import { useOrgs } from "backend/resources/orgRole";
import { useFetchMany as useOrganizations } from "features/organizations/queries/hooks";
import type { Organization } from "features/organizations/types";
import { Route } from "features/routing/constants";
import { useAppNavigate } from "lib/routing";
import { Combobox } from "shared/ui/combobox";
import {
  useActiveOrganizationId,
  useOrganizationStore,
} from "state/organization/organization";

type OrganizationWithName = Organization & { name: string };

function sortOrganizations(a: OrganizationWithName, b: OrganizationWithName) {
  return a.name > b.name ? 1 : -1;
}

export const ALL_ORGANIZATIONS = Symbol("ALL_ORGANIZATIONS");

export function OrganizationSwitcher() {
  const navigate = useAppNavigate();
  const { isSuperSuperUser } = useOrgs();
  const activeOrganizationId = useActiveOrganizationId();
  const { data: organizations } = useOrganizations({
    equals: { is_super_org: false },
  });
  const setActiveOrganizationId = useOrganizationStore(
    (state) => state.setActiveOrganizationId,
  );

  if (!organizations) {
    return null;
  }

  const filteredOrganizations = organizations
    .filter((organization): organization is OrganizationWithName =>
      Boolean(organization.name),
    )
    .sort(sortOrganizations)
    .map((organization) => ({
      value: organization.id,
      label: organization.name,
    }));

  const allOption = {
    value: ALL_ORGANIZATIONS.toString(),
    label: "All organizations",
  };

  const dropdownOptions = isSuperSuperUser
    ? [allOption, ...filteredOrganizations]
    : filteredOrganizations;

  if (!activeOrganizationId || dropdownOptions.length < 2) {
    return null;
  }

  const currentValue =
    typeof activeOrganizationId === "symbol"
      ? activeOrganizationId.toString()
      : activeOrganizationId;

  return (
    <Combobox
      ariaLabel="Organization switcher"
      options={dropdownOptions}
      value={currentValue}
      onChange={(value = ALL_ORGANIZATIONS.toString()) => {
        if (value === ALL_ORGANIZATIONS.toString()) {
          setActiveOrganizationId(ALL_ORGANIZATIONS);
          navigate({
            path: Route.ORGANIZATIONS,
          });
        } else {
          setActiveOrganizationId(value);
          navigate({
            path: Route.CARESPACES,
          });
        }
      }}
      emptyMessage="No organizations found"
      searchable
      preventUnselect
    />
  );
}
