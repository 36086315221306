import MoreInfoIcon from "assets/modal-info.svg?react";
import { NotificationType, sendNotification } from "backend/functions";
import { useUserUpdateDiscussion } from "backend/resources/chatGptConversation";
import {
  useChatGptMessages,
  useMutateChatGptMessages,
} from "backend/resources/chatGptMessage";
import { useServiceEngagementById } from "backend/resources/services/serviceEngagement";
import { formatDistanceToNow } from "date-fns";
import { Arrow } from "icons/Arrow";
import { useEffect, useRef, useState } from "react";
import type { SheetRef } from "react-modal-sheet";
import Sheet from "react-modal-sheet";

import { useActiveCarespace } from "backend/resources/carespace/carespace";
import { OrgRoleType } from "backend/resources/userRole/types";
import AlfredIcon from "components/Alfred/Alfred";
import { ChatGptSideBarInput } from "components/ChatGptSideBar/Input";
import {
  SideBarHomePage,
  SideBarNewAssessmentPage,
} from "components/ChatGptSideBar/Pages";
import { PrivateDiscussion } from "components/ChatGptSideBar/Pages/PrivateDiscussion";
import { ServiceDiscussion } from "components/ChatGptSideBar/Pages/ServiceDiscussion";
import { ServiceExternalDiscussion } from "components/ChatGptSideBar/Pages/ServiceExternalDiscussion";
import { ServiceRequestDiscussion } from "components/ChatGptSideBar/Pages/ServiceRequestDiscussion";
import { ServiceRequestExternalDiscussion } from "components/ChatGptSideBar/Pages/ServiceRequestExternalDiscussion";
import { SideBarAdminPage } from "components/ChatGptSideBar/Pages/SideBarAdminPage";
import { SideBarDiscussionsPage } from "components/ChatGptSideBar/Pages/SideBarDiscussionsPage";
import { SideBarEducationPage } from "components/ChatGptSideBar/Pages/SideBarEducationPage";
import { SideBarLocalSearchPage } from "components/ChatGptSideBar/Pages/SideBarLocalSearchPage";
import { SideBarMyCarePage } from "components/ChatGptSideBar/Pages/SideBarMyCarePage";
import { SideBarServicesPage } from "components/ChatGptSideBar/Pages/SideBarServicesPage";
import { SideBarUserUpdatesPage } from "components/ChatGptSideBar/Pages/SideBarUserUpdatesPage";
import { CarespaceDiscussion } from "components/ChatGptSideBar/Pages/UserCarespaceDiscussion";
import { UserUpdateDiscussion } from "components/ChatGptSideBar/Pages/UserUpdateDiscussion";
import { useAuthUser } from "features/users/auth";
import { useRole } from "hooks/role/useRole";
import ReactMarkdown from "react-markdown";
import { EmailForm } from "shared/forms/ServiceResourceEmailForm";
import { ResponsiveModal } from "shared/ui/responsive-modal";
import type { SideBarPageType } from "state/gpt";
import { ChatMessageType, useGptStore } from "state/gpt";

import { useFetchOne } from "features/users/queries/hooks";

const BACKEND_URL = import.meta.env.VITE_BACKEND_URL;

export enum DiscussionType {
  UserUpdate = 0,
  Carespace = 1,
  ServiceRequest = 2,
  ServiceRequestExternal = 3,
  Service = 4,
  ServiceExternal = 5,
  Private = 6,
}

type Props = {
  hideBorder?: boolean;
};

export function Alfred() {
  const isGptChatOpen = useGptStore((state) => state.isOpen);
  const { authUser } = useAuthUser();
  const { role } = useRole();

  const allowedRoles = new Set([
    OrgRoleType.ADMIN,
    OrgRoleType.CARE_NAVIGATOR,
    OrgRoleType.PROVIDER,
  ]);
  const shouldShowAlfred = () => role && allowedRoles.has(role);

  if (shouldShowAlfred()) {
    return (
      <>
        {authUser &&
          (isGptChatOpen ? <ChatGptSideBar /> : <MinimizedChatGptSideBar />)}
      </>
    );
  }

  return null;
}

export function ChatGptSideBar({ hideBorder }: Props) {
  /**
   * States
   */

  const [currentConversationId, setCurrentConversationId] = useState<string>();
  const [inputPlaceholderText, setInputPlaceholderText] = useState<string>();

  /**
   * Stores
   */

  const { authUser } = useAuthUser();
  const sidebarPageType = useGptStore((state) => state.type);
  const conversationId = useGptStore((state) => state.conversationId);
  const streamingMessage = useGptStore((state) => state.streamingMessage);
  const setStreamingMessage = useGptStore((state) => state.setStreamingMessage);
  const setPendingMessage = useGptStore((state) => state.setPendingMessage);
  const lastMessageTime = useGptStore((state) => state.lastMessageTime);
  const setLastMessageTime = useGptStore((state) => state.setLastMessageTime);

  const setShouldDisableInput = useGptStore(
    (state) => state.setShouldDisableInput,
  );
  const setUserMessage = useGptStore((state) => state.setUserMessage);
  const setMessageError = useGptStore((state) => state.setMessageError);
  const sendToGptRequest = useGptStore((state) => state.sendToGptRequest);
  const setSendToGptRequest = useGptStore((state) => state.setSendToGptRequest);

  /**
   * Hooks
   */

  /**
   * Refs
   */

  const eventSourceRef = useRef<EventSource | null>(null);
  const prevMessageTimeRef = useRef(lastMessageTime);
  const errorTimeoutRef = useRef<NodeJS.Timeout>();

  /**
   * Mutations
   */

  const chatGptMessagesMutation = useMutateChatGptMessages();

  interface Event {
    data: string;
  }

  function onMessage(event: Event) {
    const data = JSON.parse(event.data);
    const { streamingMessage, conversationId } = useGptStore.getState();
    if (data.content) {
      setPendingMessage(false);
      setStreamingMessage(data.content.replace("\n", "<br /><br />"));
      setShouldDisableInput(true);
      setInputPlaceholderText("Please wait for Alfred to finish...");
      setLastMessageTime(Date.now());
    } else if (["stop", "length"].includes(data.finish_reason)) {
      if (streamingMessage && conversationId) {
        persistMessage();
      }
      closeEventSource();
    }
  }

  function onMessageError(event: Event) {
    setMessageError("Something went wrong. Please try again.");
    setStreamingMessage(null);
  }

  async function persistMessage() {
    // grab current threadId based on context
    const threadId = getCurrentThreadId();
    const { streamingMessage } = useGptStore.getState();
    // check that
    if (streamingMessage && currentConversationId && threadId) {
      const persistedMessage = await chatGptMessagesMutation.mutateAsync({
        message: {
          chat_gpt_conversation_id: currentConversationId,
          content: streamingMessage,
          role: "assistant",
          send_to_chat_gpt: true,
          user_id: authUser?.id,
        },
        threadId,
      });
      if (persistedMessage && persistedMessage.length > 0) {
        setStreamingMessage(null);
      }
    } else {
      setStreamingMessage(null); // trigger state update
    }
  }

  async function persistUserMessage(userMessage: string) {
    // grab current threadId based on context
    const threadId = getCurrentThreadId();
    // check all values required to persist messages are truthy
    if (currentConversationId && userMessage && threadId) {
      const persistedMessage = await chatGptMessagesMutation.mutateAsync({
        message: {
          chat_gpt_conversation_id: currentConversationId,
          content: userMessage,
          role: "user",
          send_to_chat_gpt: true,
          user_id: authUser?.id,
        },
        threadId,
      });

      // if user message was persisted, send to GPT and handle required UI changes
      if (persistedMessage && persistedMessage.length > 0) {
        setShouldDisableInput(true);
        setInputPlaceholderText("Please wait for Alfred to finish...");
        setPendingMessage(true);
        setUserMessage("");
        await timeout(1000); // feign 1 second of thinking
        // if the message was sent from a recommendatino thread (rec or intervention),
        // include the recommendation title and description as context in the message

        sendToGpt(
          currentConversationId,
          ChatMessageType.USER_MESSAGE,
          userMessage,
        );
      }
    }
  }

  function getCurrentThreadId() {
    return sidebarPageType;
  }

  function sendToGpt(
    conversationId: string,
    message_type: ChatMessageType,
    text?: string,
  ) {
    if (authUser?.id && text && conversationId) {
      const params = new URLSearchParams({
        text,
        user_id: authUser.id,
        chat_gpt_conversation_id: conversationId,
        message_type,
      });
      const evtSource = new EventSource(
        `${BACKEND_URL}/chat_gpt_conversation?${params}`,
      );
      eventSourceRef.current = evtSource;
      if (evtSource !== null) {
        // @ts-ignore
        eventSourceRef.current.addEventListener("new_message", onMessage);
        eventSourceRef.current.addEventListener("error", onMessageError);
      }
    }
  }

  function closeEventSource() {
    if (eventSourceRef?.current) {
      eventSourceRef.current.removeEventListener("new_message", onMessage);
      eventSourceRef.current.close();

      clearTimeout(errorTimeoutRef.current);
      setShouldDisableInput(false);
      setInputPlaceholderText("");
    }
    setPendingMessage(false);
  }

  /**
   * Effects
   */

  // setup
  useEffect(() => {
    // setup
    clearTimeout(errorTimeoutRef.current); // clear any lingering timeouts on mount
    setShouldDisableInput(false); // ensure input is enabled on mount
    setInputPlaceholderText("");
    setStreamingMessage(null);
    // cleanup
    return () => {
      closeEventSource();
    };
  }, []);

  // set up covnersation ids
  useEffect(() => {
    if (conversationId !== currentConversationId) {
      setCurrentConversationId(conversationId);
    }
  }, [conversationId, currentConversationId]);

  // effect for error handling
  useEffect(() => {
    // update timestamp
    prevMessageTimeRef.current = lastMessageTime;

    // start error timer
    const timer = setTimeout(() => {
      const currentTime = Date.now();
      const prevMessageTime = prevMessageTimeRef.current;
      if (!prevMessageTime) return;
      const elapsedTime = currentTime - prevMessageTime;
      if (elapsedTime >= 8000 && streamingMessage && conversationId) {
        // More than 8 seconds have elapsed since the last message
        // Stop disabling the input and trigger an error
        setMessageError("Timeout.");
        closeEventSource();
        persistMessage();
      }
    }, 8000);

    errorTimeoutRef.current = timer;

    // cleanup
    return () => {
      clearTimeout(errorTimeoutRef.current); // Clear the timer when the component unmounts
    };
  }, [lastMessageTime]);

  // hand Alfred request
  useEffect(() => {
    async function asyncWork() {
      if (sendToGptRequest) {
        setShouldDisableInput(true);
        setInputPlaceholderText("Please wait for Alfred to finish...");
        setPendingMessage(true);
        setUserMessage("");
        await timeout(1000); // feign 1 second of thinking
        sendToGpt(
          sendToGptRequest.conversationId,
          sendToGptRequest.message_type,
          sendToGptRequest.text,
        );
        setSendToGptRequest(null);
      }
    }
    asyncWork();
  }, [sendToGptRequest]);

  // switching convestations
  useEffect(() => {
    async function changeConversations() {
      if (conversationId !== currentConversationId) {
        closeEventSource();
        await persistMessage();
        setCurrentConversationId(conversationId);
      }
    }

    changeConversations();
  }, [conversationId, currentConversationId]);

  return (
    <div
      style={{
        height: "100%",
        width: "380px",
      }}
      className={`w-[380px]" flex flex-col max-h-full transition-all  ${
        hideBorder ? "" : "border-l border-neutral-200 drop-shadow-sm"
      } bg-white shrink-0`}
    >
      <div className="flex-grow overflow-y-auto">
        {renderSideBarPageType(sidebarPageType)}
      </div>
      <ChatGptSideBarInput
        closeEventSource={closeEventSource}
        persistMessage={persistMessage}
        persistUserMessage={persistUserMessage}
        isDiscussion={false}
        inputPlaceholderText={inputPlaceholderText}
      />
    </div>
  );
}

const useConversationId = (
  discussionType: DiscussionType,
  threadId: string | undefined,
) => {
  const { data: userUpdateDiscussion } = useUserUpdateDiscussion(threadId);
  const { data: carespace } = useActiveCarespace();
  const { data: serviceEngagementDiscussion } =
    useServiceEngagementById(threadId);
  switch (discussionType) {
    case DiscussionType.UserUpdate: {
      return userUpdateDiscussion?.conversation_id;
    }
    case DiscussionType.Carespace: {
      return carespace?.conversation_id;
    }
    case DiscussionType.Service: {
      return serviceEngagementDiscussion?.conversation_id;
    }
    case DiscussionType.ServiceExternal: {
      return serviceEngagementDiscussion?.external_conversation_id;
    }
    case DiscussionType.Private: {
      return threadId;
    }
    default:
  }
};

export function CollapsedUserDiscussion({
  discussionType,
  threadId,
  title,
}: Props & {
  discussionType: DiscussionType;
  threadId: string | undefined;
  title: string;
}) {
  const [isOpen, setIsOpen] = useState(false);
  const sidebarPageTypeMapping: Record<DiscussionType, SideBarPageType> = {
    [DiscussionType.UserUpdate]: "userUpdate",
    [DiscussionType.Carespace]: "carespace",
    [DiscussionType.ServiceRequest]: "serviceRequest",
    [DiscussionType.ServiceRequestExternal]: "serviceRequestExternal",
    [DiscussionType.Service]: "service",
    [DiscussionType.ServiceExternal]: "serviceExternal",
    [DiscussionType.Private]: "private",
  };

  const pageType = sidebarPageTypeMapping[discussionType];
  const { isLoadingMessages, messages } = useChatGptMessages(
    threadId,
    pageType,
  );
  const latestMessage = messages?.[messages.length - 1];
  const { data: messageAuthorUser } = useFetchOne(
    {
      equals: { id: latestMessage?.user_id || undefined },
    },
    { enabled: !!latestMessage?.user_id },
  );

  return (
    <>
      <ResponsiveModal
        isOpen={isOpen}
        title={title}
        closeText="Close"
        onClose={() => setIsOpen(false)}
        fixedHeight="h-[90vh]"
      >
        <div className="flex flex-col justify-end h-[60vh]">
          <UserDiscussion discussionType={discussionType} threadId={threadId} />
        </div>
      </ResponsiveModal>
      <div
        className="flex flex-col gap-2 cursor-pointer"
        onClick={() => setIsOpen(true)}
      >
        <p className="text-lg">{title}</p>
        <div className="grid grid-cols-[5fr,2fr,2fr]">
          <p>Latest Message</p>
          <p>From</p>
          <p>Sent</p>

          {latestMessage && !isLoadingMessages ? (
            <>
              <p
                style={{
                  fontWeight: "normal",
                }}
              >
                <ReactMarkdown
                  components={{
                    a: ({ node, children, ...props }) => (
                      <a
                        {...props}
                        style={{ textDecoration: "underline" }}
                        target="_blank"
                      >
                        {children}
                      </a>
                    ),
                  }}
                >
                  {latestMessage?.content}
                </ReactMarkdown>
              </p>
              <p
                style={{
                  fontWeight: "normal",
                }}
              >
                {messageAuthorUser?.first_name} {messageAuthorUser?.last_name}
              </p>
              <p
                style={{
                  fontWeight: "normal",
                }}
              >
                {latestMessage?.created_at
                  ? formatDistanceToNow(new Date(latestMessage.created_at))
                  : ""}
              </p>
            </>
          ) : (
            <p className="text-sm">
              No messages yet. Click to start discussion.
            </p>
          )}
        </div>
      </div>
    </>
  );
}

const TITLE_EXTERNAL_DISCUSSION =
  "External Discussion (includes external provider)";

export function ProviderEmailForm({ resourceId }: { resourceId: string }) {
  return (
    <div className="bg-[#D2ECFF] p-5 rounded-md">
      <p className="text-lg mb-2 ">{TITLE_EXTERNAL_DISCUSSION}</p>
      <div className="flex gap-5 items-center ">
        <MoreInfoIcon />
        <p className=" text-sm">
          In order to send a message to this provider, their email address must
          be added to their profile. After you add it, all messages here will be
          sent to that provider via email.
        </p>
      </div>
      <EmailForm service_resource_id={resourceId} />
    </div>
  );
}

export function ExternalDiscussion({
  email,
  discussionType,
  threadId,
  resourceId,
}: {
  email: string | undefined | null;
  discussionType: DiscussionType;
  threadId: string | undefined;
  resourceId: string;
}) {
  return !email ? (
    <ProviderEmailForm resourceId={resourceId} />
  ) : (
    <CollapsedUserDiscussion
      discussionType={discussionType}
      threadId={threadId}
      title={TITLE_EXTERNAL_DISCUSSION}
    />
  );
}

export function UserDiscussion({
  discussionType,
  hideBorder,
  threadId,
}: Props & { discussionType: DiscussionType; threadId: string | undefined }) {
  const { authUser } = useAuthUser();
  const setUserMessage = useGptStore((state) => state.setUserMessage);
  const currentConversationId = useConversationId(discussionType, threadId);

  const chatGptMessagesMutation = useMutateChatGptMessages();

  async function persistUserMessage(userMessage: string) {
    if (currentConversationId && userMessage && threadId) {
      const persistedMessage = await chatGptMessagesMutation.mutateAsync({
        message: {
          chat_gpt_conversation_id: currentConversationId,
          content: userMessage,
          role: "user",
          send_to_chat_gpt: true,
          user_id: authUser?.id,
        },
        threadId,
      });
      setUserMessage("");

      const lastMessage = persistedMessage[persistedMessage.length - 1];
      switch (discussionType) {
        case DiscussionType.UserUpdate: {
          sendNotification(
            lastMessage.id,
            NotificationType.MESSAGE_POSTED_IN_HUB,
          );
          break;
        }
        case DiscussionType.Carespace: {
          sendNotification(
            lastMessage.id,
            NotificationType.MESSAGE_POSTED_IN_CARESPACE,
          );
          break;
        }
        case DiscussionType.ServiceExternal: {
          sendNotification(lastMessage.id, NotificationType.EXTERNAL_SERVICE);
          break;
        }
        case DiscussionType.ServiceRequestExternal: {
          sendNotification(
            lastMessage.id,
            NotificationType.EXTERNAL_SERVICE_REQUEST,
          );
          break;
        }
        case DiscussionType.Private: {
          sendNotification(
            lastMessage.id,
            NotificationType.EXTERNAL_DIRECT_MESSAGE,
          );
          break;
        }
      }
    }
  }

  return (
    <div
      style={{
        height: "90%",
      }}
      className={`"w-full h-full flex flex-col max-h-full transition-all
      ${hideBorder ? "" : "border-l border-neutral-200 drop-shadow-sm"}
       bg-white shrink-0`}
    >
      <div className="flex-grow overflow-y-auto">
        {renderUserDiscussion(discussionType)}
      </div>
      <ChatGptSideBarInput
        closeEventSource={() => {}}
        persistMessage={() => {}}
        persistUserMessage={persistUserMessage}
        isDiscussion={true}
        inputPlaceholderText="Type Here"
      />
    </div>
  );
}

function renderUserDiscussion(discussionType: DiscussionType) {
  const discussionTypeComponents = {
    [DiscussionType.UserUpdate]: <UserUpdateDiscussion />,
    [DiscussionType.Carespace]: <CarespaceDiscussion />,
    [DiscussionType.ServiceRequest]: <ServiceRequestDiscussion />,
    [DiscussionType.Service]: <ServiceDiscussion />,
    [DiscussionType.ServiceExternal]: <ServiceExternalDiscussion />,
    [DiscussionType.ServiceRequestExternal]: (
      <ServiceRequestExternalDiscussion />
    ),
    [DiscussionType.Private]: <PrivateDiscussion />,
  };
  return discussionTypeComponents[discussionType];
}

function renderSideBarPageType(sidebarPageType: SideBarPageType | undefined) {
  if (!sidebarPageType) return null;
  const pageTypeComponents = {
    homePage: <SideBarHomePage />,
    newAssessmentPage: <SideBarNewAssessmentPage />,
    userUpdatePage: <SideBarUserUpdatesPage />,
    localSearchPage: <SideBarLocalSearchPage />,
    educationPage: <SideBarEducationPage />,
    discussionsPage: <SideBarDiscussionsPage />,
    myCarePage: <SideBarMyCarePage />,
    adminPage: <SideBarAdminPage />,
    userUpdate: <UserUpdateDiscussion />,
    carespace: <CarespaceDiscussion />,
    serviceRequest: <ServiceRequestDiscussion />,
    service: <></>,
    servicesPage: <SideBarServicesPage />,
    serviceExternal: <ServiceExternalDiscussion />,
    serviceRequestExternal: <ServiceRequestExternalDiscussion />,
    private: <></>,
  };
  return pageTypeComponents[sidebarPageType];
}

export function MinimizedChatGptSideBar() {
  return (
    <div className="flex flex-col h-full border-l border-neutral-200 w-16 relative items-center gap-5 p-3">
      <CollapseButton />
      <AlfredIcon className="w-8 shrink-0" />
    </div>
  );
}

export function CollapseButton() {
  const isOpen = useGptStore((state) => state.isOpen);
  const setIsOpen = useGptStore((state) => state.setIsOpen);

  return (
    <button
      className="hover:bg-gray-100 flex items-center justify-center top-9 w-6 h-6  rounded-full border border-neutral-200 bg-white shrink-0"
      onClick={() => {
        setIsOpen(!isOpen);
      }}
    >
      <Arrow
        className={`w-2 h-2 ${
          isOpen ? "rotate-90" : "-rotate-90"
        } transition-all fill-brand-orange`}
      />
    </button>
  );
}

function timeout(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
