import { type Cell, type Row, flexRender } from "@tanstack/react-table";
import type { CarespaceDataWithBillingPageInfo } from "components/BillingPage/types";
import {
  InfiniteScrollingTable,
  type TableDataRowType,
} from "components/Tables/InfiniteScrollingTable";
import type { PatientTier } from "features/carespaces/types";
import { Route } from "features/routing/constants";
import { useAppNavigate } from "lib/routing";

enum PatientListColumn {
  PatientName = "Patient Name",
  Tier = "Tier",
  HasCaregiver = "Has Caregiver",
  IsEligible = "Is Eligible",
  id = "id",
}
type BasePatientListType = {
  [PatientListColumn.PatientName]: string;
  [PatientListColumn.Tier]: PatientTier;
  [PatientListColumn.HasCaregiver]: string;
  [PatientListColumn.IsEligible]: string;
  [PatientListColumn.id]: string;
};
type PatientListType = BasePatientListType &
  TableDataRowType<BasePatientListType>;

export default function PatientList({
  data,
}: {
  data: CarespaceDataWithBillingPageInfo[];
}) {
  const navigate = useAppNavigate();
  const tierOrder: Record<PatientTier, number> = {
    low: 1,
    moderate: 2,
    high: 3,
    na: 4,
  };
  const processData = (data: CarespaceDataWithBillingPageInfo[]) => {
    return data
      .map((d) => ({
        [PatientListColumn.PatientName]: d.name,
        [PatientListColumn.Tier]: d.tier,
        [PatientListColumn.HasCaregiver]: d.has_caregiver ? "Yes" : "No",
        [PatientListColumn.IsEligible]: d.is_eligible ? "Yes" : "No",
        [PatientListColumn.id]: d.id,
      }))
      .sort((a, b) => {
        const tierComparison =
          tierOrder[a[PatientListColumn.Tier]] -
          tierOrder[b[PatientListColumn.Tier]];
        if (tierComparison !== 0) {
          return tierComparison;
        }
        const caregiverComparison = a[
          PatientListColumn.HasCaregiver
        ].localeCompare(b[PatientListColumn.HasCaregiver]);
        return caregiverComparison;
      }) as PatientListType[];
  };
  return (
    <div className="flex flex-col gap-2 w-[1000px]">
      <p className="text-xl font-bold">Patient Breakdown</p>
      <InfiniteScrollingTable
        data={processData(data)}
        hiddenColumns={[PatientListColumn.id]}
        columnFields={Object.values(PatientListColumn)}
        handleClick={(
          cell: Cell<PatientListType, unknown>,
          row: Row<PatientListType>,
        ) => {
          navigate({
            path: Route.CARESPACE,
            params: {
              carespaceId: row.original[PatientListColumn.id] as string,
            },
          });
        }}
        CellContent={CellContent}
      />
    </div>
  );
}

function CellContent({
  cell,
  row,
}: {
  cell: Cell<PatientListType, unknown>;
  row: Row<PatientListType>;
}) {
  switch (cell.column.id) {
    default:
      return (
        <p className="truncate text-left max-w-[200px] w-[200px]">
          {flexRender(cell.column.columnDef.cell, cell.getContext())}
        </p>
      );
  }
}
