import { zodResolver } from "@hookform/resolvers/zod";
import { useUpdateServiceResource } from "backend/resources/services/serviceResource";
import { ButtonWithIcon, IconOption } from "components/ButtonWithIcon";
import { useForm } from "react-hook-form";
import { Form, FormField } from "shared/ui/form";
import { Input } from "shared/ui/input";
import { z } from "zod";

const EmailSchema = z.object({
  email: z.string().email("Please enter a valid email address."),
});

const useServiceResourceForm = () =>
  useForm({
    resolver: zodResolver(EmailSchema),
    mode: "onSubmit",
  });
interface EmailFormProps {
  service_resource_id: string | undefined;
  onClose?: () => void;
  isEditing?: boolean;
}

export function EmailForm({
  service_resource_id,
  onClose,
  isEditing,
}: EmailFormProps) {
  const updateServiceResource = useUpdateServiceResource().mutateAsync;

  const form = useServiceResourceForm();

  const onSubmit = (data: z.infer<typeof EmailSchema>) => {
    updateServiceResource({
      id: service_resource_id,
      email: data.email,
    });
    onClose?.();
  };

  return (
    <div className={`mt-2 flex gap-2 p-2 ${isEditing ? "flex-col" : ""}`}>
      <Form form={form} id="email-form">
        <FormField
          control={form.control}
          name="email"
          label="Email address"
          render={({ field }) => (
            <Input
              className="w-full md:w-[300px]"
              {...field}
              placeholder="Enter email address"
            />
          )}
        />
      </Form>

      <ButtonWithIcon
        size="small"
        className={`${isEditing ? "self-end" : ""}`}
        onClick={() => {
          form.handleSubmit((values) => {
            const result = EmailSchema.safeParse(values);
            if (result.success) {
              onSubmit(result.data);
            }
          })();
        }}
        text={isEditing ? "Save" : "Add Email"}
        icon={isEditing ? IconOption.CHECKMARK : IconOption.ARROW}
      />
    </div>
  );
}
