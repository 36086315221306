import { supabase } from "clients/supabaseClient";

import type { Filter } from "features/query-utils";

import { TABLE_NAME } from "../constants";
import type { TableName, UserInsert, UserUpdate } from "../types";

const all = ["users"];
const lists = [...all, "list"];
const detail = [...all, "detail"];

export const queryKeys = {
  all,
  lists,
  list: (filter: Filter<TableName>) => [...lists, filter],
  detail: (filter: Filter<TableName>) => [...detail, filter],
};

export const fields = `
    id,
    created_at,
    first_name,
    last_name,
    profile_image,
    email,
    has_approved_tos,
    cell_number,
    timezone,
    npi,
    organization_role(
      organization_id
    )
  `;

export const select = () => supabase.from(TABLE_NAME).select(fields);

export const update = (userUpdate: UserUpdate) =>
  supabase.from(TABLE_NAME).update(userUpdate);

export const insert = (userInsert: UserInsert) =>
  supabase.from(TABLE_NAME).insert(userInsert).select(fields);

export const fetchInvitation = ({ invitation_id }: { invitation_id: string }) =>
  supabase.from("invitation").select().eq("id", invitation_id).maybeSingle();
