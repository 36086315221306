import { Route } from "features/routing/constants";
import { OrganizationRole } from "features/users/constants";
import type { CarespaceRole } from "features/users/types";
import { useAppNavigate } from "lib/routing";
import { ChevronsLeft, ChevronsRight } from "lucide-react";
import React, { useEffect, useState, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useToast } from "shared/hooks/use-toast";
import { Button } from "shared/ui/button";
import { Separator } from "shared/ui/separator";
import { Text } from "shared/ui/text";
import ActivityList from "../components/activity-list";
import { AlfredChat } from "../components/alfred-chat/index";
import CarespaceAlignmentForm from "../components/carespace-alignment-form";
import CompletedActivityList from "../components/completed-activity-list";
import ContactInfo from "../components/contact-info";
import NewActivity from "../components/new-activity";
import NewNote from "../components/new-note";
import PatientInfo from "../components/patient-info";
import ScheduledActivityList from "../components/scheduled-activity-list";
import {
  useAddActivity,
  useCarespaceUserRole,
  useFetchActivities,
  useFetchManyUserAssessements,
  useFetchOne,
  useGenerateActivities,
  useUpdateGuideStatus,
} from "../queries/hooks";

function sortByRole(a: { role: CarespaceRole }, b: { role: CarespaceRole }) {
  const roleOrder: Record<CarespaceRole, number> = {
    primary_caregiver: 0,
    secondary_caregiver: 1,
  };

  return roleOrder[a.role] - roleOrder[b.role];
}

export default function CarespaceList() {
  const { carespaceId } = useParams();
  const { data: carespace, isLoading } = useFetchOne({
    equals: { id: carespaceId },
  });
  const { data: assessments, isLoading: isLoadingAssessments } =
    useFetchManyUserAssessements({
      equals: { carespace_id: carespaceId },
    });
  const { data: activities = [], isLoading: activitiesLoading } =
    useFetchActivities(carespaceId);
  const { data: currentOrgRole } = useCarespaceUserRole(
    carespace?.organization_id,
  );

  const activeActivities = useMemo(
    () =>
      activities.filter(
        (a) =>
          !a.completed_at &&
          !a.skipped_at &&
          (!a.scheduled_at || new Date(a.scheduled_at) <= new Date()),
      ),
    [activities],
  );

  const completedOrSkippedActivities = useMemo(
    () => activities.filter((a) => a.completed_at || a.skipped_at),
    [activities],
  );

  const scheduledActivities = useMemo(
    () =>
      activities.filter(
        (a) =>
          a.scheduled_at &&
          new Date(a.scheduled_at) > new Date() &&
          !a.completed_at &&
          !a.skipped_at,
      ),
    [activities],
  );

  const navigate = useAppNavigate();
  const addActivity = useAddActivity(carespaceId).mutateAsync;
  const generateActivities = useGenerateActivities(carespaceId).mutateAsync;
  const updateGuideStatus = useUpdateGuideStatus().mutateAsync;
  const [alignmentFormOpen, setAlignmentFormOpen] = useState(false);
  const { toast } = useToast();

  const patient = carespace?.patient ?? null;
  const hasCompletedZarit =
    assessments?.some(
      (assessment) =>
        assessment.assessment.slug === "ZARIT" &&
        assessment.status === "completed",
    ) ?? false;
  const hasCompletedPromis =
    assessments?.some(
      (assessment) =>
        assessment.assessment.slug === "PROMIS" &&
        assessment.status === "completed",
    ) ?? false;

  useEffect(() => {
    // Update guide_status to 'assessed' if 'screened' and both assessments are completed
    if (!carespace) return;
    if (carespace.guide_status !== "screened") return;
    if (!hasCompletedPromis || (carespace.caregiver && !hasCompletedZarit))
      return;

    updateGuideStatus({
      carespaceId: carespace.id,
      status: "assessed",
    });
  }, [carespace, hasCompletedPromis, hasCompletedZarit, updateGuideStatus]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!carespaceId || !carespace) {
    return <div>Carespace not found</div>;
  }

  return (
    <div className="grid grid-cols-[2fr_1px_5fr_1px_3fr] min-h-full">
      <div className="flex flex-col">
        {patient && (
          <>
            <PatientInfo
              name={patient.name}
              birthday={patient.birthday}
              tier={patient.tier}
              sex={patient.sex}
              mbi={patient.mbi ?? undefined}
              medicaid_id={patient.medicaid_id ?? undefined}
              preferred_name={patient.preferred_name ?? undefined}
              preferred_language={patient.preferred_language ?? undefined}
              guide_status={carespace.guide_status}
            />
            {(carespace.guide_status === "new" ||
              carespace.guide_status === "review") && (
              <Button
                variant="default"
                className="mb-4 mx-4"
                onClick={() => {
                  setAlignmentFormOpen(!alignmentFormOpen);
                }}
              >
                {alignmentFormOpen ? (
                  <>
                    <ChevronsLeft className="w-4 h-4" />
                    Hide form
                  </>
                ) : (
                  <>
                    <ChevronsRight className="w-4 h-4" />
                    {carespace.guide_status === "new"
                      ? "Start Screening"
                      : "Start Review"}
                  </>
                )}
              </Button>
            )}
            <Separator orientation="horizontal" />
          </>
        )}
        {carespace.pcp_name && (
          <React.Fragment>
            <ContactInfo
              name={carespace.pcp_name}
              roleType={"pcp"}
              cellNumber={carespace.pcp_phone_number}
            />
            <Separator orientation="horizontal" />
          </React.Fragment>
        )}
        {carespace.provider_name && (
          <React.Fragment>
            <ContactInfo
              name={carespace.provider_name}
              roleType={OrganizationRole.PROVIDER}
            />
            <Separator orientation="horizontal" />
          </React.Fragment>
        )}
        {[...carespace.user_role].sort(sortByRole).map((info, i) =>
          info.user ? (
            <React.Fragment key={info.user.name}>
              <ContactInfo
                name={info.user.name}
                roleType={info.role}
                email={info.user.email}
                cellNumber={info.user.cell_number}
              />
              <Separator orientation="horizontal" />
            </React.Fragment>
          ) : null,
        )}
      </div>
      <Separator orientation="vertical" />
      {alignmentFormOpen ? (
        <div className="overflow-scroll">
          <CarespaceAlignmentForm
            carespace={carespace}
            title={
              carespace.guide_status === "new"
                ? "Patient Screening"
                : "Patient Review"
            }
            submitLabel={
              carespace.guide_status === "new"
                ? "Complete Screening"
                : "Complete Review"
            }
            rationale={carespace.guide_status_rationale ?? undefined}
            onSubmit={() => {
              setAlignmentFormOpen(false);
            }}
          />
        </div>
      ) : (
        <div className="p-2 bg-gray-50">
          <div className="flex flex-row justify-end mx-2 mb-2 gap-2">
            <NewActivity onSave={addActivity} onGenerate={generateActivities} />
            <NewNote onSave={addActivity} />
          </div>
          <div className="px-2">
            <ActivityList
              carespaceId={carespaceId}
              activities={activeActivities}
              isLoading={activitiesLoading}
            />
            <ScheduledActivityList
              carespaceId={carespaceId}
              activities={scheduledActivities}
              isLoading={activitiesLoading}
            />
          </div>
          <CompletedActivityList
            activities={completedOrSkippedActivities}
            isLoading={activitiesLoading}
          />
        </div>
      )}
      <Separator orientation="vertical" />
      <div className="flex flex-col">
        <div className="p-4">
          <Text>Assessments</Text>
          <Text size="sm" className="mb-2 text-gray-500">
            None
          </Text>
          {carespace.guide_status === "screened" &&
            carespace.caregiver &&
            !isLoadingAssessments &&
            !hasCompletedZarit && (
              <Button
                variant="default"
                className="mt-4 w-full"
                onClick={() =>
                  navigate({
                    path: Route.CARESPACE_ASSESSMENT,
                    params: { carespaceId: carespace.id },
                    queryParams: { assessment_slug: "ZARIT" },
                  })
                }
              >
                Start ZARIT
              </Button>
            )}
          {carespace.guide_status === "screened" &&
          !isLoadingAssessments &&
          !hasCompletedPromis ? (
            <Button
              variant="default"
              className="mt-4 w-full"
              onClick={() =>
                navigate({
                  path: Route.CARESPACE_ASSESSMENT,
                  params: { carespaceId: carespace.id },
                  queryParams: { assessment_slug: "PROMIS" },
                })
              }
            >
              Start PROMIS
            </Button>
          ) : (
            <Button
              variant="outline"
              className="mt-4 w-full"
              onClick={() =>
                toast({
                  title: "Coming soon",
                  description: "This feature is under development",
                })
              }
            >
              Start assessment
            </Button>
          )}
        </div>
        <Separator orientation="horizontal" />
        <div className="p-4">
          <Text>Services</Text>
          <Text size="sm" className="mb-2 text-gray-500">
            None
          </Text>
          <Button
            variant="outline"
            className="mt-4 w-full"
            onClick={() =>
              toast({
                title: "Coming soon",
                description: "This feature is under development",
              })
            }
          >
            Add service
          </Button>
        </div>
        <Separator orientation="horizontal" />
        {currentOrgRole && (
          <div className="p-4">
            <Text>Alfred</Text>
            <div className="mt-2 h-[400px]">
              <AlfredChat carespaceId={carespaceId} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
