import type { Route as RoutePath } from "features/routing/constants";
import { useNavigate } from "react-router-dom";

/**
 *  createPath() is a utility function for generating paths from the RoutePath enum.
 */
export function createPath(args: TArgs) {
  let path = args?.path?.toString();
  // Create a path by replacing params in the route definition
  if ("params" in args) {
    path = Object.entries(args.params).reduce(
      (previousValue: string, [param, value]) =>
        previousValue.replace(`:${param}`, `${value}`),
      args.path,
    );
  }
  // Append query parameters if they exist
  if (args.queryParams) {
    const queryString = Object.entries(args.queryParams)
      .filter(([_, value]) => !!value)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value || "")}`,
      )
      .join("&");
    path += `?${queryString}`;
  }

  return path ?? "";
}

/**
 * useAppNavigate() is a custom hook that wraps the useNavigate() hook from `react-router-dom`.
 * It returns a function that takes a TArgs object and navigates to the path generated by createPath(args).
 */
export function useAppNavigate() {
  const navigate = useNavigate();
  return (args: TArgs, options?: { replace?: boolean }) =>
    navigate(createPath(args), options);
}

/**
 * useNavigateBack() is a custom hook that wraps the useNavigate() hook from `react-router-dom`
 */
export function useNavigateBack() {
  const navigate = useNavigate();
  return () => navigate(-1);
}

/**
 *  Docs refs for the TypeScript features used in the args types:
 *  - Distributive Conditional Types: https://www.typescriptlang.org/docs/handbook/2/conditional-types.html#distributive-conditional-types
 *  - Conditional Types: https://www.typescriptlang.org/docs/handbook/2/conditional-types.html
 *  - Template Literal Types: https://www.typescriptlang.org/docs/handbook/2/template-literal-types.html
 *
 *  See also: https://www.smashingmagazine.com/2021/01/dynamic-static-typing-typescript/
 */

type ParseRouteParams<Route> = string extends Route
  ? never // <- stripped out by TypeScript in the final Union type between all these options
  : Route extends `${string}/:${infer P1}/${infer Rest}` // <- if there is a param and other segments after it
    ? P1 | ParseRouteParams<`${string}/${Rest}`> // <- recursive step
    : Route extends `${string}/:${infer P}`
      ? P
      : never;

type RouteParams = {
  [Key in RoutePath]: Key extends `${string}/:${infer P}` // <- if there's at least one param
    ? { path: Key; params: Record<ParseRouteParams<Key>, string> } // <- recursively formed "A | B | C ..." as key of Record = dynamically generated required params
    : { path: Key };
};

type TArgs = (RouteParams[keyof RouteParams] | { path?: never }) & {
  queryParams?: Record<string, string | null>;
};
