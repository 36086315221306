/**
 * @fileoverview Sets up the Zustand library folders store.
 * This store holds information about library folders state.
 */
import { useCarespaceIds } from "backend/resources/userRole";
import { useEffect } from "react";
import { resetAssessmentStore } from "state/assessment";
import { LocalStorageKeys } from "state/localStorageKeys";
import { create } from "zustand";
import { persist } from "zustand/middleware";

/**
 * Hook to access the library folders store
 */

type State = {
  activeCarespaceId?: string | undefined;
};

type Actions = {
  setActiveCarespaceId: (activeCarespaceId?: string) => void;
  reset: () => void;
};

const initialState: State = {
  activeCarespaceId: undefined,
};

// TODO: remove this as a persistent store, it's a potential HIPAA violation. Removal might cause bugs.
// See https://app.shortcut.com/craniometrix/story/3096/frontend-remove-persist-from-network-store
export const useCarespaceStore = create<State & Actions>()(
  persist(
    (set, get) => ({
      ...initialState,

      setActiveCarespaceId: (activeCarespaceId?: string) => {
        set({ activeCarespaceId });
        resetAssessmentStore();
      },

      reset: () => set({ ...initialState }),
    }),
    {
      name: LocalStorageKeys.carespace, // unique name
      getStorage: () => localStorage, // (optional) by default the 'localStorage' is used
    },
  ),
);

export function useActiveCarespaceId() {
  const { carespaceIds } = useCarespaceIds();

  const { activeCarespaceId, setActiveCarespaceId } = useCarespaceStore();

  useEffect(() => {
    if (!activeCarespaceId && carespaceIds && carespaceIds?.length > 0) {
      setActiveCarespaceId(carespaceIds[0]);
    }
  }, [activeCarespaceId, carespaceIds, setActiveCarespaceId]);

  return activeCarespaceId || carespaceIds?.[0];
}

export function getActiveCarespaceIdDirectly() {
  return useCarespaceStore.getState().activeCarespaceId;
}
