import { useMutation, useQuery } from "@tanstack/react-query";
import { queryClient } from "app";
import { QUERY_KEYS } from "backend/query-keys";
import type { ServiceEngagement } from "backend/resources/services/serviceEngagement";
import { supabase } from "clients/supabaseClient";
import { ALL_ORGANIZATIONS } from "features/routing/layouts/components/organization-switcher";
import { useActiveOrganizationId } from "state/organization/organization";
import type { Database } from "../../../../types/supabase";

const TABLE = "service_resource";
export type ServiceResource =
  Database["public"]["Tables"]["service_resource"]["Row"];
export type ServiceResourceUpdate =
  Database["public"]["Tables"]["service_resource"]["Update"];
export type ServiceResourceWithServiceEngagements = ServiceResource & {
  service_engagement: ServiceEngagement[];
};

export function useServiceResources() {
  const activeOrgId = useActiveOrganizationId();
  return useQuery({
    queryKey: [QUERY_KEYS.serviceResource, { activeOrgId }],
    queryFn: async () => {
      if (!activeOrgId) return null;
      let queryBuilder = supabase
        .from(TABLE)
        .select("*, service_engagement(*)")
        .order("created_at", { ascending: false });

      if (activeOrgId !== ALL_ORGANIZATIONS) {
        queryBuilder = queryBuilder.eq("organization_id", activeOrgId);
      }

      const { data, error } = await queryBuilder;

      if (error) {
        throw new Error(error.message);
      }

      return data;
    },
  });
}

export function useServiceResource(
  serviceResourceEventId: string | undefined | null,
) {
  return useQuery({
    queryKey: [QUERY_KEYS.serviceResource, serviceResourceEventId],
    queryFn: async () => {
      if (!serviceResourceEventId) return null;
      const { data, error } = await supabase
        .from(TABLE)
        .select("*")
        .eq("id", serviceResourceEventId)
        .limit(1)
        .maybeSingle();

      return data;
    },
  });
}

export function useUpsertServiceResourceAndPlanEntry() {
  const upsertServiceResource = useUpsertServiceResource();

  return useMutation({
    mutationFn: async (upsertData: {
      serviceResourceUpdate: ServiceResourceUpdate;
      plan_entry_id?: string;
    }) => {
      const upsertDataObject = {
        plan_entry_id: upsertData.plan_entry_id,
        serviceResourceUpdate: upsertData.serviceResourceUpdate,
      };
      const { data: serviceResourceData } =
        await upsertServiceResource.mutateAsync(
          upsertDataObject.serviceResourceUpdate,
        );

      return serviceResourceData;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.serviceResource] });
    },
  });
}

export function useUpsertServiceResource() {
  const activeOrgId = useActiveOrganizationId();

  return useMutation({
    mutationFn: async (
      upsertData: ServiceResourceUpdate & {
        organization_id?: string | undefined;
      },
    ) => {
      if (!upsertData.listing_id || !upsertData.name) throw new Error();

      let organizationId = upsertData.organization_id;

      if (!organizationId) {
        if (!activeOrgId) {
          throw new Error("No active organization id");
        }

        if (activeOrgId === ALL_ORGANIZATIONS) {
          throw new Error("Multiple active organization ids");
        }

        organizationId = activeOrgId;
      }

      return supabase
        .from(TABLE)
        .upsert(
          {
            ...upsertData,
            organization_id: upsertData.organization_id ?? organizationId,
          } as ServiceResource,
          { onConflict: "listing_id,organization_id" },
        )
        .select("*")
        .limit(1)
        .maybeSingle();
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.serviceResource] });
    },
  });
}
export function useUpdateServiceResource() {
  return useMutation({
    mutationFn: async (serviceResourceUpdate: ServiceResourceUpdate) => {
      if (!serviceResourceUpdate.id) return null;
      return supabase
        .from(TABLE)
        .update(serviceResourceUpdate)
        .eq("id", serviceResourceUpdate.id);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.serviceResource] });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.serviceEngagements],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.chatGptConversation],
      });
    },
  });
}

export function useServiceResourceByCategory(category: string) {
  const activeOrgId = useActiveOrganizationId();
  return useQuery({
    queryKey: [QUERY_KEYS.serviceResource, { category, activeOrgId }],
    queryFn: async () => {
      if (!category || !activeOrgId) return null;

      let query = supabase
        .from(TABLE)
        .select("*")
        .textSearch("categories", category);

      if (activeOrgId !== ALL_ORGANIZATIONS) {
        query = query.eq("organization_id", activeOrgId);
      }

      const { data, error } = await query;

      return data;
    },
  });
}
