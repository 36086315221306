import { DiscussionType, UserDiscussion } from "components/ChatGptSideBar";
import { userUpdatesStore } from "state/userUpdate/userUpdate";

export default function UpdatePageDiscussionSection({
  isEditing,
}: {
  isEditing: boolean;
}) {
  const activeUserUpdateId = userUpdatesStore(
    (state) => state.activeUserUpdateId,
  );
  return (
    <div className="mt-10 flex flex-col gap-10">
      <p className="text-xl">Discussion</p>
      {activeUserUpdateId ? (
        <div className="border rounded-md overflow-hidden py-2">
          <UserDiscussion
            discussionType={DiscussionType.UserUpdate}
            threadId={activeUserUpdateId}
          />
        </div>
      ) : (
        <p className="text-sm">
          Once an update is posted, you'll be able to see the discussion here.
        </p>
      )}
    </div>
  );
}
