import {
  useActiveCarespace,
  useAddConversationToActiveCarespace,
} from "backend/resources/carespace/carespace";
import {
  ConversationType,
  useMutateChatGptConversations,
} from "backend/resources/chatGptConversation";
import { useChatGptMessages } from "backend/resources/chatGptMessage";
import { MessagesThread } from "components/ChatGptSideBar/MessagesThread";
import { useEffect } from "react";

export function CarespaceDiscussion() {
  const { data: carespace } = useActiveCarespace();
  const { messages } = useChatGptMessages(carespace?.id, "carespace");

  const addConversationToActiveCarespace =
    useAddConversationToActiveCarespace().mutateAsync;
  const createConversation = useMutateChatGptConversations().mutateAsync;

  useEffect(() => {
    async function openCarespaceDiscussionThread() {
      if (carespace?.conversation_id) return;
      const newConversation = await createConversation({
        type: ConversationType.General,
        carespaceId: carespace?.id,
      });
      if (newConversation) {
        const newConversationId = newConversation.id;
        await addConversationToActiveCarespace(newConversationId);
      }
    }
    openCarespaceDiscussionThread();
  }, [carespace, addConversationToActiveCarespace, createConversation]);

  return (
    <div className="flex flex-col max-h-[40rem] flex-grow w-full h-full">
      <MessagesThread threadId={carespace?.id} pageType="carespace">
        {!messages && <InitialContent />}{" "}
      </MessagesThread>
    </div>
  );
}

function InitialContent() {
  return (
    <div className="flex flex-col pt-6 text-sm">
      <p>No Discussion yet. Write into the chat below to get started.</p>
    </div>
  );
}
