import * as Sentry from "@sentry/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { queryClient } from "app";
import { QUERY_KEYS } from "backend/query-keys";
import type { Carespace } from "backend/resources/carespace/carespace";
import { useCarespacesInOrganization } from "backend/resources/carespace/carespace";
import {
  ConversationType,
  useMutateChatGptConversations,
} from "backend/resources/chatGptConversation";
import type { ServiceResource } from "backend/resources/services/serviceResource";
import { supabase } from "clients/supabaseClient";
import { ALL_ORGANIZATIONS } from "features/routing/layouts/components/organization-switcher";
import { useActiveOrganizationId } from "state/organization/organization";
import type { Database } from "../../../../types/supabase";

const TABLE = "service_engagement";
export type ServiceEngagement =
  Database["public"]["Tables"]["service_engagement"]["Row"];
export type ServiceEngagementInsert =
  Database["public"]["Tables"]["service_engagement"]["Insert"];
export type ServiceEngagementUpdate =
  Database["public"]["Tables"]["service_engagement"]["Update"];

export type ServiceEngagementWithServiceResource = ServiceEngagement & {
  service_resource: ServiceResource | null;
};
export type ServiceEngagementWithServiceResourceAndCarespace =
  ServiceEngagementWithServiceResource & {
    carespace: Carespace | null;
  };

export function useServiceEngagementByServiceResourceIdAndCarespaceId({
  service_resource_id,
  carespace_id,
}: {
  service_resource_id: string | undefined | null;
  carespace_id: string | undefined | null;
}) {
  return useQuery({
    queryKey: [
      QUERY_KEYS.serviceEngagements,
      { service_resource_id, carespace_id },
    ],
    queryFn: () =>
      fetchServiceEngagementByServiceResourceIdAndCarespaceId(
        service_resource_id,
        carespace_id,
      ),
  });
}

export async function fetchServiceEngagementByServiceResourceIdAndCarespaceId(
  service_resource_id: string | undefined | null,
  carespace_id: string | undefined | null,
) {
  if (!service_resource_id || !carespace_id) return null;
  const { data, error } = await supabase
    .from(TABLE)
    .select("*")
    .eq("service_resource_id", service_resource_id)
    .eq("carespace_id", carespace_id)
    .limit(1)
    .maybeSingle();
  return data;
}

export function useServiceEngagementByServiceRequestId({
  service_request_id,
}: {
  service_request_id: string | undefined | null;
}) {
  return useQuery({
    queryKey: [QUERY_KEYS.serviceEngagements, { service_request_id }],
    queryFn: async () => {
      if (!service_request_id) return null;
      const { data, error } = await supabase
        .from("service_request_to_engagement")
        .select("*, service_engagement(*)")
        .eq("plan_entry_id", service_request_id)
        .limit(1)
        .maybeSingle();

      if (error) {
        Sentry.captureException(error);
        throw new Error(error.message);
      }
      return data?.service_engagement;
    },
  });
}

export function useServiceEngagementById(
  serviceEngagementId: string | undefined | null,
) {
  return useQuery({
    queryKey: [QUERY_KEYS.serviceEngagements, { serviceEngagementId }],
    queryFn: async () => {
      if (!serviceEngagementId) return null;
      const { data, error } = await supabase
        .from(TABLE)
        .select("*, service_resource(*)")
        .eq("id", serviceEngagementId)
        .limit(1)
        .maybeSingle();
      if (error) {
        Sentry.captureException(error);
        throw new Error(error.message);
      }
      return data as ServiceEngagementWithServiceResource;
    },
  });
}

export function useServiceEngagements() {
  const activeOrgId = useActiveOrganizationId();
  const { carespaces } = useCarespacesInOrganization();
  const carespaceIds = carespaces?.map((carespace) => carespace.id) ?? [];
  return useQuery({
    queryKey: [QUERY_KEYS.serviceEngagements, { activeOrgId, carespaceIds }],
    queryFn: async () => {
      const { data, error } = await supabase
        .from(TABLE)
        .select("*, service_resource(*),  carespace(*)")
        .in("carespace_id", carespaceIds);

      if (error) {
        Sentry.captureException(error);
        throw new Error(error.message);
      }
      const filteredData =
        activeOrgId === ALL_ORGANIZATIONS
          ? data
          : data.filter(
              (entry) => entry.carespace?.organization_id === activeOrgId,
            );
      return filteredData as ServiceEngagementWithServiceResourceAndCarespace[];
    },
  });
}

export function useCreateServiceEngagement() {
  return useMutation({
    mutationFn: async ({
      serviceEngagement,
      serviceRequestId,
    }: {
      serviceEngagement: ServiceEngagementInsert;
      serviceRequestId: string;
    }) => {
      const { data, error } = await supabase
        .from(TABLE)
        .insert(serviceEngagement)
        .select()
        .limit(1)
        .maybeSingle();

      if (error) {
        Sentry.captureException(error);
        throw new Error(error.message);
      }

      if (!data) {
        throw new Error("Failed to create service engagement");
      }

      await supabase
        .from("service_request_to_engagement")
        .insert({
          plan_entry_id: serviceRequestId,
          service_engagement_id: data.id,
        })
        .select()
        .limit(1)
        .maybeSingle();

      return data;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.serviceEngagements],
      });
    },
  });
}

export function useUpdateServiceEngagement() {
  return useMutation({
    mutationFn: async (serviceEngagement: ServiceEngagementUpdate) => {
      if (serviceEngagement.id) {
        const { data, error } = await supabase
          .from(TABLE)
          .update(serviceEngagement)
          .eq("id", serviceEngagement.id);
        if (error) {
          Sentry.captureException(error);
          throw new Error(error.message);
        }
        return data;
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.chatGptConversation],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.serviceEngagements],
      });
    },
  });
}

export function useDeleteServiceEngagement() {
  return useMutation({
    mutationFn: async (serviceEngagementId: string) => {
      const { data, error } = await supabase
        .from(TABLE)
        .delete()
        .eq("id", serviceEngagementId);
      if (error) {
        Sentry.captureException(error);
        throw new Error(error.message);
      }
      return data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.serviceEngagements],
      });
    },
  });
}

export function useAddConversationToServiceEngagement() {
  const updateServiceEngagement = useUpdateServiceEngagement().mutateAsync;
  const chatGptConversationMutation = useMutateChatGptConversations();
  return useMutation({
    mutationFn: async ({
      serviceEngagementId,
      carespaceId,
    }: {
      serviceEngagementId: string | undefined;
      carespaceId: string | undefined;
    }) => {
      if (!serviceEngagementId) return;
      const newConversation = await chatGptConversationMutation.mutateAsync({
        type: ConversationType.Service,
        carespaceId,
      });
      updateServiceEngagement({
        id: serviceEngagementId,
        conversation_id: newConversation?.id,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.serviceEngagements],
      });
    },
  });
}
