import type { CarespacesWithPatientAndCaregivers } from "backend/resources/carespace/carespace";
import { ConversationType } from "backend/resources/chatGptConversation";
import { useOrgs } from "backend/resources/orgRole";
import { Select } from "components/Select";
import { ALL_ORGANIZATIONS } from "features/routing/layouts/components/organization-switcher";
import type { FC } from "react";
import { useMemo } from "react";
import { useDiscussionFilterStore } from "state/discussionsFilter/discussionsFilter";
import { useActiveOrganizationId } from "state/organization/organization";

export const DiscussionTypeToColor: Record<ConversationType, string> = {
  [ConversationType.Request]: "#8185F0",
  [ConversationType.Service]: "#98C3EA",
  [ConversationType.General]: "#F6B26B",
  [ConversationType.Private]: "#2A8EE9",
  [ConversationType.Family]: "#6064BE",
  [ConversationType.Alfred]: "",
};

export enum DiscussionFilterTypes {
  ORG = "Org",
  CARESPACE = "Carespace",
  TYPE = "Type",
  ORGANIZATION = "Organization",
}

export interface DiscussionsFilterConfig {
  [DiscussionFilterTypes.ORG]?: boolean;
  [DiscussionFilterTypes.CARESPACE]?: boolean;
  [DiscussionFilterTypes.TYPE]?: boolean;
  [DiscussionFilterTypes.ORGANIZATION]?: boolean;
}

export enum DiscussionFields {
  isUnread = "isUnread",
  Carespace = "Carespace",
  Title = "Title",
  Type = "Type",
  LatestMessage = "Latest Message",
  SentAt = "Sent",
  SentBy = "Sent By",
  UNDERLYING_OBJECT_ID = "underlying_object_Id",
  carespace_id = "carespace_id",
  isExternal = "isExternal",
  Id = "id",
}

export type DiscussionTableType = {
  [DiscussionFields.isUnread]: boolean;
  [DiscussionFields.SentAt]: string;
  [DiscussionFields.LatestMessage]: string;
  [DiscussionFields.Title]: string;
  [DiscussionFields.Type]: ConversationType;
  [DiscussionFields.Carespace]: string;
  [DiscussionFields.UNDERLYING_OBJECT_ID]: string;
  [DiscussionFields.carespace_id]: string;
  [DiscussionFields.isExternal]: boolean;
  [DiscussionFields.Id]: string;
  [DiscussionFields.SentBy]: string;
  subRows?: DiscussionTableType[] | undefined;
};

interface DiscussionFiltersProps {
  filterConfig: DiscussionsFilterConfig;
  carespaces: CarespacesWithPatientAndCaregivers[];
}

export const DiscussionFilters: FC<DiscussionFiltersProps> = ({
  filterConfig,
  carespaces,
}) => {
  const organizationId = useActiveOrganizationId();
  const { carespace, type, setCarespace, setType } = useDiscussionFilterStore();
  const { hasCareCentralAccess } = useOrgs();

  // Filter carespaces based on selected organization
  const filteredCarespaces = useMemo(() => {
    if (!organizationId) return carespaces;

    if (organizationId === ALL_ORGANIZATIONS) {
      return carespaces;
    }

    return carespaces.filter((cs) => cs.organization_id === organizationId);
  }, [carespaces, organizationId]);

  // Get carespace options from filtered carespaces
  const carespaceOptions = useMemo(() => {
    const options = [{ value: "All", label: "All" }];
    const uniqueCarespaces = new Set();

    for (const cs of filteredCarespaces) {
      const { name } = cs;
      if (!uniqueCarespaces.has(name) && name) {
        uniqueCarespaces.add(name);
        options.push({ value: cs.id, label: name || "" });
      }
    }

    return options;
  }, [filteredCarespaces]);

  const conversationTypeOptions = useMemo(
    () =>
      Object.values(ConversationType)
        .filter((typeOption) => {
          if (typeOption === ConversationType.Alfred) {
            return false;
          }
          if (hasCareCentralAccess) {
            return typeOption !== ConversationType.Family;
          }
          return typeOption !== ConversationType.Private;
        })
        .map((discussionType) => ({
          label: discussionType,
          value: discussionType,
        })),
    [hasCareCentralAccess],
  );

  return (
    <div className="whitespace-nowrap w-full flex items-center overflow-x-auto text-sm h-min gap-x-6 gap-y-2 py-2">
      {filterConfig[DiscussionFilterTypes.CARESPACE] && (
        <div className="flex gap-4 items-center shrink-0">
          <p className="shrink-0">{DiscussionFilterTypes.CARESPACE}</p>
          <Select
            currentOption={carespaceOptions.find(
              (option) => option.value === carespace,
            )}
            options={carespaceOptions}
            onChange={(carespaceId: string) => {
              setCarespace(carespaceId === "All" ? undefined : carespaceId);
            }}
            classNames="w-[200px]"
            isSearchable
            placeHolder="Search carespaces..."
          />
        </div>
      )}
      {filterConfig[DiscussionFilterTypes.TYPE] && (
        <div className="flex gap-4 items-center shrink-0">
          <p className="shrink-0">{DiscussionFilterTypes.TYPE}</p>
          <Select
            currentOption={
              type
                ? { value: type.toString(), label: type.toString() }
                : { value: undefined, label: "All" }
            }
            options={[
              { value: "All", label: "All" },
              ...conversationTypeOptions,
            ]}
            onChange={(typeOption: string) =>
              setType(typeOption === "All" ? undefined : typeOption)
            }
            classNames="w-[200px]"
          />
        </div>
      )}
    </div>
  );
};
