import { zodResolver } from "@hookform/resolvers/zod";
import type { YellowPagesListing } from "backend/functions";
import { useSearchLocalResources } from "backend/functions";
import { ButtonWithIcon, IconOption } from "components/ButtonWithIcon";
import { LoadingSpinner } from "components/LoadingSpinner";
import { LocalResourceCard } from "components/ResourcesPage/components/LocalResourceCard";
import { yellowPageCategoryOptions } from "components/ResourcesPage/components/categories";
import { Select } from "components/Select";
import { TextInput } from "components/TextInput";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Form, FormField } from "shared/ui/form";
import * as z from "zod";

const searchFormSchema = z
  .object({
    zipCode: z.string().refine((zipCode) => zipCode.length === 5, {
      message: "Zip code must be exactly 5 characters long",
    }),
    searchText: z.string().optional(),
    selectedCategory: z.string().optional(),
  })
  .refine((data) => !!data.searchText || !!data.selectedCategory, {
    message: "Please provide either a search text or select a category",
    path: ["selectedCategory"],
  });

type FormData = z.infer<typeof searchFormSchema>;

export function LocalServicesSearch({
  backgroundColor,
  selectedLocalResources,
  onSelectLocalResource,
  hideCheckbox,
}: {
  backgroundColor: string;
  selectedLocalResources: YellowPagesListing[] | undefined | null;
  onSelectLocalResource: (value: YellowPagesListing | null | undefined) => void;
  hideCheckbox?: boolean;
}) {
  const form = useForm<FormData>({
    resolver: zodResolver(searchFormSchema),
    mode: "onSubmit",
  });
  const formValues = form.watch();

  const [searchParams, setSearchParams] = useState<FormData | null>(null);

  const { data: results, isLoading } = useSearchLocalResources(
    searchParams?.zipCode,
    searchParams?.searchText || searchParams?.selectedCategory || "",
  );

  const onSubmit = (data: FormData) => {
    setSearchParams(data);
  };

  return (
    <div className="flex flex-col gap-2  min-w-3xl w-3xl max-w-3xl ">
      <div
        className={`flex flex-col gap-4 p-4 text-sm overflow-scroll ${backgroundColor}`}
      >
        <Form form={form} id="local-services-search">
          <div className="flex gap-6 justify-between">
            <FormField
              control={form.control}
              name="zipCode"
              label="Zip*"
              render={({ field }) => (
                <TextInput
                  data-hj-allow
                  {...field}
                  className="max-w-[10ch] placeholder:text-xs"
                />
              )}
            />
            <div className="flex flex-col gap-5 w-full flex-grow">
              <FormField
                control={form.control}
                name="searchText"
                label="Search"
                render={({ field }) => (
                  <TextInput
                    {...field}
                    data-hj-allow
                    className="w-full placeholder:text-sm"
                    placeholder="Enter text..."
                  />
                )}
              />
              <FormField
                control={form.control}
                name="selectedCategory"
                label="OR"
                render={({ field }) => {
                  const disabled = !!formValues.searchText;
                  return (
                    <Select
                      {...field}
                      placeHolder={
                        disabled
                          ? "Remove text to select a category"
                          : "Please select..."
                      }
                      disabled={disabled}
                      classNames="p-0.5 pr-2 h-[2.5rem] w-full bg-white"
                      borderClass="border-2 border-neutral-200"
                      currentOption={yellowPageCategoryOptions.find(
                        (option) => option.value === field.value,
                      )}
                      options={yellowPageCategoryOptions}
                    />
                  );
                }}
              />
            </div>
          </div>

          <ButtonWithIcon
            size="small"
            className="w-full justify-end"
            onClick={form.handleSubmit(onSubmit)}
            text="Search"
            type="button"
            icon={IconOption.ARROW}
          />
        </Form>
      </div>

      <LocalServicesSearchResults
        searchParams={searchParams}
        isLoading={isLoading}
        results={results ?? []}
        hideCheckbox={hideCheckbox}
        selectedLocalResources={selectedLocalResources ?? []}
        onSelectLocalResource={onSelectLocalResource}
      />
    </div>
  );
}

interface Props {
  searchParams: FormData | null;
  isLoading: boolean;
  results: YellowPagesListing[];
  hideCheckbox?: boolean;
  selectedLocalResources: YellowPagesListing[] | undefined | null;
  onSelectLocalResource: (value: YellowPagesListing | null | undefined) => void;
}

function LocalServicesSearchResults({
  searchParams,
  isLoading,
  results,
  hideCheckbox,
  selectedLocalResources,
  onSelectLocalResource,
}: Props) {
  return (
    searchParams && (
      <div className="flex flex-col gap-4 mt-2 max-h-[45vh] overflow-auto">
        {isLoading ? (
          <div className="flex flex-col justify-center items-center">
            <LoadingSpinner className="w-8 h-8" />
          </div>
        ) : (
          results?.map((result) => (
            <LocalResourceCard
              hideCheckbox={hideCheckbox}
              listing={result}
              selectedLocalResources={selectedLocalResources}
              onSelectLocalResource={onSelectLocalResource}
            />
          ))
        )}
      </div>
    )
  );
}
