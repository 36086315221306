import { supabase } from "clients/supabaseClient";
import { useEffect, useState } from "react";
import { useToast } from "shared/hooks/use-toast";
import queryClient from "shared/query-client";
import { Database } from "types/supabase";
import { v4 as uuidv4 } from "uuid";

// Types
type FeedbackType = Database["public"]["Enums"]["feedback_type"];

type Message = {
  id: string;
  content: string;
  role: "user" | "assistant";
  created_at: string;
  feedback?: FeedbackType;
};

type ChatResult = {
  message: string;
  conversation_id: string;
  message_id: string;
};

export function useAlfredChat(carespaceId: string) {
  const { toast } = useToast();
  const [messages, setMessages] = useState<Message[]>([]);
  const [streaming, setStreaming] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [conversationId, setConversationId] = useState<string | undefined>(
    undefined,
  );

  // Invalidate activities query whenever conversationId changes
  useEffect(() => {
    if (conversationId && carespaceId) {
      // Invalidate the activities query to update the timeline
      queryClient.invalidateQueries({
        queryKey: ["activities", carespaceId],
      });
    }
  }, [conversationId, carespaceId]);

  const sendMessage = async (userMessage: string) => {
    if (!userMessage.trim() || isLoading) return;

    // Add user message to chat
    const newMessage: Message = {
      id: uuidv4(),
      content: userMessage,
      role: "user",
      created_at: new Date().toISOString(),
    };

    setMessages((prev) => [...prev, newMessage]);
    setIsLoading(true);

    try {
      const { data, error } = await supabase.functions.invoke<ChatResult>(
        "chat_message",
        {
          body: {
            carespace_id: carespaceId,
            message: userMessage,
            conversation_id: conversationId,
          },
        },
      );

      if (error) {
        throw new Error(error.message || "Failed to send message");
      }

      // Save the conversation ID for future messages
      if (data?.conversation_id) {
        setConversationId(data.conversation_id);
      }

      // Show streaming effect for AI response
      setStreaming("");
      let streamingText = "";
      const botResponse =
        data?.message || "Sorry, I couldn't process your request";
      let index = 0;

      const interval = setInterval(() => {
        if (index < botResponse.length) {
          streamingText += botResponse.charAt(index);
          setStreaming(streamingText);
          index++;
        } else {
          clearInterval(interval);

          // Save the complete message
          setTimeout(() => {
            const newMessage: Message = {
              id: data?.message_id || uuidv4(),
              content: botResponse,
              role: "assistant",
              created_at: new Date().toISOString(),
            };

            setMessages((prev) => [...prev, newMessage]);
            setStreaming(null);
            setIsLoading(false);
          }, 500);
        }
      }, 15);
    } catch (error) {
      console.error("Error sending message:", error);
      setIsLoading(false);

      // Show error message
      const errorMessage: Message = {
        id: uuidv4(),
        content:
          "Sorry, I couldn't process your request. Please try again later.",
        role: "assistant",
        created_at: new Date().toISOString(),
      };

      setMessages((prev) => [...prev, errorMessage]);
    }
  };

  // Handle feedback submission
  const handleFeedback = async (messageId: string, feedback: FeedbackType) => {
    try {
      // Update message in database
      const { error } = await supabase
        .from("chat_gpt_message")
        .update({ feedback })
        .eq("id", messageId);

      if (error) {
        console.error("Error updating feedback:", error);
        return;
      }

      // Update message in state
      setMessages(
        messages.map((msg) =>
          msg.id === messageId ? { ...msg, feedback } : msg,
        ),
      );
    } catch (error) {
      console.error("Error submitting feedback:", error);
      toast({
        title: "Error submitting feedback",
        description: "Please try again later",
        variant: "destructive",
      });
    }
  };

  return {
    messages,
    streaming,
    isLoading,
    sendMessage,
    setMessages,
    handleFeedback,
  };
}
