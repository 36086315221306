import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { QueryClientProvider } from "@tanstack/react-query";
import OneSignalComponent from "components/OneSignalComponent/OneSignalComponent";
import "react-loading-skeleton/dist/skeleton.css";
import { Route, Routes } from "react-router-dom";

import routes from "features/routing/routes";
import { AuthUserProvider } from "features/users/auth";

import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { Layout } from "features/routing/constants";
import PrivateLayout from "features/routing/layouts/private";
import PublicLayout from "features/routing/layouts/public";
import RestrictedLayout from "features/routing/layouts/restricted";

import { useState } from "react";
import queryClient from "shared/query-client";
import { DialogContainerContext } from "shared/ui/dialog";
import { Toaster } from "shared/ui/toaster";

const layoutMap = {
  [Layout.PUBLIC]: PublicLayout,
  [Layout.RESTRICTED]: RestrictedLayout,
  [Layout.PRIVATE]: PrivateLayout,
};

export function App() {
  const [dialogContainer, setDialogContainer] = useState<HTMLDivElement | null>(
    null,
  );

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <DialogContainerContext.Provider value={dialogContainer}>
          <AuthUserProvider>
            <OneSignalComponent />
            <Routes>
              {routes.map(({ path, layout, element }) => {
                const LayoutComponent = layoutMap[layout];

                return (
                  <Route
                    key={path}
                    path={path}
                    element={<LayoutComponent>{element}</LayoutComponent>}
                  />
                );
              })}
            </Routes>
            <Toaster />

            <div id="dialog-container" ref={setDialogContainer} />
          </AuthUserProvider>
        </DialogContainerContext.Provider>
      </QueryClientProvider>
    </LocalizationProvider>
  );
}

export { default as queryClient } from "shared/query-client";
