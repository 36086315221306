import * as Sentry from "@sentry/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { queryClient } from "app";
import { fetchConversations } from "backend/functions/functions";
import { QUERY_KEYS } from "backend/query-keys";
import {
  useActiveCarespace,
  useAllCarespaces,
} from "backend/resources/carespace/carespace";
import type { ConversationType } from "backend/resources/chatGptConversation";
import {
  useServiceDiscussion,
  useUserUpdateDiscussion,
} from "backend/resources/chatGptConversation";
import { supabase } from "clients/supabaseClient";
import { useAuthUser } from "features/users/auth";
import { useEffect } from "react";
import { useActiveCarespaceId } from "state/carespace/carespace";
import type { SideBarPageType } from "state/gpt";
import type { Database } from "../../../../types/supabase";

const TABLE = "chat_gpt_message";

// queries

export type ChatGptMessage =
  Database["public"]["Tables"]["chat_gpt_message"]["Row"];

async function fetchChatGptMessagesByConversationId(conversationId?: string) {
  if (!conversationId) return null;
  const { data: messages, error: conversationError } = await supabase
    .from("chat_gpt_message")
    .select("*,read_message(*)")
    .eq("chat_gpt_conversation_id", conversationId);

  return messages?.sort(
    (a, b) => Date.parse(a.created_at) - Date.parse(b.created_at),
  ) as ChatGptMessage[];
}

async function fetchMessagesByUserUpdateId(userUpdateId?: string) {
  if (!userUpdateId) return null;
  // Execute the queries in parallel
  const { data: pageData, error: pageError } = await supabase
    .from("user_update")
    .select("chat_gpt_conversation(chat_gpt_message(*,read_message(*)))")
    .eq("id", userUpdateId)
    .limit(1)
    .returns<
      { chat_gpt_conversation: { chat_gpt_message: ChatGptMessage[] } }[]
    >()
    .maybeSingle();

  // Check for errors and throw if necessary
  if (pageError) {
    Sentry.captureException(pageError);
    throw new Error(pageError.message);
  }

  return pageData?.chat_gpt_conversation.chat_gpt_message?.sort(
    (a, b) => Date.parse(a.created_at) - Date.parse(b.created_at),
  );
}

export type DiscussionMessage = ChatGptMessage & {
  is_read: boolean;
  carespace_id: string;
  title: string;
  external_participant_id?: string;
  type: ConversationType;
  id: string;
  conversation_id: string;
  sent_by: string;
  carespace_name: string;
  organization_id: string;
};

async function fetchMessagesByServiceId(
  serviceId?: string,
  isExternal = false,
) {
  if (!serviceId) return null;
  // Execute the queries in parallel

  const externalKey = isExternal
    ? "public_service_engagement_external_conversation_id_fkey"
    : "service_engagement_conversation_id_fkey";
  const { data: pageData, error: pageError } = await supabase
    .from("service_engagement")
    .select(
      `*, chat_gpt_conversation!${externalKey} (
        chat_gpt_message!inner(*)
      )
    `,
    )
    .eq("id", serviceId)
    .limit(1)
    .maybeSingle();

  // Check for errors and throw if necessary
  if (pageError) {
    Sentry.captureException(pageError);
    throw new Error(pageError.message);
  }

  return pageData?.chat_gpt_conversation?.chat_gpt_message?.sort(
    (a, b) => Date.parse(a.created_at) - Date.parse(b.created_at),
  );
}

async function fetchMessagesByCarespaceId(carespaceId?: string) {
  if (!carespaceId) return null;
  // Execute the queries in parallel
  const { data: pageData, error: pageError } = await supabase
    .from("carespace")
    .select(
      "chat_gpt_conversation!carespace_conversation_id_fkey(chat_gpt_message(*,read_message(*)))",
    )
    .eq("id", carespaceId)
    .limit(1)
    .returns<
      { chat_gpt_conversation: { chat_gpt_message: ChatGptMessage[] } }[]
    >()
    .maybeSingle();

  // Check for errors and throw if necessary
  if (pageError) {
    Sentry.captureException(pageError);
    throw new Error(pageError.message);
  }

  return pageData?.chat_gpt_conversation.chat_gpt_message?.sort(
    (a, b) => Date.parse(a.created_at) - Date.parse(b.created_at),
  );
}

async function fetchChatGptMessageById(chatGptMessageId?: string | null) {
  if (!chatGptMessageId) return null;
  const { data, error } = await supabase
    .from("chat_gpt_message")
    .select("*,read_message(*)")
    .eq("id", chatGptMessageId)
    .limit(1)
    .maybeSingle();

  // Check for errors and throw if necessary
  if (error) {
    Sentry.captureException(error);
    throw new Error(error.message);
  }
  return data;
}

/**
 * Hook to fetch all messages for the provided thread.
 * Determines which query function (& conversationId aggregation strategy) to use using pageType.
 */
export const useChatGptMessage = (messageId?: string | null) => {
  const { isLoading, isFetching, data, error, refetch } = useQuery({
    queryKey: [
      QUERY_KEYS.chatGptMessages,
      {
        messageId,
      },
    ],
    queryFn: () => fetchChatGptMessageById(messageId),
    refetchOnWindowFocus: false,
  });

  return {
    isLoadingMessage: isLoading,
    isFetchingMessage: isFetching,
    message: data,
    messageError: error,
    refetchMessage: refetch,
  };
};

// TODO: instead of threading... can we just use conversationId? Need to simplify
export const useChatGptMessages = (
  threadId: string | undefined,
  pageType: SideBarPageType,
) => {
  const { authUser } = useAuthUser();
  const carespaceId = useActiveCarespaceId();
  const {
    isLoading: isLoadingMessages,
    isFetching: isFetchingMessages,
    data: messages,
    error: messagesError,
    refetch: refetchMessages,
  } = useQuery({
    queryKey: [
      QUERY_KEYS.chatGptMessages,
      {
        threadId,
        pageType,
      },
    ],
    queryFn: () => {
      switch (pageType) {
        case "service": {
          return fetchMessagesByServiceId(threadId);
        }
        case "serviceExternal": {
          return fetchMessagesByServiceId(threadId, true);
        }
        case "carespace": {
          return fetchMessagesByCarespaceId(threadId);
        }
        case "userUpdate": {
          return fetchMessagesByUserUpdateId(threadId);
        }
        case "private": {
          return fetchChatGptMessagesByConversationId(threadId);
        }
        default: {
          return [];
        }
      }
    },
    refetchOnWindowFocus: false,
  });

  let liveConversationId: string | null | undefined;
  switch (pageType) {
    case "userUpdate": {
      liveConversationId =
        useUserUpdateDiscussion(threadId)?.data?.conversation_id;
      break;
    }
    case "carespace": {
      liveConversationId = useActiveCarespace()?.data?.conversation_id;
      break;
    }
    case "service": {
      liveConversationId = useServiceDiscussion(threadId)?.data;
      break;
    }
    case "serviceExternal": {
      liveConversationId = useServiceDiscussion(threadId, true)?.data;
      break;
    }
    case "private": {
      liveConversationId = threadId;
      break;
    }
    default: {
      liveConversationId = null;
    }
  }

  // subscribe to insert changes in the user_to_notification table
  useEffect(() => {
    const realtimeUpdatesPageTypes = [
      "carespace",
      "userUpdate",
      "serviceRequest",
      "serviceRequestExternal",
      "service",
      "serviceExternal",
      "private",
    ];
    if (realtimeUpdatesPageTypes.includes(pageType)) {
      const subscription = supabase
        .channel("table-filter-changes")
        .on(
          "postgres_changes",
          {
            event: "INSERT",
            schema: "public",
            table: "chat_gpt_message",
            filter: `chat_gpt_conversation_id=eq.${liveConversationId}`,
          },
          () => {
            refetchMessages();
          },
        )
        .subscribe();
      return () => {
        subscription.unsubscribe();
      };
    }
  }, [liveConversationId, pageType, refetchMessages]);

  return {
    // messages
    isLoadingMessages,
    isFetchingMessages,
    messages,
    messagesError,
    refetchMessages,
  };
};

export const useLatestMessages = () => {
  const { carespaces } = useAllCarespaces();
  const carespaceIds = carespaces?.map((carespace) => carespace.id) ?? [];
  const { authUser } = useAuthUser();
  return useQuery({
    queryKey: [
      QUERY_KEYS.latestMessages,
      { carespaceIds, userId: authUser?.id },
    ],
    queryFn: async () => fetchConversations(authUser?.id, carespaceIds),
  });
};

export type ChatGptMessageInsert =
  Database["public"]["Tables"]["chat_gpt_message"]["Insert"];

export async function saveChatGptMessage(message: ChatGptMessageInsert) {
  const { data, error } = await supabase.from(TABLE).insert(message).select();
  if (error) {
    Sentry.captureException(error);
    throw new Error(error.message);
  }

  return data;
}

export function useMutateChatGptMessages() {
  return useMutation({
    mutationFn: ({
      message,
      threadId,
    }: {
      message: ChatGptMessageInsert;
      threadId: string;
    }) => saveChatGptMessage(message),
    // When mutate is called:
    onMutate: async ({
      message,
      threadId,
    }: {
      message: ChatGptMessageInsert;
      threadId: string;
    }) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({
        queryKey: [QUERY_KEYS.chatGptMessages, { threadId }],
      });

      // Snapshot the previous value
      const previousMessages = queryClient.getQueryData([
        QUERY_KEYS.chatGptMessages,
        { threadId },
      ]);

      // Optimistically update to the new value
      queryClient.setQueryData(
        // query key
        [QUERY_KEYS.chatGptMessages, { threadId }],
        // updater fn
        (oldMessages) => {
          if (oldMessages) {
            return [...(oldMessages as ChatGptMessageInsert[]), message];
          }
          return [message];
        },
      );

      // Return a context object with the snapshotted value
      return { previousMessages };
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (
      err,
      {
        message,
        threadId,
      }: {
        message: ChatGptMessageInsert;
        threadId: string;
      },
      context,
    ) => {
      queryClient.setQueryData(
        // query key
        [QUERY_KEYS.chatGptMessages, { threadId }],
        // what we're setting the key to
        context?.previousMessages,
      );
    },
    // Always refetch after error or success:
    onSettled: (
      data,
      error,
      {
        message,
        threadId,
      }: {
        message: ChatGptMessageInsert;
        threadId: string;
      },
    ) => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.chatGptMessages, { threadId }],
      });
    },
  });
}
