import { useAutoAnimate } from "@formkit/auto-animate/react";
import * as AccordionPrimitive from "@radix-ui/react-accordion";
import { ChevronDown } from "lucide-react";
import * as React from "react";

import { cn } from "shared/utils/cn";

const AccordionItem = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Item>
>(({ className, ...props }, ref) => (
  <AccordionPrimitive.Item
    ref={ref}
    className={cn("border rounded", className)}
    {...props}
  />
));
AccordionItem.displayName = "AccordionItem";

const AccordionTrigger = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Trigger>
>(({ className, children, ...props }, ref) => (
  <AccordionPrimitive.Header className="flex">
    <AccordionPrimitive.Trigger
      ref={ref}
      className={cn(
        "flex flex-1 items-center gap-2 p-4 transition-all hover:bg-neutral-100/50 [&[data-state=open]>svg]:rotate-180",
        className,
      )}
      {...props}
    >
      <ChevronDown className="h-4 w-4 text-brand-orange shrink-0 transition-transform duration-200" />
      {children}
    </AccordionPrimitive.Trigger>
  </AccordionPrimitive.Header>
));
AccordionTrigger.displayName = AccordionPrimitive.Trigger.displayName;

const AccordionContent = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Content>
>(({ className, children, ...props }, ref) => (
  <AccordionPrimitive.Content
    ref={ref}
    className="overflow-hidden text-sm transition-all data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down"
    {...props}
  >
    <div className={cn(className)}>{children}</div>
  </AccordionPrimitive.Content>
));

AccordionContent.displayName = AccordionPrimitive.Content.displayName;

export type AccordionProps = React.ComponentPropsWithRef<
  typeof AccordionPrimitive.Root
> & {
  items: {
    value: string;
    label: React.ReactNode;
    content: React.ReactNode;
  }[];
};

const Accordion = ({ items, className, ...rest }: AccordionProps) => {
  const [animateRef] = useAutoAnimate();

  return (
    <AccordionPrimitive.Root {...rest}>
      <div ref={animateRef} className={cn("flex flex-col gap-4", className)}>
        {items.map((item) => (
          <AccordionItem key={item.value} value={item.value}>
            <AccordionTrigger>{item.label}</AccordionTrigger>
            <AccordionContent>{item.content}</AccordionContent>
          </AccordionItem>
        ))}
      </div>
    </AccordionPrimitive.Root>
  );
};

export { Accordion, AccordionContent, AccordionItem, AccordionTrigger };
