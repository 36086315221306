import { supabase } from "clients/supabaseClient";
import { ButtonWithIcon, IconOption } from "components/ButtonWithIcon";
import { Card } from "components/Card/Card";
import Carousel from "components/Carousel/Carousel";
import { CloseButton } from "components/CloseButton/CloseButton";
import { Popup } from "components/Popup";
import { useAuthUser } from "features/users/auth";
import { useSignedUrl } from "hooks/useSignedUrl";
import { useWindowSize } from "hooks/useWindowSize";
import type { ChangeEvent } from "react";
import { useRef, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useActiveCarespaceId } from "state/carespace/carespace";
import {
  addGalleryMedia,
  deleteGalleryMedia,
  userUpdatesStore,
} from "state/userUpdate/userUpdate";

export default function GallerySection({ isEditing }: { isEditing: boolean }) {
  const inputRef = useRef<HTMLInputElement>(null);
  const { authUser } = useAuthUser();
  const galleryMediaInUserUpdate = userUpdatesStore(
    (state) => state.galleryMediaInUserUpdate,
  );
  const activeUserUpdateId = userUpdatesStore(
    (state) => state.activeUserUpdateId,
  );
  const activeCarespaceId = useActiveCarespaceId();

  const { isMobile } = useWindowSize();

  const handleProfileImageUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];

    if (!file || !authUser) {
      // TODO(jen): handle error
      return;
    }

    e.target.value = "";

    const filename = `${activeCarespaceId}/${
      file.name
    }_${Date.now()}.${file.type.replace(/(.*)\//g, "")}`;
    // optimistic add to show in gallery section

    const { data, error } = await supabase.storage
      .from("update-gallery")
      .upload(filename, file, {
        cacheControl: "3600",
        upsert: true,
      });

    if (!error) {
      addGalleryMedia(filename);
    }
  };

  function openFileExplorer() {
    inputRef.current?.click();
  }

  if (!activeUserUpdateId) {
    return (
      <div className="flex flex-col  mt-12">
        <p className="text-xl ">Gallery</p>
        <p className="text-sm mt-10">
          Once an update is posted, you'll be able to see the gallery here.
        </p>
      </div>
    );
  }
  return (
    <div className="flex flex-col gap-4 mb-10">
      {(galleryMediaInUserUpdate && galleryMediaInUserUpdate.length > 0) ||
      isEditing ? (
        <div className="flex justify-between items-center">
          <p className="text-xl">Gallery</p>
          {isEditing ? (
            <div>
              <ButtonWithIcon
                text="Add"
                onClick={openFileExplorer}
                icon={IconOption.PLUS}
                size={isMobile ? "small" : "medium"}
              />
              <input
                type="file"
                accept="image/*,video/*"
                className="hidden"
                ref={inputRef}
                onChange={handleProfileImageUpload}
                data-hj-allow
              />
            </div>
          ) : null}
        </div>
      ) : null}
      {galleryMediaInUserUpdate && galleryMediaInUserUpdate.length > 0 ? (
        <Carousel
          items={galleryMediaInUserUpdate.map((fileName) => (
            <div className="flex justify-center w-full">
              <button
                type="button"
                className="absolute top-2 right-2 hover:scale-[1.15] transition-transform z-[2] "
                onClick={() => {
                  deleteGalleryMedia(fileName);
                }}
              >
                {isEditing ? <CloseButton /> : null}
              </button>
              <GalleryImage filename={fileName} />
            </div>
          ))}
        />
      ) : isEditing ? (
        <p className="text-center">
          Add photos and videos by clicking the add button.
        </p>
      ) : null}
    </div>
  );
}

function GalleryImage({ filename }: { filename?: string }) {
  const [isImagePopupOpen, setIsImagePopupOpen] = useState(false);

  const { signedUrl, isLoading: isLoadingProfileImage } = useSignedUrl({
    path: filename,
    bucket: "update-gallery",
  });

  const extension = filename?.split(".").pop();

  const isImage = ["jpg", "jpeg", "png", "gif", "bmp", "webp"].includes(
    extension?.toLowerCase() || "",
  );
  const isVideo = [
    "mp4",
    "webm",
    "ogg",
    "quicktime", // QuickTime movie
    "avi", // AVI video
    "mov", // MOV video
    "wmv", // Windows Media Video
    "mkv", // Matroska Video
    // Add more video file extensions here
  ].includes(extension?.toLowerCase() || "");

  if (!filename) {
    return null;
  }
  if (isLoadingProfileImage) {
    return <Skeleton className="mt-2 h-[240px] w-[220px]" />;
  }
  return (
    <Card border="none" color="white" padding="none">
      <div className="flex justify-center">
        {isImage && (
          <img
            className="h-full w-full object-cover cursor-pointer"
            src={signedUrl ?? ""}
            onClick={() => setIsImagePopupOpen(true)}
            alt={filename}
          />
        )}
        {isVideo && (
          <video className="h-full w-full object-cover" controls>
            <source src={signedUrl ?? ""} />
          </video>
        )}
        {isImagePopupOpen ? (
          <Popup close={() => setIsImagePopupOpen(false)}>
            <img
              className="max-w-[60rem]"
              src={signedUrl ?? ""}
              alt={filename}
            />
          </Popup>
        ) : null}
      </div>
    </Card>
  );
}
