import {
  useInsertUserDataRequest,
  useUserDataRequestQuery,
} from "backend/resources/userDataRequest";
import {
  useInsertUserDeletionRequest,
  useUserDeletionRequestQuery,
} from "backend/resources/userDeletionRequest";
import type {
  CarespaceRoleType,
  OrgRoleType,
} from "backend/resources/userRole/types";
import {
  CarespaceRoleTypeToLabel,
  OrgRoleTypeToLabel,
} from "backend/resources/userRole/types";
import BottomActionBar from "components/BottomActionBar/BottomActionBar";
import { ButtonWithIcon, IconOption } from "components/ButtonWithIcon";
import { LoadingSpinner } from "components/LoadingSpinner";
import { ProfileImage } from "components/Profile/ProfileImage";
import {
  ListType,
  RoleTableFields,
  UserRoleTable,
} from "components/Tables/UserRoleTable";
import { Route } from "features/routing/constants";
import { useAuthUser } from "features/users/auth";
import { useFetchOne } from "features/users/queries/hooks";
import { useOrgAndCarespaceIdentitiesForUser } from "hooks/orgMember/orgMember";
import { CraniometrixProduct, useProductAccess } from "hooks/product/product";
import { useWindowSize } from "hooks/useWindowSize";
import { useAppNavigate } from "lib/routing";
import { useState } from "react";
import { EditProfileForm } from "shared/forms/EditProfileForm";

import { Text } from "shared/ui/text";

export function ProfilePage() {
  const [isEditing, setIsEditing] = useState(false);
  if (isEditing) {
    return <EditProfileForm onClose={() => setIsEditing(false)} />;
  }

  return <ProfilePageView setIsEditing={setIsEditing} />;
}

function ProfilePageView({
  setIsEditing,
}: {
  setIsEditing: (isEditing: boolean) => void;
}) {
  const { authUser } = useAuthUser();
  const {
    data: user,
    isLoading,
    isError,
  } = useFetchOne({ equals: { id: authUser?.id } }, { enabled: !!authUser });
  const { isMobile } = useWindowSize();

  // Queries
  const { data: productAccess } = useProductAccess();
  const { data } = useOrgAndCarespaceIdentitiesForUser(authUser?.id);
  const { data: userDataRequest } = useUserDataRequestQuery(authUser?.id);
  const { data: userDeletionRequest } = useUserDeletionRequestQuery(
    authUser?.id,
  );

  // Mutations
  const upsertUserDeletionRequest = useInsertUserDeletionRequest().mutateAsync;
  const upsertUserDataRequest = useInsertUserDataRequest().mutateAsync;
  const navigate = useAppNavigate();

  if (isLoading) {
    <LoadingSpinner className="w-6 h-6" />;
  }

  if (isError) {
    return <Text>Sorry, something went wrong. Please try again.</Text>;
  }

  return (
    <div>
      {/* content */}
      <div className="flex flex-col w-full md:w-full relative gap-6">
        {/* profile picture row */}
        <div className="flex items-center gap-10">
          <div className="flex items-center">
            <ProfileImage size={64} className="w-16 h-16" userId={user?.id} />
            <div className="text-xl ml-4">
              {user?.first_name} {user?.last_name}
            </div>
            {/* edit button */}
          </div>
          {!isMobile ? (
            <ButtonWithIcon
              text="Edit"
              size="small"
              onClick={() => setIsEditing(true)}
              icon={IconOption.EDIT}
            />
          ) : null}
        </div>

        {/* email */}
        {user?.email ? (
          <ButtonWithIcon
            onClick={() => (window.location.href = `mailto:${user?.email}`)}
            text={user.email}
            icon={IconOption.SEND_INVITATION}
            size="small"
          />
        ) : null}
        {/* cell */}
        {user?.cell_number ? (
          <ButtonWithIcon
            onClick={() => (window.location.href = `tel:${user?.cell_number}`)}
            text={user?.cell_number}
            icon={IconOption.PHONE}
            size="small"
          />
        ) : null}
        {productAccess === CraniometrixProduct.CARE_CENTRAL ? (
          <>
            {/* organization */}
            <div className="flex flex-col w-full">
              <h2 className="text-xl">Organization(s)</h2>
              {(data?.organization_role?.length ?? 0) > 0 ? (
                <UserRoleTable
                  listType={ListType.Orgs}
                  data={
                    data?.organization_role
                      .sort(
                        (a, b) =>
                          a.organization?.name?.localeCompare(
                            b.organization?.name ?? "",
                          ) ?? 0,
                      )
                      .map((org) => ({
                        [RoleTableFields.WorkSpaceName]:
                          org.organization?.name ?? "",
                        [RoleTableFields.Role]:
                          OrgRoleTypeToLabel[org.role as OrgRoleType] ?? "",
                        [RoleTableFields.Id]: org.organization?.id || "",
                        [RoleTableFields.UserId]: org.user_id,
                        [RoleTableFields.roleObject]: org,
                      })) || []
                  }
                />
              ) : (
                <p>None</p>
              )}
            </div>
            {/* care spaces */}
            <div className="flex flex-col w-full">
              <h2 className="text-xl">Carespace(s)</h2>
              {(data?.user_role?.length ?? 0) > 0 ? (
                <UserRoleTable
                  listType={ListType.Carespaces}
                  data={
                    data?.user_role
                      .sort(
                        (a, b) =>
                          a.carespace?.name?.localeCompare(
                            b.carespace?.name ?? "",
                          ) ?? 0,
                      )
                      .map((role) => ({
                        [RoleTableFields.WorkSpaceName]:
                          role.carespace?.name ?? "",
                        [RoleTableFields.Role]:
                          CarespaceRoleTypeToLabel[
                            role.role as CarespaceRoleType
                          ] ?? "",
                        [RoleTableFields.Id]: role.carespace?.id || "",
                        [RoleTableFields.UserId]: role.user_id,
                        [RoleTableFields.roleObject]: role,
                      })) ?? []
                  }
                />
              ) : (
                <p>None</p>
              )}
            </div>
          </>
        ) : null}
        {/* Buttons */}
        <>
          {/* Reset Password */}
          <ButtonWithIcon
            onClick={() =>
              navigate({
                path: Route.RESET_PASSWORD,
              })
            }
            size="small"
            text="Change Password"
            icon={IconOption.PADLOCK}
          />
          {/* Request Personal Data */}
          <div className="flex flex-col gap-2 italic">
            <ButtonWithIcon
              onClick={() =>
                upsertUserDataRequest({
                  user_id: authUser?.id,
                })
              }
              size="small"
              text="Request Personal Data"
              icon={IconOption.ARROW}
              disabled={!!userDataRequest}
            />
            {userDataRequest ? (
              <p className="text-xs">
                You requested data on{" "}
                {new Date(userDataRequest.created_at).toLocaleDateString()},
                we're on it!
              </p>
            ) : null}
          </div>
          <div className="flex flex-col gap-2 italic">
            {/* Request Account Deletion */}
            <ButtonWithIcon
              onClick={() =>
                upsertUserDeletionRequest({
                  user_id: authUser?.id,
                })
              }
              size="small"
              text="Request Account Deletion"
              icon={IconOption.ARROW}
              disabled={!!userDeletionRequest}
            />
            {userDeletionRequest ? (
              <p className="text-xs">
                You requested to delete your account on{" "}
                {new Date(userDeletionRequest.created_at).toLocaleDateString()},
                we're on it!
              </p>
            ) : null}
          </div>
          {/* ToS and Privacy Policy */}
          {isMobile ? (
            <>
              <ButtonWithIcon
                onClick={() => window.open(Route.TERMS_OF_SERVICE, "_blank")}
                text="Terms of Service"
                size="small"
                icon={IconOption.TERMS_OF_SERVICE}
              />
              <ButtonWithIcon
                onClick={() => window.open(Route.PRIVACY_POLICY, "_blank")}
                text="Privacy Policy"
                size="small"
                icon={IconOption.PRIVACY_POLICY}
              />
            </>
          ) : null}
        </>
        <BottomActionBar
          centerSlot={
            <ButtonWithIcon
              text=""
              onClick={() => setIsEditing(true)}
              icon={IconOption.EDIT}
            />
          }
        />
      </div>
    </div>
  );
}
