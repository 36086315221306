import { useMemo } from "react";

import type { DiscussionMessage } from "backend/resources/chatGptMessage";
import type { DiscussionsFilterConfig } from "components/DiscussionComponent/components/DiscussionsFilter";
import { DiscussionFilterTypes } from "components/DiscussionComponent/components/DiscussionsFilter";
import { useDiscussionFilterStore } from "state/discussionsFilter/discussionsFilter";
import { useActiveOrganizationId } from "state/organization/organization";
import { isMatch } from "utils";

export const useDiscussionsFilteredData = ({
  data,
  config,
}: {
  data?: DiscussionMessage[];
  config: DiscussionsFilterConfig;
}) => {
  const organizationId = useActiveOrganizationId();
  const filters = useDiscussionFilterStore();

  return useMemo(
    () =>
      data?.filter((row) => {
        const isDiscussionTypeMatch = config[DiscussionFilterTypes.TYPE]
          ? isMatch(filters.type, row.type)
          : true;

        const isDiscussionOrganizationMatch = config[
          DiscussionFilterTypes.ORGANIZATION
        ]
          ? row.organization_id === organizationId
          : true;

        const isDiscussionCarespaceMatch = config[
          DiscussionFilterTypes.CARESPACE
        ]
          ? isMatch(filters.carespace, row.carespace_id)
          : true;
        return (
          isDiscussionTypeMatch &&
          isDiscussionCarespaceMatch &&
          isDiscussionOrganizationMatch
        );
      }),
    [data, config, organizationId, filters.carespace, filters.type],
  );
};
