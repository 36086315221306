import { useAlfredPageSideBar } from "backend/resources/chatGptConversation";
import { useServiceEngagements } from "backend/resources/services/serviceEngagement";
import BackButton from "components/BackButton/BackButton";
import { LoadingSpinner } from "components/LoadingSpinner";
import { PageContainer } from "components/PageContainer";
import { PageMainHeaderWithCarespaceName } from "components/PageMainHeader/PageMainHeaderWithCarespace";
import { LocalServicesSearch } from "components/ResourcesPage/components/LocalServicesSearch";
import ServicesTable, { ServiceFields } from "components/Tables/ServicesTable";
import Tabs from "components/Tabs/Tabs";
import { CraniometrixProduct, useProductAccess } from "hooks/product/product";
import { useWindowSize } from "hooks/useWindowSize";
import { useState } from "react";
import { useActiveCarespaceId } from "state/carespace/carespace";

export enum LocalSearchTabs {
  SEARCH = "Search",
  SERVICES = "Active Services",
}

export default function ResourcesPage() {
  // store
  const { isMobile } = useWindowSize();
  const activeCarespaceId = useActiveCarespaceId();

  // state
  const [currentTab, setCurrentTab] = useState<LocalSearchTabs>(
    LocalSearchTabs.SEARCH,
  );

  // Queries
  useAlfredPageSideBar("servicesPage");
  const { data: productAccess, isLoading: isLoadingProductAccess } =
    useProductAccess();
  const { data } = useServiceEngagements();

  if (isLoadingProductAccess) {
    return (
      <div className="flex flex-col items-center justify-center">
        <LoadingSpinner className="w-8 h-8" />
      </div>
    );
  }
  return (
    <PageContainer>
      {productAccess === CraniometrixProduct.CARE_CENTRAL ? (
        <BackButton className="mb-2" />
      ) : null}
      <PageMainHeaderWithCarespaceName
        text="Services"
        className="text-center md:text-start w-full pb-3"
      />
      <Tabs
        tabs={Object.values(LocalSearchTabs)}
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
      />
      {/* Description */}

      {currentTab === LocalSearchTabs.SEARCH ? (
        <>
          <p className="text-sm py-4">
            Enter your zip code and search for local resources to help with your
            care and other related services
          </p>
          <LocalServicesSearch
            hideCheckbox
            selectedLocalResources={undefined}
            onSelectLocalResource={() => {}}
            backgroundColor="bg-gray-100/80"
          />
        </>
      ) : null}
      {currentTab === LocalSearchTabs.SERVICES ? (
        <ServicesTable
          data={data}
          filterConfig={{
            customAdditionalFilter: (row) =>
              row.carespace_id === activeCarespaceId,
          }}
          hiddenColumns={
            isMobile
              ? [
                  ServiceFields.Carespace,
                  ServiceFields.ServiceType,
                  ServiceFields.Provider,
                  ServiceFields.ActiveOrInactiveDate,
                ]
              : [ServiceFields.Carespace]
          }
        />
      ) : null}
    </PageContainer>
  );
}
