import { useOrgs } from "backend/resources/orgRole";
import { Route } from "features/routing/constants";
import { useAppNavigate } from "lib/routing";
import { useEffect } from "react";

export default function useRequireSuperOrg() {
  const { isSuperSuperUser, isLoading } = useOrgs();
  const navigate = useAppNavigate();

  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (!isSuperSuperUser) {
      navigate({
        path: Route.ROOT,
      });
    }
  }, [navigate, isLoading, isSuperSuperUser]);
}
