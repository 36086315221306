import type { Patient } from "backend/resources/patient";
import type { PAFStatus } from "components/CarespacePage/PAFSubmission/pafEnums";
import {
  GuideAlignmentStatus,
  PAFButtonLabel,
  PAFStatusLabelMap,
} from "components/CarespacePage/Tabs/CarespaceMainTab/constants";

export const getGuideAlignmentPillLabel = (
  pafSubmissionStatus: PAFStatus,
  guideAlignmentStatus: GuideAlignmentStatus,
) => {
  if (pafSubmissionStatus === "ready") {
    return !guideAlignmentStatus ||
      guideAlignmentStatus === GuideAlignmentStatus.NA
      ? PAFStatusLabelMap[pafSubmissionStatus]
      : PAFStatusLabelMap[guideAlignmentStatus];
  }
  if (pafSubmissionStatus === "draft") {
    return PAFStatusLabelMap[pafSubmissionStatus];
  }
  return PAFStatusLabelMap[GuideAlignmentStatus.NA];
};

export const getPAFButtonLabel = (
  guideAlignmentLabel: PAFStatus | GuideAlignmentStatus,
) => {
  if (guideAlignmentLabel === "ready" || guideAlignmentLabel === "draft") {
    return PAFButtonLabel.EDIT;
  }
  if (
    guideAlignmentLabel === GuideAlignmentStatus.SUBMITTED ||
    guideAlignmentLabel === GuideAlignmentStatus.ALIGNED
  ) {
    return PAFButtonLabel.VIEW;
  }
  return PAFButtonLabel.NEW;
};

export const buildPAFFileName = (patient: Patient | null) => {
  if (!patient) {
    return `paf_submission_${new Date().toLocaleString()}`;
  }
  return `${patient?.first_name}_${
    patient?.last_name
  }_paf_submission_${new Date().toLocaleString()}`;
};
