import * as Sentry from "@sentry/react";
import type { Session } from "@supabase/supabase-js";
import type { UseQueryResult } from "@tanstack/react-query";
import { useMutation, useQuery } from "@tanstack/react-query";
import { queryClient } from "app";
import { downloadBlob } from "backend/functions/util";
import { QUERY_KEYS } from "backend/query-keys";
import type { DiscussionMessage } from "backend/resources/chatGptMessage/chatGptMessage";
import type { Patient } from "backend/resources/patient";
import type { OrgRoleType } from "backend/resources/userRole/types";
import { supabase } from "clients/supabaseClient";
import type { BillingPageData } from "components/BillingPage/types";
import { ALL_ORGANIZATIONS } from "features/routing/layouts/components/organization-switcher";
import { useAuthUser } from "features/users/auth";
import qs from "qs";
import type { YellowPagesListingSchema } from "shared/forms/types";
import { useAnalyticsPageFilterStore } from "state/analyticsPageFilter/analyticsPageFilter";
import { useActiveOrganizationId } from "state/organization/organization";
import type { z } from "zod";

const BACKEND_URL = import.meta.env.VITE_BACKEND_URL;

export async function getSupabaseAccessToken(sessionParam?: Session | null) {
  let currentSession = sessionParam;
  if (!currentSession) {
    currentSession = (await supabase.auth.getSession()).data.session;
  }
  const accessToken = currentSession?.access_token;
  return accessToken;
}

/**
 * Google APIs
 */

export type GoogleApiScope =
  | "https://www.googleapis.com/auth/userinfo.email" // email
  | "https://www.googleapis.com/auth/userinfo.profile" // profile
  | "https://www.googleapis.com/auth/calendar.events" // calendar events
  | "openid";

export interface SimpleGoogleCalendarEvent {
  summary: string; // Title or main heading of the event
  description: string; // Description of the event
  location: string; // Location where the event is taking place
  start: EventDateTime; // Start time of the event
  end: EventDateTime; // End time of the event
  colorId?: string; // (Optional) The color of the calendar event
}

interface EventDateTime {
  dateTime: string; // The date and time in ISO format
  timeZone: string; // The time zone
}

/**
 * get_recommendations batch job
 */

interface UserAssessmentResults {
  prompts: {
    question: string;
    type: string;
    answer: string;
    user_assessment_answer_id: string;
    assessment_question_id: string;
  }[];
  userAssessmentId: string;
  userId: string;
}

export async function get_recommendations({
  care_plan_generation_status_id,
}: {
  care_plan_generation_status_id: string;
}) {
  try {
    const response = await fetch(
      `${BACKEND_URL}/user-assessment-chat_gpt_recommendation`,
      {
        method: "POST",
        mode: "cors",
        headers: {
          Authorization: `Bearer ${await getSupabaseAccessToken()}`,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers":
            "authorization, x-client-info, apikey, content-type",
          "Content-Type": "application/json; charset=utf-8",
        },
        body: JSON.stringify({ care_plan_generation_status_id }),
      },
    );
    const responseBody = await response.json();
    return responseBody;
  } catch (error) {
    if (error instanceof Error) {
      return { error: error.message };
    }
    return { error: "Unknown error occurred" };
  }
}

export async function submitGetRecommendationsJob({
  care_plan_generation_status_id,
}: {
  care_plan_generation_status_id: string;
}) {
  try {
    const response = await fetch(
      `${BACKEND_URL}/submit_care_plan_generation_batch_job`,
      {
        method: "POST",
        mode: "cors",
        headers: {
          Authorization: `Bearer ${await getSupabaseAccessToken()}`,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers":
            "authorization, x-client-info, apikey, content-type",
          "Content-Type": "application/json; charset=utf-8",
        },
        body: JSON.stringify({
          care_plan_generation_status_id,
        }),
      },
    );
    const responseBody = await response.json();
    if (response.status !== 200) {
      throw new Error(responseBody.detail);
    }
    return responseBody;
  } catch (error) {
    Sentry.captureException(error);
    if (error instanceof Error) {
      return { error: error.message };
    }
    return { error: "Unknown error occurred" };
  }
}

/**
 * Onboarding
 */

export enum NotificationType {
  REC_IS_READY = "Recommendations are Ready",
  DOCTOR_REVIEWED = "Doctor has Reviewed",
  UPDATE_POSTED_IN_HUB = "New Hub Update",
  MESSAGE_POSTED_IN_HUB = "New Comment in The Hub",
  MESSAGE_POSTED_IN_CARESPACE = "New Comment in The Discussion",
  TODO = "Todos",
  CARESPACE_DEACTIVATION = "Carespace Deactivation",
  CARESPACE_INVITATION = "Carespace Invitation",
  WORKSPACE_INVITATION_ACCEPTANCE = "Carespace Invitation Acceptance",
  ORGANIZATION_INVITATION = "Organization Invitation",
  WELCOME = "Welcome",
  EXTERNAL_SERVICE = "External Service",
  EXTERNAL_SERVICE_REQUEST = "External Service Request",
  EXTERNAL_DIRECT_MESSAGE = "External Direct Message",
  EXTERNAL_DOCUMENT_SHARING = "External Document Sharing",
}

export async function sendNotification(
  related_object_id: string,
  notif_type: NotificationType,
) {
  try {
    const response = await fetch(`${BACKEND_URL}/send_notification`, {
      method: "POST",
      mode: "cors",
      headers: {
        Authorization: `Bearer ${await getSupabaseAccessToken()}`,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
          "authorization, x-client-info, apikey, content-type",
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify({
        related_object_id,
        notif_type,
      }),
    });
    const responseBody = await response.json();
    return responseBody;
  } catch (error: unknown) {
    Sentry.captureException(error);
    return { error: error instanceof Error ? error.message : String(error) };
  }
}

export async function submitAssessment(user_assessment_id: string) {
  try {
    const response = await fetch(`${BACKEND_URL}/submit_assessment`, {
      method: "POST",
      mode: "cors",
      headers: {
        Authorization: `Bearer ${await getSupabaseAccessToken()}`,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
          "authorization, x-client-info, apikey, content-type",
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify({
        user_assessment_id,
      }),
    });
    const responseBody = await response.json();
    if (response.status !== 200) {
      throw new Error(responseBody.detail);
    }
    return responseBody;
  } catch (error: unknown) {
    return { error: error instanceof Error ? error.message : String(error) };
  }
}

export async function oneSignal(
  user_id: string,
  is_starting_assessment?: boolean,
  is_onboarding?: boolean,
) {
  try {
    const response = await fetch(`${BACKEND_URL}/one_signal`, {
      method: "POST",
      mode: "cors",
      headers: {
        Authorization: `Bearer ${await getSupabaseAccessToken()}`,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
          "authorization, x-client-info, apikey, content-type",
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify({
        user_id,
        is_starting_assessment,
        is_onboarding,
      }),
    });
    const responseBody = await response.json();
    return responseBody;
  } catch (error: unknown) {
    Sentry.captureException(error);
    return { error: error instanceof Error ? error.message : String(error) };
  }
}

async function createPrivateConversation({
  user_ids,
  carespace_id,
  service_provider_id,
}: {
  user_ids: string[];
  carespace_id: string;
  service_provider_id?: string | undefined;
}) {
  const response = await fetch(`${BACKEND_URL}/create_private_conversation`, {
    method: "POST",
    mode: "cors",
    headers: {
      Authorization: `Bearer ${await getSupabaseAccessToken()}`,
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers":
        "authorization, x-client-info, apikey, content-type",
      "Content-Type": "application/json; charset=utf-8",
    },
    body: JSON.stringify({ user_ids, carespace_id, service_provider_id }),
  });
  const responseBody = await response.json();
  return responseBody;
}

export const useCreatePrivateConversationMutation = () => {
  const { authUser } = useAuthUser();
  return useMutation({
    mutationFn: async ({
      user_id,
      carespace_id,
      service_provider_id,
    }: {
      user_id?: string | undefined;
      carespace_id: string;
      service_provider_id?: string | undefined;
    }) => {
      if (!authUser || (!user_id && !service_provider_id)) return null;
      const userIds = user_id ? [authUser.id, user_id] : [authUser.id];
      return createPrivateConversation({
        user_ids: userIds,
        carespace_id,
        service_provider_id,
      });
    },
  });
};

export async function createCarespace({
  name,
  orgId,
  carespace_creator_id,
}: {
  name: string;
  orgId: string;
  carespace_creator_id: string;
}) {
  try {
    const response = await fetch(`${BACKEND_URL}/create_carespace`, {
      method: "POST",
      mode: "cors",
      headers: {
        Authorization: `Bearer ${await getSupabaseAccessToken()}`,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
          "authorization, x-client-info, apikey, content-type",
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify({
        name,
        org_id: orgId,
        carespace_creator_id,
      }),
    });
    const responseBody = await response.json();
    if (response.status !== 200) {
      throw new Error(responseBody.detail);
    }
    return responseBody;
  } catch (error: unknown) {
    Sentry.captureException(error);
    return { error: error instanceof Error ? error.message : String(error) };
  }
}

export async function createOrgAndIdentity({
  authId,
  orgRole,
  orgName,
  address,
  city,
  state,
  zip,
}: {
  authId: string;
  orgRole: OrgRoleType;
  orgName: string;
  address?: string;
  city?: string;
  state?: string;
  zip?: string;
}) {
  try {
    const response = await fetch(`${BACKEND_URL}/create_org_and_identity`, {
      method: "POST",
      mode: "cors",
      headers: {
        Authorization: `Bearer ${await getSupabaseAccessToken()}`,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
          "authorization, x-client-info, apikey, content-type",
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify({
        auth_id: authId,
        org_role: orgRole,
        org_name: orgName,
        address,
        city,
        state,
        zip,
      }),
    });
    const responseBody = await response.json();
    if (response.status !== 200) {
      throw new Error(responseBody.detail);
    }
    // invalidate affected queries
    queryClient.invalidateQueries({
      queryKey: [QUERY_KEYS.orgs],
    });
    queryClient.invalidateQueries({
      queryKey: [QUERY_KEYS.orgAndIdentities],
    });
    queryClient.invalidateQueries({
      queryKey: [QUERY_KEYS.userRole],
    });
    queryClient.invalidateQueries({
      queryKey: [QUERY_KEYS.carespaceIds],
    });
    return responseBody;
  } catch (error: unknown) {
    Sentry.captureException(error);
    return { error: error instanceof Error ? error.message : String(error) };
  }
}

/**
 * search_local_resources
 */

export type YellowPagesListing = z.infer<typeof YellowPagesListingSchema>;

export const useSearchLocalResources = (
  yplocation?: string,
  ypkeyword?: string,
) =>
  useQuery({
    queryKey: [QUERY_KEYS.localResources, yplocation, ypkeyword],
    queryFn: async () => {
      const { data } = await searchLocalResources({
        yplocation: yplocation || "",
        ypkeyword: ypkeyword || "",
      });
      return data;
    },
  });

export async function getGoogleMeeting() {
  try {
    const response = await fetch(`${BACKEND_URL}/create_google_meeting`, {
      method: "GET",
      mode: "cors",
      headers: {
        Authorization: `Bearer ${await getSupabaseAccessToken()}`,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
          "authorization, x-client-info, apikey, content-type",
        "Content-Type": "application/json; charset=utf-8",
      },
    });
    if (response.status !== 200) {
      throw new Error(await response.text());
    }
    const responseBody = await response.json();
    return { data: responseBody, error: null };
  } catch (error: unknown) {
    Sentry.captureException(error);
    return { error: error instanceof Error ? error.message : String(error) };
  }
}

export function useBillingPageData(
  hideIneligible: boolean,
  selectedMonth: number | undefined,
  selectedYear: number | undefined,
) {
  const organizationId = useActiveOrganizationId();
  return useQuery({
    queryKey: [
      QUERY_KEYS.billingPageData,
      {
        organizationId,
        hideIneligible,
        month: selectedMonth,
        year: selectedYear,
      },
    ],
    queryFn: async () => {
      try {
        if (!organizationId) {
          throw new Error("Please select an organization");
        }

        if (organizationId === ALL_ORGANIZATIONS) {
          throw new Error(
            "The billing data is not available for multiple organizations",
          );
        }

        const response = await fetch(
          `${BACKEND_URL}/get_billing_page_info?organization_id=${organizationId}&filter_out_ineligible=${hideIneligible}&month=${selectedMonth}&year=${selectedYear}`,
          {
            method: "GET",
            mode: "cors",
            headers: {
              Authorization: `Bearer ${await getSupabaseAccessToken()}`,
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Headers":
                "authorization, x-client-info, apikey, content-type",
              "Content-Type": "application/json; charset=utf-8",
            },
          },
        );
        if (response.status !== 200) {
          throw new Error(await response.text());
        }
        const responseBody = await response.json();
        return responseBody as BillingPageData;
      } catch (error: unknown) {
        Sentry.captureException(error);
        return null;
      }
    },
  });
}

export const useGetIntercomIdentityHash = () =>
  useQuery({
    queryKey: [QUERY_KEYS.intercomIdentityHash],
    queryFn: getIntercomIdentityHash,
  });

export async function getIntercomIdentityHash() {
  try {
    const response = await fetch(`${BACKEND_URL}/intercom_identifier`, {
      method: "GET",
      mode: "cors",
      headers: {
        Authorization: `Bearer ${await getSupabaseAccessToken()}`,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
          "authorization, x-client-info, apikey, content-type",
        "Content-Type": "application/pdf",
      },
    });
    if (response.status !== 200) {
      throw new Error(await response.text());
    }
    return await response.json();
  } catch (error: unknown) {
    Sentry.captureException(error);
    return { error: error instanceof Error ? error.message : String(error) };
  }
}

export const useCreateGoogleMeetingMutation = () =>
  useMutation({
    mutationFn: getGoogleMeeting,
  });

export async function getCarePlanPdfURL(
  care_plan_id: string,
  patient: Patient | undefined,
) {
  try {
    const response = await fetch(
      `${BACKEND_URL}/download_care_plan_pdf?care_plan_id=${care_plan_id}`,
      {
        method: "GET",
        mode: "cors",
        headers: {
          Authorization: `Bearer ${await getSupabaseAccessToken()}`,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers":
            "authorization, x-client-info, apikey, content-type",
          "Content-Type": "application/pdf",
        },
      },
    );
    if (response.status !== 200) {
      throw new Error(await response.text());
    }
    const blob = await response.blob();
    downloadBlob(
      blob,
      `${patient?.first_name}_${patient?.last_name}_care_plan.pdf`,
    );
    return { data: blob, error: null };
  } catch (error: unknown) {
    Sentry.captureException(error);
    return { error: error instanceof Error ? error.message : String(error) };
  }
}

export async function searchLocalResources({
  yplocation,
  ypkeyword,
}: {
  yplocation: string;
  ypkeyword: string;
}) {
  try {
    const query = qs.stringify({ yplocation, ypkeyword });
    const response = await fetch(
      `${BACKEND_URL}/find_local_resources?${query}`,
      {
        mode: "cors",
        headers: {
          Authorization: `Bearer ${await getSupabaseAccessToken()}`,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers":
            "authorization, x-client-info, apikey, content-type",
          "Content-Type": "application/json; charset=utf-8",
        },
      },
    );
    if (response.status !== 200) {
      throw new Error(await response.text());
    }
    const responseBody = (await response.json()) as YellowPagesListing[];
    return { data: responseBody, error: null };
  } catch (error: unknown) {
    Sentry.captureException(error);
    return { error: error instanceof Error ? error.message : String(error) };
  }
}

export type AssessmentQuestionAnalytics = {
  tier_breakdown: Record<string, number>;
  average_age: number;
  sex_breakdown: Record<string, number>;
  caregiver_breakdown: Record<string, number>;
  total_answers: number;
};

export function useAssessmentAnalytics(): UseQueryResult<{
  possible_answers: string[];
  total_breakdown: { [key: string]: AssessmentQuestionAnalytics };
  month_by_month_breakdown: {
    [key: string]: { [key: string]: AssessmentQuestionAnalytics };
  };
  total_patients: number;
}> {
  const organizationId = useActiveOrganizationId();
  const { questionId, tier, sex, hasCaregiver, monthRange, ageRange } =
    useAnalyticsPageFilterStore();
  return useQuery({
    queryKey: [
      QUERY_KEYS.assessmentAnalytics,
      {
        organizationId,
        questionId,
        tier,
        sex,
        hasCaregiver,
        monthRange,
        ageRange,
      },
    ],
    queryFn: async () => {
      try {
        if (!questionId) {
          return null;
        }

        if (!organizationId) {
          throw new Error("Please select an organization");
        }

        if (organizationId === ALL_ORGANIZATIONS) {
          throw new Error(
            "The analytics data is not available for multiple organizations",
          );
        }

        const response = await fetch(
          `${BACKEND_URL}/get_user_assessment_analytics?organization_id=${organizationId}&assessment_question_id=${questionId}&dyad=${tier}&sex=${sex}&has_caregiver=${hasCaregiver}&month=${monthRange}&age_range=${ageRange}`,
          {
            method: "GET",
            mode: "cors",
            headers: {
              Authorization: `Bearer ${await getSupabaseAccessToken()}`,
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Headers":
                "authorization, x-client-info, apikey, content-type",
              "Content-Type": "application/json; charset=utf-8",
            },
          },
        );
        if (response.status !== 200) {
          throw new Error(await response.text());
        }
        const responseBody = await response.json();
        return responseBody as Record<string, unknown>;
      } catch (error: unknown) {
        Sentry.captureException(error);
        return null;
      }
    },
  });
}

export const usePublishCarePlanToEHR = () =>
  useMutation({
    mutationFn: async ({ care_plan_id }: { care_plan_id: string }) => {
      await fetch(`${BACKEND_URL}/publish_care_plan`, {
        method: "POST",
        mode: "cors",
        headers: {
          Authorization: `Bearer ${await getSupabaseAccessToken()}`,
          "Content-Type": "application/json; charset=utf-8",
        },
        body: JSON.stringify({
          care_plan_id,
        }),
      });
    },
  });

export async function updateCarespaceCareNavigator({
  carespace_ids,
  care_navigator_id,
}: {
  carespace_ids: string[];
  care_navigator_id: string;
}) {
  try {
    const response = await fetch(
      `${BACKEND_URL}/update-network-care-navigator`,
      {
        method: "POST",
        mode: "cors",
        headers: {
          Authorization: `Bearer ${await getSupabaseAccessToken()}`,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers":
            "authorization, x-client-info, apikey, content-type",
          "Content-Type": "application/json; charset=utf-8",
        },
        body: JSON.stringify({ network_ids: carespace_ids, care_navigator_id }),
      },
    );

    const responseBody = await response.json();
    if (response.status !== 200) {
      throw new Error(responseBody.detail);
    }

    return responseBody;
  } catch (error: unknown) {
    Sentry.captureException(error);
    return { error: error instanceof Error ? error.message : String(error) };
  }
}

export async function fetchConversations(
  userId: string | undefined,
  carespaceIds: string[],
) {
  if (!userId || !carespaceIds || carespaceIds.length === 0) {
    return [];
  }

  const queryString = carespaceIds
    .map((id) => `network_ids=${encodeURIComponent(id)}`)
    .join("&");

  try {
    const response = await fetch(
      `${BACKEND_URL}/fetch_conversations?user_id=${userId}&${queryString}`,
      {
        method: "GET",
        mode: "cors",
        headers: {
          Authorization: `Bearer ${await getSupabaseAccessToken()}`,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers":
            "authorization, x-client-info, apikey, content-type",
          "Content-Type": "application/json; charset=utf-8",
        },
      },
    );
    const responseBody = await response.json();
    return responseBody as DiscussionMessage[];
  } catch (e) {
    Sentry.captureException(e);
    return [];
  }
}
