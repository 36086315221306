import {
  type Dispatch,
  type ReactNode,
  type SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";

import type { Session } from "@supabase/supabase-js";
import { supabase } from "clients/supabaseClient";
import { useToast } from "shared/hooks/use-toast";

export interface AuthUser {
  id: string;
  email: string;
}

export const AuthUserContext = createContext<
  | {
      authUser: AuthUser | undefined;
      isLoading: boolean;
      setAuthUser: Dispatch<SetStateAction<AuthUser | undefined>>;
      logout: () => Promise<void>;
    }
  | undefined
>(undefined);

interface AuthUserProviderProps {
  children: ReactNode;
}

export function AuthUserProvider({ children }: AuthUserProviderProps) {
  const navigate = useNavigate();
  const { toast } = useToast();
  const [isLoading, setIsLoading] = useState(true);
  const [authUser, setAuthUser] = useState<AuthUser | undefined>(undefined);

  useEffect(() => {
    function setSession(session?: Session | null) {
      if (!session && authUser?.id) {
        toast({
          title: "Session expired",
          description: "Please log in again.",
        });
        setAuthUser(undefined);
        localStorage.clear();
      }

      if (session && authUser?.id !== session.user.id) {
        setAuthUser({
          id: session.user.id,
          email: session.user.email || "",
        });
      }
    }

    supabase.auth
      .getSession()
      .then(({ data: { session } }) => {
        setSession(session);
      })
      .catch(() => {
        toast({
          title: "Sorry, something went wrong",
          description: "Please try logging in again.",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });

    return () => subscription.unsubscribe();
  }, [authUser?.id, toast]);

  const logout = async () => {
    try {
      setAuthUser(undefined);
      localStorage.clear();
      const result = await supabase.auth.signOut({ scope: "local" });
      // Ignoring typescript warning about accessing private methods
      // This is a workaround for a known issue with Supabase auth
      // https://github.com/supabase/supabase/issues/25350
      if (result.error) {
        // @ts-ignore
        await supabase.auth._removeSession();
        // @ts-ignore
        await supabase.auth._notifyAllSubscribers("SIGNED_OUT", null);
      }
    } catch (error) {
      console.error(error);
    } finally {
      navigate(0);
    }
  };

  return (
    <AuthUserContext.Provider
      value={{
        authUser,
        isLoading,
        setAuthUser,
        logout,
      }}
    >
      {children}
    </AuthUserContext.Provider>
  );
}

export function useAuthUser() {
  const value = useContext(AuthUserContext);

  if (!value) {
    throw new Error("useAuthUser must be used within an AuthUserContext");
  }

  return value;
}
