import { cva } from "class-variance-authority";
import * as React from "react";

import { cn } from "shared/utils/cn";

const textVariants = cva("text-black font-normal", {
  variants: {
    size: {
      xs: "text-xs",
      sm: "text-sm",
      md: "text-base",
      lg: "text-lg",
      xl: "text-xl",
    },
  },
  defaultVariants: {
    size: "md",
  },
});

export interface TextProps
  extends Omit<React.HTMLProps<HTMLParagraphElement>, "size"> {
  size?: "xs" | "sm" | "md" | "lg" | "xl";
}

const Text = React.forwardRef<HTMLParagraphElement, TextProps>(
  ({ className, size, ...rest }, ref) => (
    <p ref={ref} className={cn(textVariants({ size, className }))} {...rest} />
  ),
);
Text.displayName = "Text";

export { Text };
