import * as Sentry from "@sentry/react";
import { type ReactNode, useEffect } from "react";

import { supabase } from "clients/supabaseClient";

import { useAuthUser } from "features/users/auth";

import { SidebarInset, SidebarProvider } from "shared/ui/sidebar";

import { LoadingSpinner } from "components/LoadingSpinner";
import { useAppNavigate } from "lib/routing";
import { useToast } from "shared/hooks/use-toast";

import { Route } from "../constants";

import { Header } from "./components/header";
import { Sidebar } from "./components/sidebar";

export interface PrivateLayoutProps {
  children: ReactNode;
}

export default function PrivateLayout({ children }: PrivateLayoutProps) {
  const navigate = useAppNavigate();
  const { toast } = useToast();
  const { authUser, isLoading } = useAuthUser();

  useEffect(() => {
    if (isLoading) return;
    if (!authUser) {
      navigate({
        path: Route.LOGIN,
      });
    }
  }, [authUser, isLoading, navigate]);

  useEffect(() => {
    const verifyMfa = async () => {
      const { data, error } =
        await supabase.auth.mfa.getAuthenticatorAssuranceLevel();

      if (error) {
        Sentry.captureException(error);
        toast({
          title: "Sorry, something went wrong",
          description: "Please refresh the page.",
          variant: "destructive",
        });
        return;
      }

      if (data?.currentLevel !== "aal2") {
        navigate({ path: Route.LOGIN });
      }
    };

    if (authUser) {
      verifyMfa();
    }
  }, [authUser, navigate, toast]);

  if (isLoading) {
    return (
      <section className="flex flex-col h-full">
        <LoadingSpinner />
      </section>
    );
  }

  return (
    <section className="flex flex-col h-full">
      <Header authenticated />
      <SidebarProvider>
        <Sidebar />
        <SidebarInset>{children}</SidebarInset>
      </SidebarProvider>
    </section>
  );
}
