import type { ReactNode } from "react";
import { Header } from "./components/header";

export interface PublicLayoutProps {
  children: ReactNode;
}

export default function PublicLayout({ children }: PublicLayoutProps) {
  return (
    <section className="flex flex-col bg-neutral-50 h-full w-full overflow-y-scroll">
      <Header />
      <main className="flex-grow p-4">{children}</main>
    </section>
  );
}
