import type { OrgAndIdentityReturnType } from "backend/resources/orgRole";

export function checkIsSuperSuperUser(
  self_user_id?: string,
  data?: OrgAndIdentityReturnType[] | null,
) {
  if (
    data?.some(
      (orgIdentity) =>
        orgIdentity.organization?.is_super_org && orgIdentity.is_superuser,
    )
  ) {
    return true;
  }
  // else, they do not have access
  return false;
}
