/**
 * @fileoverview Shared page header component, used as the main header for all page routes.
 */

import { useActiveCarespaceName } from "backend/resources/carespace/carespace";
import { LoadingSpinner } from "components/LoadingSpinner";
import { HeaderNamePill } from "components/Pill";
import { CraniometrixProduct, useProductAccess } from "hooks/product/product";

interface Props {
  text: React.ReactNode;
  className?: string;
}

export function PageMainHeaderWithCarespaceName({ text, className }: Props) {
  const { carespaceName } = useActiveCarespaceName();
  const { data, isLoading } = useProductAccess();
  return (
    <div
      className={`text-3xl font-light flex flex-col md:flex-col-reverse md:items-start gap-1 md:gap-2 w-full md:w-auto  text-center  md:text-left ${className}`}
    >
      <p className="whitespace-nowrap">{text}</p>
      {isLoading ? (
        <LoadingSpinner className="w-8 h-8" />
      ) : data === CraniometrixProduct.CARE_CENTRAL ? (
        <HeaderNamePill text={carespaceName} />
      ) : null}
    </div>
  );
}
