import {
  type ColumnFiltersState,
  createColumnHelper,
} from "@tanstack/react-table";
import { LoadingSpinner } from "components/LoadingSpinner";
import { useMemo, useState } from "react";

import { OrgRoleTypeToLabel } from "backend/resources/userRole/types";
import {
  useFetchOne as useFetchOrganization,
  useUpdateRole,
} from "features/organizations/queries/hooks";
import { Route } from "features/routing/constants";
import { ALL_ORGANIZATIONS } from "features/routing/layouts/components/organization-switcher";
import { useAppNavigate } from "lib/routing";
import { EllipsisVertical, Pencil, Trash } from "lucide-react";
import { useToast } from "shared/hooks/use-toast";
import { Button } from "shared/ui/button";
import {
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuRoot,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "shared/ui/dropdown-menu";
import { DataTable } from "shared/ui/table";
import { Text } from "shared/ui/text";
import { Title } from "shared/ui/title";
import { useActiveOrganizationId } from "state/organization/organization";
import DeleteDialog from "../components/delete-dialog";
import { useDelete, useFetchMany } from "../queries/hooks";
import type { OrganizationInvitation } from "../types";

const columnHelper = createColumnHelper<OrganizationInvitation>();

type MemberListProps = {
  superorgView?: boolean;
};

export default function MemberList({ superorgView = false }: MemberListProps) {
  const { toast } = useToast();
  const activeOrganizationId = useActiveOrganizationId();

  // If we're viewing super org members (via the sidebar link) or if ALL_ORGANIZATIONS is selected,
  // we want to show super org members
  const showSuperOrgMembers =
    superorgView || activeOrganizationId === ALL_ORGANIZATIONS;

  const { data, isLoading } = useFetchOrganization(
    showSuperOrgMembers
      ? {
          equals: { is_super_org: true },
        }
      : {
          equals: { id: activeOrganizationId },
        },
  );
  const {
    data: organizationInvitations,
    isLoading: isLoadingOrganizationInvitations,
  } = useFetchMany(
    {
      equals: { organization_id: data?.id },
    },
    {
      enabled: !!data,
    },
  );
  const deleteMember = useDelete().mutateAsync;
  const navigate = useAppNavigate();
  const [userToBeDeleted, setUserToBeDeleted] =
    useState<OrganizationInvitation | null>(null);
  const updateRole = useUpdateRole({
    equals: { id: userToBeDeleted?.organization_role_id ?? "" },
  }).mutateAsync;
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

  const columns = useMemo(
    () => [
      columnHelper.accessor("first_name", {
        header: "First Name",
        meta: {
          columnFiltering: {
            filterLabel: "First Name",
          },
        },
      }),
      columnHelper.accessor("last_name", {
        header: "Last Name",
        meta: {
          columnFiltering: {
            filterLabel: "Last Name",
          },
        },
      }),
      columnHelper.accessor("invited_email", {
        header: "E-mail",
        meta: {
          columnFiltering: {
            filterLabel: "E-mail",
          },
        },
      }),
      columnHelper.accessor((row) => OrgRoleTypeToLabel[row.role_type], {
        header: "Role",
        meta: {
          columnFiltering: {
            filterLabel: "Role",
          },
        },
      }),
      columnHelper.accessor("status", {
        header: "Status",
        meta: {
          columnFiltering: {
            filterLabel: "Status",
          },
        },
      }),
      columnHelper.display({
        id: "actions",
        cell: ({ row }) => (
          <DropdownMenuRoot>
            <DropdownMenuTrigger asChild>
              <Button
                variant="outline"
                size="icon"
                aria-label={`Actions for ${row.original.first_name} ${row.original.last_name}`}
              >
                <EllipsisVertical className="w-4 h-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-56">
              <DropdownMenuLabel>
                {row.original.first_name} {row.original.last_name}
              </DropdownMenuLabel>
              <DropdownMenuSeparator />
              <DropdownMenuGroup>
                <DropdownMenuItem
                  onClick={() =>
                    navigate({
                      path: showSuperOrgMembers
                        ? Route.SUPERORG_MEMBERS_EDIT
                        : Route.MEMBERS_EDIT,
                      params: { memberId: row.original.id },
                    })
                  }
                >
                  <Pencil className="w-4 h-4" />
                  <span>Edit</span>
                </DropdownMenuItem>
                <DropdownMenuItem
                  onClick={() => setUserToBeDeleted(row.original)}
                >
                  <Trash className="w-4 h-4" />
                  <span>Delete</span>
                </DropdownMenuItem>
              </DropdownMenuGroup>
            </DropdownMenuContent>
          </DropdownMenuRoot>
        ),
      }),
    ],
    [navigate, showSuperOrgMembers],
  );

  async function deleteUser() {
    if (!userToBeDeleted) return;

    try {
      await deleteMember(userToBeDeleted.id);

      if (userToBeDeleted.organization_role_id) {
        await updateRole({
          id: userToBeDeleted.organization_role_id,
          is_deactivated: true,
        });
      }

      toast({
        variant: "success",
        title: "Success",
        description: "Member deleted successfully",
      });
      setUserToBeDeleted(null);
    } catch {
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to delete member",
      });
    }
  }

  if (isLoading || isLoadingOrganizationInvitations) {
    return (
      <div className="w-full h-full flex items-center justify-center">
        <LoadingSpinner className="w-6 h-6" />
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-4 h-full p-6">
      <Title>
        {showSuperOrgMembers
          ? "Super Organization Members"
          : "Organization Members"}
      </Title>
      <div className="flex items-center mb-8">
        <Button
          onClick={() => {
            navigate({
              path: showSuperOrgMembers
                ? Route.SUPERORG_MEMBERS_NEW
                : Route.MEMBERS_NEW,
            });
          }}
        >
          Add Member
        </Button>
      </div>
      {organizationInvitations ? (
        <DataTable
          columns={columns}
          data={organizationInvitations}
          columnFiltering={{
            columnFilters,
            setColumnFilters,
          }}
        />
      ) : (
        <div className="w-full h-full flex items-center justify-center">
          <Text>No members found</Text>
        </div>
      )}
      <DeleteDialog
        open={userToBeDeleted !== null}
        onClose={() => setUserToBeDeleted(null)}
        onConfirm={deleteUser}
      />
    </div>
  );
}
