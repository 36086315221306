import {
  BookLock,
  BookUser,
  Building,
  ChevronDown,
  Cog,
  FolderOpenDot,
  HandPlatter,
  Heart,
  Home,
  Receipt,
  Route as RouteIcon,
  ScrollText,
  Users,
} from "lucide-react";
import { useEffect, useState } from "react";
import { Link, useLocation, useMatch } from "react-router-dom";

import { useActiveOrganizationId } from "state/organization/organization";

import { Route } from "features/routing/constants";
import type { SidebarItem, SidebarSubItem } from "features/routing/types";

import { useAuthUser } from "features/users/auth";
import { OrganizationRole } from "features/users/constants";

import { useGetUserRoles } from "features/organizations/queries/hooks";

import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "shared/ui/collapsible";
import {
  SidebarContent,
  SidebarFooter,
  SidebarGroup,
  SidebarGroupContent,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarMenuSub,
  SidebarMenuSubButton,
  SidebarMenuSubItem,
  Sidebar as SidebarPrimitive,
  SidebarRail,
} from "shared/ui/sidebar";

import { ALL_ORGANIZATIONS } from "./organization-switcher";

const professionalItems: SidebarItem[] = [
  {
    title: "Carespaces",
    url: Route.CARESPACES,
    icon: FolderOpenDot,
  },
  {
    title: "Service Hub",
    url: Route.SERVICE_HUB,
    icon: HandPlatter,
  },
  {
    title: "Directory",
    url: Route.DIRECTORY,
    icon: BookUser,
  },
];

const familyItems: SidebarItem[] = [
  {
    title: "Home",
    url: Route.HOME,
    icon: Home,
  },
  {
    title: "Family Hub",
    url: Route.FAMILY_HUB,
    icon: Users,
  },
  {
    title: "Tools",
    icon: Cog,
    subItems: [
      {
        title: "Services",
        url: Route.LOCAL_SEARCH,
      },
    ],
  },
  {
    title: "My Care",
    url: Route.MY_CARE,
    icon: Heart,
  },
];

const adminItems: SidebarItem[] = [
  {
    title: "Carespaces",
    url: Route.CARESPACES,
    icon: FolderOpenDot,
  },
  {
    title: "Service Hub",
    url: Route.SERVICE_HUB,
    icon: HandPlatter,
  },
  {
    title: "Directory",
    url: Route.DIRECTORY,
    icon: BookUser,
  },
  {
    title: "Billing",
    url: Route.BILLING_PAGE,
    icon: Receipt,
  },
  {
    title: "Admin",
    url: Route.ADMIN,
    icon: Cog,
    subItems: [
      {
        title: "Organization",
        url: Route.ADMIN,
      },
      {
        title: "Members",
        url: Route.MEMBERS,
      },
    ],
  },
];

const superOrgItems: SidebarItem[] = [
  { title: "Organizations", url: Route.ORGANIZATIONS, icon: Building },
  ...professionalItems,
];

const superOrgAdminItems: SidebarItem[] = [
  {
    title: "Super Org Members",
    url: Route.SUPERORG_MEMBERS,
    icon: Users,
  },
  {
    title: "CareFlow",
    url: Route.CAREFLOW,
    icon: RouteIcon,
  },
];

const defaultFooterItems: SidebarItem[] = [
  {
    title: "Terms of Service",
    url: `https://www.craniometrix.com${Route.TERMS_OF_SERVICE}`,
    icon: ScrollText,
    external: true,
  },
  {
    title: "Privacy Policy",
    url: `https://www.craniometrix.com${Route.PRIVACY_POLICY}`,
    icon: BookLock,
    external: true,
  },
];

const MenuItem = ({
  item,
  external,
}: {
  item: SidebarItem;
  external?: boolean;
}) => {
  const pathMatches = item.url && useMatch(`/${item.url}/*`);

  return (
    <SidebarMenuButton asChild isActive={!!pathMatches}>
      {external ? (
        <Link to={item.url || ""} target="_blank" rel="noopener noreferrer">
          <item.icon />
          <span>{item.title}</span>
        </Link>
      ) : (
        <Link to={item.url || ""}>
          <item.icon />
          <span>{item.title}</span>
        </Link>
      )}
    </SidebarMenuButton>
  );
};

const SubMenuItem = ({
  item,
  external,
}: {
  item: SidebarSubItem;
  external?: boolean;
}) => {
  const pathMatches = item.url && useMatch(`/${item.url}/*`);

  return (
    <SidebarMenuSubButton asChild isActive={!!pathMatches}>
      {external ? (
        <Link to={item.url || ""} target="_blank" rel="noopener noreferrer">
          <span>{item.title}</span>
        </Link>
      ) : (
        <Link to={item.url || ""}>
          <span>{item.title}</span>
        </Link>
      )}
    </SidebarMenuSubButton>
  );
};

export function Sidebar() {
  const { authUser } = useAuthUser();
  const organizationId = useActiveOrganizationId();
  const location = useLocation();
  const { pathname } = location;

  const { data: userRoles = [], isLoading } = useGetUserRoles(
    {
      equals: { user_id: authUser?.id },
    },
    { enabled: !!authUser },
  );

  const [items, setItems] = useState<SidebarItem[]>([]);
  const [footerItems, setFooterItems] =
    useState<SidebarItem[]>(defaultFooterItems);

  const isSuperOrgUser = userRoles.some((userRole) => userRole.is_super_org);
  const isSuperOrgAdmin = userRoles.some(
    (userRole) =>
      userRole.is_super_org && userRole.role === OrganizationRole.ADMIN,
  );
  const activeOrgRole = userRoles.find(
    (role) => role.organization_id === organizationId,
  )?.role;

  useEffect(() => {
    if (organizationId === ALL_ORGANIZATIONS && isSuperOrgAdmin) {
      setItems(superOrgItems);
      setFooterItems([...superOrgAdminItems, ...defaultFooterItems]);
      return;
    }

    if (organizationId === ALL_ORGANIZATIONS && isSuperOrgUser) {
      setItems(superOrgItems);
      return;
    }

    if (isSuperOrgAdmin) {
      setItems(adminItems);
      setFooterItems([...superOrgAdminItems, ...defaultFooterItems]);
      return;
    }

    if (activeOrgRole === OrganizationRole.ADMIN) {
      setItems(adminItems);
      return;
    }

    if (
      activeOrgRole === OrganizationRole.CARE_NAVIGATOR ||
      activeOrgRole === OrganizationRole.PROVIDER ||
      isSuperOrgUser
    ) {
      setItems(professionalItems);
      return;
    }

    setItems(familyItems);
  }, [isSuperOrgAdmin, isSuperOrgUser, organizationId, activeOrgRole]);

  if (isLoading) {
    return null;
  }

  return (
    <SidebarPrimitive variant="inset" collapsible="icon">
      <SidebarContent>
        <SidebarGroup>
          <SidebarGroupContent>
            <SidebarMenu>
              {items.map((item) =>
                item.subItems ? (
                  <Collapsible
                    key={item.title}
                    className="group/collapsible"
                    defaultOpen={item.subItems.some(
                      (subItem) => subItem.url === pathname,
                    )}
                  >
                    <SidebarMenuItem>
                      <CollapsibleTrigger asChild>
                        <SidebarMenuButton
                          isActive={item.subItems
                            .map((subItem) => subItem.url)
                            .includes(pathname)}
                        >
                          <item.icon />
                          <span>{item.title}</span>

                          <ChevronDown className="ml-auto transition-transform group-data-[state=open]/collapsible:rotate-180" />
                        </SidebarMenuButton>
                      </CollapsibleTrigger>

                      <CollapsibleContent>
                        <SidebarMenuSub>
                          {item.subItems.map((subItem) => (
                            <SidebarMenuSubItem key={subItem.title}>
                              <SubMenuItem item={subItem} />
                            </SidebarMenuSubItem>
                          ))}
                        </SidebarMenuSub>
                      </CollapsibleContent>
                    </SidebarMenuItem>
                  </Collapsible>
                ) : (
                  <SidebarMenuItem key={item.title}>
                    <MenuItem item={item} />
                  </SidebarMenuItem>
                ),
              )}
            </SidebarMenu>
          </SidebarGroupContent>
        </SidebarGroup>
      </SidebarContent>

      <SidebarFooter>
        {footerItems.map((item) => (
          <SidebarMenuItem key={item.title}>
            <MenuItem item={item} external={item.external ?? false} />
          </SidebarMenuItem>
        ))}
      </SidebarFooter>
      <SidebarRail />
    </SidebarPrimitive>
  );
}
