import {
  type ColumnFiltersState,
  createColumnHelper,
} from "@tanstack/react-table";
import { LoadingSpinner } from "components/LoadingSpinner";
import { useMemo, useState } from "react";

import { OrgRoleTypeToLabel } from "backend/resources/userRole/types";
import { useFetchMany } from "features/members/queries/hooks";
import type { OrganizationInvitation } from "features/members/types";
import { ALL_ORGANIZATIONS } from "features/routing/layouts/components/organization-switcher";
import { useAppNavigate } from "lib/routing";
import { DataTable } from "shared/ui/table";
import { Text } from "shared/ui/text";
import { Title } from "shared/ui/title";
import { useActiveOrganizationId } from "state/organization/organization";

const columnHelper = createColumnHelper<OrganizationInvitation>();

export default function DirectoryPage() {
  const activeOrganizationId = useActiveOrganizationId();
  const {
    data: organizationInvitations,
    isLoading: isLoadingOrganizationInvitations,
  } = useFetchMany(
    activeOrganizationId === ALL_ORGANIZATIONS
      ? { equals: { status: "active" } }
      : {
          equals: { id: activeOrganizationId, status: "active" },
        },
    {
      enabled: !!activeOrganizationId,
    },
  );
  const navigate = useAppNavigate();
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

  const columns = useMemo(
    () => [
      columnHelper.accessor("first_name", {
        header: "First Name",
        meta: {
          columnFiltering: {
            filterLabel: "First Name",
          },
        },
      }),
      columnHelper.accessor("last_name", {
        header: "Last Name",
        meta: {
          columnFiltering: {
            filterLabel: "Last Name",
          },
        },
      }),
      columnHelper.accessor("invited_email", {
        header: "E-mail",
        meta: {
          columnFiltering: {
            filterLabel: "E-mail",
          },
        },
      }),
      columnHelper.accessor((row) => OrgRoleTypeToLabel[row.role_type], {
        header: "Role",
        meta: {
          columnFiltering: {
            filterLabel: "Role",
          },
        },
      }),
    ],
    [navigate],
  );

  if (isLoadingOrganizationInvitations) {
    return (
      <div className="w-full h-full flex items-center justify-center">
        <LoadingSpinner className="w-6 h-6" />
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-4 h-full p-6">
      <Title>Directory</Title>

      {organizationInvitations ? (
        <DataTable
          columns={columns}
          data={organizationInvitations}
          columnFiltering={{
            columnFilters,
            setColumnFilters,
          }}
        />
      ) : (
        <div className="w-full h-full flex items-center justify-center">
          <Text>No members found</Text>
        </div>
      )}
    </div>
  );
}
