import {
  type Cell,
  type CellContext,
  type ExpandedState,
  type Row,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { ButtonWithIcon, IconOption } from "components/ButtonWithIcon";
import { DefaultCheckbox } from "components/Checkbox";
import { CloseButton } from "components/CloseButton/CloseButton";
import { DiscussionFields } from "components/DiscussionComponent/components/DiscussionsFilter";
import { LoadingSpinner } from "components/LoadingSpinner";
import { useWindowSize } from "hooks/useWindowSize";
import { Arrow } from "icons/Arrow";
import { type ReactNode, useEffect, useMemo, useRef, useState } from "react";
import Skeleton from "react-loading-skeleton";
import "./tables.css";

const CHECKBOX = "Checkbox";
const DELETE = "Delete";
const EXPANDED_ARROW = "ExpandedArrow";

interface CheckboxOptions<T> {
  disabled?: (row: Row<T>) => boolean;
  onCheck: (row: Row<T>, isChecked: boolean) => void;
  isChecked: (row: Row<T>) => boolean;
  hideStrikeThrough?: boolean;
  isRadio?: boolean;
}

export interface DeleteOptions<T> {
  onDelete: (row: Row<T>) => void;
}

export interface PaginationOptions<T> {
  fetchNextPage: () => Promise<void>;
  isFetchingNextPage: boolean;
  hasNextPage: boolean;
}

type SubrowOptions<T> = Omit<InfiniteScrollingTableProps<T>, "data">;

interface InfiniteScrollingTableProps<T, T2 = T> {
  data: T[] | undefined;
  hiddenColumns?: string[];
  mobileColumns?: string[];
  hiddenColumnHeaders?: string[];
  columnFields: string[];
  handleClick: (cell: Cell<T, unknown>, row: Row<T>) => void;
  CellContent: (props: { cell: Cell<T, unknown>; row: Row<T> }) => JSX.Element;
  shouldPreventClicks?: boolean;
  checkboxOptions?: CheckboxOptions<T>;
  deleteOptions?: DeleteOptions<T>;
  paginationOptions?: PaginationOptions<T>;
  overriddenHeaderNames?: { [key: string]: string };
  emptyText?: string;
  maxHeight?: string;
  isLoading?: boolean;
  headersToCenter?: string[];
  cellsToCenter?: string[];
  subrowOptions?: SubrowOptions<T2>;
  stickyHeaderZIndex?: number;
}
export type TableDataRowType<T> = { subRows?: TableDataRowType<T>[] };

export function InfiniteScrollingTable<
  T extends TableDataRowType<T>,
  T2 extends TableDataRowType<T2> = T,
>({
  data,
  hiddenColumns,
  mobileColumns,
  hiddenColumnHeaders,
  columnFields,
  handleClick,
  CellContent,
  shouldPreventClicks,
  checkboxOptions,
  deleteOptions,
  paginationOptions,
  overriddenHeaderNames,
  emptyText,
  maxHeight,
  isLoading,
  headersToCenter,
  cellsToCenter,
  subrowOptions,
  stickyHeaderZIndex = 2,
}: InfiniteScrollingTableProps<T, T2>) {
  // Columns
  const { isMobile } = useWindowSize();
  const columnHelper = createColumnHelper<T>();
  const columnTypes = [
    ...(subrowOptions ? [EXPANDED_ARROW] : []),
    ...(checkboxOptions ? [CHECKBOX] : deleteOptions ? [DELETE] : []),
    ...columnFields,
  ];
  const [expanded, setExpanded] = useState<ExpandedState>({});
  const [uniqueClass] = useState(
    () => `table-${Math.random().toString(36).substr(2, 9)}`,
  );

  const tableData = useMemo(
    () =>
      isLoading
        ? Array(30).fill((_: unknown, index: number) => ({
            id: index.toString(),
          }))
        : data,
    [isLoading, data],
  );

  const columns = columnTypes.map((key) =>
    columnHelper.accessor((row: T) => row[key as keyof T], {
      cell: (info) => {
        return (
          <RenderComponentCellContent
            key={key}
            info={info}
            deleteOptions={deleteOptions}
            checkboxOptions={checkboxOptions}
            setExpanded={setExpanded}
            isCellCentered={cellsToCenter?.includes(key)}
            subrowOptions={subrowOptions}
          />
        );
      },
      footer: (info) => info.column.id,
      id: key,
      header: (info) => {
        const hiddenHeaders = [
          EXPANDED_ARROW,
          CHECKBOX,
          DELETE,
          "subRows",
          ...(hiddenColumnHeaders ?? []),
        ];
        return (
          <p
            className={
              headersToCenter?.includes(key) ? "text-center" : "text-start"
            }
          >
            {hiddenHeaders.includes(key)
              ? ""
              : (overriddenHeaderNames?.[key] ?? info.column.id)}
          </p>
        );
      },
    }),
  );

  const tableColumns = useMemo(
    () =>
      isLoading
        ? columns.map((column) => ({
            ...column,
            Cell: <Skeleton />,
          }))
        : columns,
    [isLoading, columns],
  );

  const table = useReactTable({
    data: tableData ?? [],
    columns: tableColumns,
    state: {
      expanded,
    },
    getRowId: (row, index) => (row as any).id ?? index,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    initialState: {
      columnVisibility: {
        ...Object.fromEntries(
          hiddenColumns?.map((col: string) => [col, false]) || [],
        ),
      },
    },
  });

  // For smooth scrolling:
  const scrollableRef = useRef<HTMLDivElement>(null);
  const parentRef = useRef<HTMLDivElement>(null);
  const tableRef = useRef<HTMLTableElement>(null);
  const { rows } = table.getRowModel();

  // Effects
  useEffect(() => {
    const finalHiddenColumns = [
      ...(isMobile && mobileColumns
        ? columnFields.filter((field) => !mobileColumns.includes(field))
        : []),
      ...(hiddenColumns ?? []),
      DiscussionFields.UNDERLYING_OBJECT_ID,
      DiscussionFields.carespace_id,
      DiscussionFields.isExternal,
      DiscussionFields.Id,
    ];
    // Updates visibility of columns
    const newColumnVisibility = {
      ...Object.fromEntries(
        finalHiddenColumns?.map((col: string) => [col, false]) || [],
      ),
      ...(!data || data.length === 0
        ? Object.fromEntries(
            [CHECKBOX, DELETE, ...(hiddenColumnHeaders ?? [])].map(
              (col: string) => [col, false],
            ),
          )
        : {}),
    };
    table.setColumnVisibility(newColumnVisibility);
  }, [hiddenColumns, mobileColumns, table, data]);

  return (
    <div ref={parentRef} className="relative">
      <div
        ref={scrollableRef}
        style={{ maxHeight: maxHeight ? maxHeight : "50vh" }}
        className="overflow-y-auto text-left flex flex-col gap-4  pb-5"
      >
        {scrollableRef.current?.scrollTop ? (
          <ButtonWithIcon
            className="absolute bottom-0 self-center  z-10"
            onClick={() => {
              if (scrollableRef.current) {
                scrollableRef.current.scrollTop = 0;
              }
            }}
            text=""
            icon={IconOption.UP_ARROW}
          />
        ) : null}
        <table ref={tableRef} className="border-separate virtual-table">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr
                key={headerGroup.id}
                className={`virtual-table-sticky-header  ${uniqueClass}`}
                style={
                  {
                    "--sticky-header-z-index": stickyHeaderZIndex,
                  } as React.CSSProperties
                }
              >
                {headerGroup.headers.map((header, index) => (
                  <th
                    key={header.id}
                    className="py-2 h-full text-center w-[20px]"
                  >
                    <div className="h-[2.5rem] relative ">
                      <p className="absolute bottom-0  w-full ">
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext(),
                            )}
                      </p>
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className="text-sm w-full">
            {isLoading ? (
              tableData?.map((_, index) => (
                <tr
                  key={index}
                  style={{
                    height: 60,
                    cursor: shouldPreventClicks ? "" : "pointer",
                  }}
                  className="relative"
                >
                  {table.getHeaderGroups().map((headerGroup, index) =>
                    headerGroup.headers.map((header, index) => (
                      <td
                        style={{
                          paddingLeft: index === 0 ? "20px" : "0px",
                        }}
                        key={header.id}
                        onClick={(e) => {
                          e.preventDefault();
                        }}
                      >
                        <Skeleton className="w-full h-[40px]" />
                      </td>
                    )),
                  )}
                </tr>
              ))
            ) : table.getRowModel().rows.length > 0 ? (
              rows.map((row, index) => {
                if (row) {
                  return (
                    <>
                      <tr
                        key={row.id}
                        style={{
                          height: row.depth === 0 ? 60 : 60 / 2,
                          cursor: shouldPreventClicks ? "" : "pointer",
                        }}
                        className="relative"
                      >
                        {row?.getVisibleCells().map((cell, index) => (
                          <td
                            style={{
                              paddingLeft: index === 0 ? "20px" : "0px",
                            }}
                            key={cell.id}
                            onClick={(e) => {
                              e.preventDefault();
                              if (!shouldPreventClicks) {
                                handleClick(cell, row);
                              }
                            }}
                          >
                            {/* Strike through */}
                            {checkboxOptions?.isChecked(row) &&
                              !checkboxOptions.hideStrikeThrough && (
                                <div className="w-[90%] h-[1px] bg-gray-500 absolute top-1/2 left-[38px]" />
                              )}
                            {row.depth === 0 && (
                              <div
                                style={{ height: 60, top: 0 }}
                                className="w-full z-[-1] h-full border-2 rounded-xl absolute self-center left-0"
                              />
                            )}
                            {/* Content */}
                            {![CHECKBOX, DELETE, EXPANDED_ARROW].includes(
                              cell.column.id,
                            ) && !isLoading ? (
                              <CellContent cell={cell} row={row} />
                            ) : (
                              <p>
                                {flexRender(
                                  cell.column.columnDef.cell,
                                  cell.getContext(),
                                )}
                              </p>
                            )}
                          </td>
                        ))}
                      </tr>
                      {row.getIsExpanded() && subrowOptions && (
                        <tr>
                          <td colSpan={row.getVisibleCells().length}>
                            <div className="w-full pl-[40px]">
                              <InfiniteScrollingTable
                                data={row.original.subRows}
                                columnFields={subrowOptions.columnFields}
                                handleClick={subrowOptions.handleClick}
                                CellContent={subrowOptions.CellContent}
                                hiddenColumns={subrowOptions.hiddenColumns}
                                mobileColumns={subrowOptions.mobileColumns}
                                hiddenColumnHeaders={
                                  subrowOptions.hiddenColumnHeaders
                                }
                                overriddenHeaderNames={
                                  subrowOptions.overriddenHeaderNames
                                }
                                emptyText={subrowOptions.emptyText}
                                maxHeight={subrowOptions.maxHeight}
                                isLoading={subrowOptions.isLoading}
                                headersToCenter={subrowOptions.headersToCenter}
                                cellsToCenter={subrowOptions.cellsToCenter}
                                stickyHeaderZIndex={stickyHeaderZIndex - 1}
                              />
                            </div>
                          </td>
                        </tr>
                      )}
                    </>
                  );
                }
              })
            ) : (
              <tr>
                <td colSpan={columns.length}>
                  <p className="w-max mt-2 text-center">
                    {emptyText ??
                      "There are no rows that match this status"}{" "}
                  </p>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {/* Show loading spinner */}
      {paginationOptions?.isFetchingNextPage &&
        paginationOptions.hasNextPage &&
        scrollableRef.current &&
        scrollableRef.current.scrollTop + scrollableRef.current.clientHeight >=
          scrollableRef.current.scrollHeight - 5 && (
          <div className="w-full flex justify-center absolute -bottom-10">
            <LoadingSpinner className="w-10 self-center p-2" />
          </div>
        )}
    </div>
  );
}

// Functions
interface RenderComponentCellContentProps<T, T2 = T> {
  info: CellContext<T, T[keyof T]>;
  checkboxOptions?: CheckboxOptions<T>;
  deleteOptions?: DeleteOptions<T>;
  setExpanded: (expanded: ExpandedState) => void;
  isCellCentered?: boolean;
  subrowOptions?: SubrowOptions<T2>;
}

function RenderComponentCellContent<T, T2 = T>({
  info,
  checkboxOptions,
  deleteOptions,
  setExpanded,
  isCellCentered = false,
  subrowOptions,
}: RenderComponentCellContentProps<T, T2>) {
  const { isMobile } = useWindowSize();
  const cellAlignment = isCellCentered ? "text-center" : "text-start";

  switch (info.column.id) {
    case EXPANDED_ARROW:
      const shouldShowExpandedArrow =
        subrowOptions && (info.row.original as any).subRows?.length > 0;
      return shouldShowExpandedArrow ? (
        <ExpandedArrow row={info.row} setExpanded={setExpanded} />
      ) : null;
    case CHECKBOX:
      return (
        <div className={`flex ${cellAlignment} gap-2 w-[26px] md:w-[42px]`}>
          <div
            style={{
              justifyContent: info.row.getCanExpand()
                ? "space-between"
                : isMobile
                  ? "start"
                  : "flex-end",
            }}
            className={`flex ${cellAlignment} gap-2 relative w-min-[42px] w-[42px]`}
          >
            <DefaultCheckbox
              checked={checkboxOptions?.isChecked(info.row) ?? false}
              onChange={(isChecked: boolean) => {
                // We close rows when clicking checkbox because it might cause new rows to be added, and having sub rows open can create an issue where rows are overlaying
                if (info.row.getIsExpanded()) {
                  info.row.toggleExpanded();
                }
                checkboxOptions?.onCheck(info.row, isChecked);
              }}
              disabled={checkboxOptions?.disabled?.(info.row) ?? false}
              iconHeight={16}
              iconWidth={16}
            />
          </div>
        </div>
      );
    case DELETE:
      return (
        <button
          type="button"
          className={`hover:scale-[1.15] transition-transform p-1 ${cellAlignment}`}
          onClick={(event) => {
            event.preventDefault();
            deleteOptions?.onDelete(info.row);
          }}
        >
          <CloseButton />
        </button>
      );
    default:
      return (
        <div
          className={`${
            info.row.depth === 1 ? "pl-[1.5rem]" : ""
          } ${cellAlignment}`}
        >
          {info.getValue() as ReactNode}
        </div>
      );
  }
}

function ExpandedArrow<T>({
  row,
  setExpanded,
}: {
  row: Row<T>;
  setExpanded: (expanded: ExpandedState) => void;
}) {
  return (
    <div className="items-center flex">
      <button
        onClick={(e) => {
          e.stopPropagation();
          row.toggleExpanded();
          setExpanded({ [row.id]: !row.getIsExpanded() }); // Ensure only one row is expanded at a time
        }}
      >
        <Arrow
          size={16}
          className={`${
            row.getIsExpanded() ? "" : "rotate-180"
          } transition-all fill-brand-orange`}
        />
      </button>
    </div>
  );
}
