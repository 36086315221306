import { add, format, formatDistanceToNow, parseISO } from "date-fns";
import { Check, Trash } from "lucide-react";
import parseInterval from "postgres-interval";
import { useMemo } from "react";

import { Badge } from "shared/ui/badge";
import { Button } from "shared/ui/button";
import { Card } from "shared/ui/card";
import { Text } from "shared/ui/text";
import { Title } from "shared/ui/title";
import type { Activity } from "../types";

export type ActivityProps = Activity & {
  onComplete?: (activity: Activity) => void;
  onSkip?: (activity: Activity) => void;
};

function getFormattedDeadlineStatus(
  createdAt: string,
  deadline?: string | null,
  scheduledAt?: string | null,
) {
  if (!deadline) {
    return null;
  }

  // Use scheduled_at date if available, otherwise use created_at
  const baseDate = scheduledAt ? parseISO(scheduledAt) : parseISO(createdAt);
  const duration = parseInterval(deadline);
  const calculatedDeadline = add(baseDate, duration);

  if (calculatedDeadline < new Date()) {
    return "overdue";
  }
  return `due ${formatDistanceToNow(calculatedDeadline, {
    addSuffix: true,
  })}`;
}

function isScheduledForFuture(scheduledAt: string | null | undefined): boolean {
  if (!scheduledAt) return false;

  const scheduledDate = parseISO(scheduledAt);
  return scheduledDate > new Date();
}

function formatScheduledDate(scheduledAt: string): string {
  const date = parseISO(scheduledAt);
  return format(date, "MM/dd/yyyy");
}

// Determines if a string is a valid URL
function isValidUrl(value: unknown): value is string {
  if (typeof value !== "string") {
    return false;
  }

  try {
    new URL(value);
    return true;
  } catch {
    return false;
  }
}

// Function to safely render any value as a string
function renderValue(value: unknown): string {
  if (value === null || value === undefined) {
    return "";
  }

  if (typeof value === "string") {
    return value;
  }

  if (typeof value === "object") {
    return JSON.stringify(value);
  }

  return String(value);
}

const OWNER_COLORS = {
  carenavigator: "blue",
  caregiver: "green",
} as const;

const DEADLINE_COLOR = "red" as const;
const SCHEDULED_COLOR = "purple" as const;

export default function ActivityCard(props: ActivityProps) {
  const deadlineStatus = useMemo(
    () =>
      getFormattedDeadlineStatus(
        props.created_at,
        props.deadline,
        props.scheduled_at,
      ),
    [props.created_at, props.deadline, props.scheduled_at],
  );

  const showScheduledBadge = useMemo(
    () => isScheduledForFuture(props.scheduled_at),
    [props.scheduled_at],
  );

  const hasCustomFields =
    props.custom_fields && Object.keys(props.custom_fields).length > 0;

  const showActionButtons = props.onComplete && props.onSkip;

  const completionDate = props.effective_date
    ? parseISO(props.effective_date)
    : null;
  const completionStatus = props.completed_at
    ? "Completed"
    : props.skipped_at
      ? "Skipped"
      : null;

  return (
    <Card contentClassName="px-4 py-3">
      <Title order={4} className="font-semibold">
        {props.title}
      </Title>
      {props.creatorName && (
        <Text size="xs" className="text-gray-500">
          Created by {props.creatorName}
        </Text>
      )}
      {completionDate && completionStatus && (
        <Text size="xs" className="text-gray-500">
          {completionStatus}{" "}
          {formatDistanceToNow(completionDate, { addSuffix: true })}
        </Text>
      )}
      {props.description ? (
        <Text size="sm" className="mt-2">
          {props.description}
        </Text>
      ) : null}

      {hasCustomFields && (
        <div className="mt-3 overflow-hidden">
          <table className="w-full text-sm">
            <tbody>
              {Object.entries(props.custom_fields ?? {}).map(([key, value]) => (
                <tr key={key}>
                  <td className="py-2 pr-3 font-medium text-gray-700 whitespace-nowrap">
                    {key}
                  </td>
                  <td className="py-2 px-3 text-gray-900 w-full">
                    {isValidUrl(value) ? (
                      <a
                        href={value}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-600 hover:underline"
                      >
                        {value}
                      </a>
                    ) : (
                      renderValue(value)
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      <div className="flex flex-col sm:flex-row gap-2 mt-2 sm:items-end">
        <div className="flex flex-row flex-wrap gap-1 sm:self-center">
          <Badge className="text-xs" color={OWNER_COLORS[props.owner]}>
            {props.owner}
          </Badge>
          {deadlineStatus && (
            <Badge className="text-xs" color={DEADLINE_COLOR}>
              {deadlineStatus}
            </Badge>
          )}
          {showScheduledBadge && props.scheduled_at && (
            <Badge className="text-xs" color={SCHEDULED_COLOR}>
              scheduled for {formatScheduledDate(props.scheduled_at)}
            </Badge>
          )}
        </div>
        {showActionButtons && (
          <div className="flex flex-row gap-2 mt-1 sm:mt-0 sm:ml-auto">
            <Button
              variant="outline"
              size="xs"
              aria-label="Skip activity"
              onClick={() => props.onSkip?.(props)}
            >
              <Trash className="w-4 h-4" /> Skip
            </Button>
            <Button
              size="xs"
              aria-label="Mark activity as complete"
              onClick={() => props.onComplete?.(props)}
            >
              <Check className="w-4 h-4" /> Mark as Done
            </Button>
          </div>
        )}
      </div>
    </Card>
  );
}
