import type { Cell, Row } from "@tanstack/react-table";
import { flexRender } from "@tanstack/react-table";
import { ButtonWithIcon, IconOption } from "components/ButtonWithIcon";
import type {
  DeleteOptions,
  TableDataRowType,
} from "components/Tables/InfiniteScrollingTable";
import { InfiniteScrollingTable } from "components/Tables/InfiniteScrollingTable";
import { useSignedUrl } from "hooks/useSignedUrl";

export enum DocumentTableFields {
  File = "File",
  Name = "Name",
  Date = "Date",
}

type BaseDocumentsTableType = {
  [DocumentTableFields.File]?: File;
  [DocumentTableFields.Name]: string;
  [DocumentTableFields.Date]: string;
};
type DocumentsTableType = BaseDocumentsTableType &
  TableDataRowType<BaseDocumentsTableType>;

interface DocumentsTableProps {
  documents: DocumentsTableType[] | undefined;
  deleteOptions?: DeleteOptions<DocumentsTableType>;
  carespaceId: string;
}

export function DocumentsTable({
  documents,
  deleteOptions,
  carespaceId,
}: DocumentsTableProps) {
  return (
    <InfiniteScrollingTable
      data={documents}
      mobileColumns={[DocumentTableFields.File, DocumentTableFields.Name]}
      deleteOptions={deleteOptions}
      columnFields={[
        DocumentTableFields.File,
        DocumentTableFields.Name,
        DocumentTableFields.Date,
      ]}
      handleClick={() => {}}
      hiddenColumnHeaders={[DocumentTableFields.File]}
      CellContent={(props: {
        cell: Cell<DocumentsTableType, unknown>;
        row: Row<DocumentsTableType>;
      }) => {
        const completeFileName = `${carespaceId}/${props.cell.row.original.Name}`;
        const { signedUrl } = useSignedUrl({
          path: completeFileName,
          bucket: "documents",
        });
        switch (props.cell.column.id) {
          case DocumentTableFields.File:
            return (
              <ButtonWithIcon
                onClick={() => {
                  if (signedUrl) {
                    window.open(signedUrl, "_blank");
                  } else if (props.row.original[DocumentTableFields.File]) {
                    const url = URL.createObjectURL(
                      props.row.original[DocumentTableFields.File],
                    );
                    window.open(url, "_blank");
                  }
                }}
                text=""
                size="small"
                icon={IconOption.DOWNLOAD}
              />
            );
          case DocumentTableFields.Name:
            const fileName = props.cell.row.original.Name;
            const truncatedFileName = fileName?.includes("/")
              ? fileName?.split("/").pop()
              : fileName;
            return (
              <p className="truncate w-[220px] md:w-[350px]">
                {truncatedFileName}
              </p>
            );
          default:
            return (
              <div className="truncate">
                {flexRender(
                  props.cell.column.columnDef.cell,
                  props.cell.getContext(),
                )}
              </div>
            );
        }
      }}
    />
  );
}
