export const allYellowPageCategories = [
  "Adult Day Care Centers",
  "Alzheimer's Care & Services",
  "Arbitration & Mediation Attorneys",
  "Assisted Living & Elder Care Services",
  "Assisted Living Facilities",
  "Churches & Places of Worship",
  "Counseling Services",
  "Counselors-Licensed Professional",
  "Developmentally Disabled & Special Needs Services & Products",
  "Disability Services",
  "Elder Law Attorneys",
  "Eldercare-Home Health Services",
  "Elderly Homes",
  "Estate Planning, Probate, & Living Trusts",
  "Food Banks",
  "Geriatric Consulting & Services",
  "Guardianship Services",
  "Health & Welfare Clinics",
  "Home Centers",
  "Home Health Care Equipment & Supplies",
  "Home Health Services",
  "Homes-Institutional & Aged",
  "Hospices",
  "Hospitals",
  "House Cleaning",
  "Independent Living Services For The Disabled",
  "Insurance",
  "Maid & Butler Services",
  "Medical Centers",
  "Medical Clinics",
  "Mental Health Services",
  "Nurses",
  "Nurses Registries",
  "Nurses-Home Services",
  "Nursing & Convalescent Homes",
  "Nursing Home Litigation Attorneys",
  "Nursing Homes Referral Service",
  "Nursing Homes-Intermediate Care Facility",
  "Nursing Homes-Skilled Nursing Facility",
  "Personal Care Homes",
  "Physical Therapists",
  "Physical Therapy Clinics",
  "Physicians & Surgeons",
  "Physicians & Surgeons, Emergency Medicine",
  "Physicians & Surgeons, Family Medicine & General Practice",
  "Physicians & Surgeons, Geriatrics",
  "Physicians & Surgeons, Neurology",
  "Physicians & Surgeons, Primary Care Physicians",
  "Physicians & Surgeons, Psychiatry",
  "Psychiatric Clinics",
  "Psychologists",
  "Psychotherapists",
  "Rehabilitation Services",
  "Religious Organizations",
  "Residential Care Facilities",
  "Rest Homes",
  "Senior Citizen Counseling",
  "Senior Citizens Services & Organizations",
  "Social Security & Disability Law Attorneys",
  "Social Service Organizations",
  "Social Workers",
  "Support Groups",
  "Tax Attorneys",
  "Urgent Care",
  "Wills, Trusts & Estate Planning Attorneys",
  "Wrongful Death Attorneys",
];

export const yellowPageCategoryOptions = allYellowPageCategories.map(
  (category) => ({
    value: category,
    label: category,
  }),
);
