/**
 * @fileoverview Sets up the Zustand product store.
 * This store holds information about the product state.
 */
import * as Sentry from "@sentry/react";
import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "backend/query-keys";
import { useActiveCarespace } from "backend/resources/carespace/carespace";
import type { Organization, OrganizationRole } from "backend/resources/orgRole";
import { useOrgs } from "backend/resources/orgRole";
import { useActiveUserRole } from "backend/resources/userRole";
import { supabase } from "clients/supabaseClient";
import { ALL_ORGANIZATIONS } from "features/routing/layouts/components/organization-switcher";
import { useAuthUser } from "features/users/auth";
import { useActiveOrganizationId } from "state/organization/organization";

export enum CraniometrixProduct {
  CARE_PILOT = "care pilot",
  CARE_CENTRAL = "care central",
}

/**
 * Product access and URL state
 */

export function useProductAccess() {
  const { userRole, isUserRoleLoading } = useActiveUserRole();

  const { isLoading: isHasCareCentralAccessLoading, hasCareCentralAccess } =
    useOrgs();

  return {
    data: getCurrentProduct(hasCareCentralAccess, !!userRole),
    isLoading: isHasCareCentralAccessLoading || isUserRoleLoading,
  };
}

function getCurrentProduct(
  hasCareCentralAccess: boolean,
  hasUserRole: boolean,
) {
  if (hasCareCentralAccess) return CraniometrixProduct.CARE_CENTRAL;
  if (hasUserRole) return CraniometrixProduct.CARE_PILOT;
  return undefined;
}

/**
 * Self-contained hook for checking if the user has CareBinder control
 *
 * @memo: we tried consolidating this but it was difficult to coordinate the org and orgRole loading states,
 * perhaps we can revisit this in the future but for now... coders beware
 */
// TODO fix this mess
// TODO: PR-2794: Remove CareBinder completely
export function useHasPlanBinderControl() {
  const { authUser } = useAuthUser();
  const activeOrgId = useActiveOrganizationId();
  const { data: carespaceData } = useActiveCarespace();
  const { isSuperSuperUser } = useOrgs();

  return useQuery({
    queryKey: [QUERY_KEYS.hasCarebinderControl, { activeOrgId }],
    queryFn: () => {
      if (isSuperSuperUser) {
        return true;
      }

      if (carespaceData?.organization_id) {
        return fetchHasCareBinderControl(
          carespaceData.organization_id,
          authUser?.id,
        );
      }

      if (!activeOrgId) {
        return false;
      }

      if (activeOrgId === ALL_ORGANIZATIONS) {
        throw new Error("Multiple organizations are not supported");
      }

      return fetchHasCareBinderControl(activeOrgId, authUser?.id);
    },
    refetchOnWindowFocus: false,
  });
}

async function fetchHasCareBinderControl(
  organization_id: string | undefined | null,
  user_id?: string | undefined | null,
) {
  if (!user_id || !organization_id) return false;

  const { data: organizationRole, error } = await supabase
    .from("organization_role")
    .select("*")
    .eq("organization_id", organization_id)
    .eq("user_id", user_id)
    .returns<(OrganizationRole & { organization: Organization })[]>()
    .limit(1)
    .maybeSingle();

  if (error) {
    Sentry.captureException(error);
    throw new Error(error.message);
  }

  return organizationRole !== undefined;
}
