import {
  type ServiceEngagementWithServiceResourceAndCarespace,
  useServiceEngagements,
} from "backend/resources/services/serviceEngagement";
import type { ServiceFilterConfig } from "components/Services Hub/components/ServiceFilters";
import ServicesTable, { ServiceFields } from "components/Tables/ServicesTable";
import { useParams } from "react-router-dom";
import { ServiceFilterTypes } from "state/serviceFilters/serviceFilters";

const FILTER_CONFIG: ServiceFilterConfig<ServiceEngagementWithServiceResourceAndCarespace> =
  {
    [ServiceFilterTypes.ORG]: true,
    [ServiceFilterTypes.CARESPACE]: true,
    [ServiceFilterTypes.SERVICE_TYPE]: true,
    [ServiceFilterTypes.HIDE_INACTIVE]: true,
  };

export default function ServiceProviderServiceEngagementsTab() {
  const { id: service_resource_id } = useParams<{ id: string }>();
  const { data } = useServiceEngagements();

  return (
    <ServicesTable
      data={data}
      hiddenColumns={[ServiceFields.Provider]}
      filterConfig={{
        ...FILTER_CONFIG,
        customAdditionalFilter: (row) =>
          row.service_resource_id === service_resource_id,
      }}
      filtersToShow={FILTER_CONFIG}
    />
  );
}
