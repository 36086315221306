import type { ReactNode } from "react";
import { useRef, useState } from "react";
import type { SheetRef } from "react-modal-sheet";
import Sheet from "react-modal-sheet";

interface Props {
  children?: ReactNode;
}

export function ContentHeightSheet({ children }: Props) {
  const [isOpen, setOpen] = useState(false);
  const ref = useRef<SheetRef>();
  const open = () => setOpen(true);
  const close = () => setOpen(false);
  const snapTo = (i: number) => ref.current?.snapTo(i);

  return (
    <Sheet
      ref={ref}
      isOpen={isOpen}
      onClose={close}
      initialSnap={0}
      snapPoints={[-50, 100, 0]}
      detent="content-height"
    >
      <Sheet.Container>
        <Sheet.Header />
        <Sheet.Content>
          <Sheet.Scroller>{children}</Sheet.Scroller>
        </Sheet.Content>
      </Sheet.Container>

      <Sheet.Backdrop onTap={close} />
    </Sheet>
  );
}

interface NonDraggableSheetProps {
  isOpen: boolean;
  onClose?: () => void; // if undefined, sheet cannot affect its own open state
  children?: ReactNode;
}

export function BottomSheet({
  isOpen,
  onClose,
  children,
}: NonDraggableSheetProps) {
  const ref = useRef<SheetRef>();

  function handleClose() {
    if (onClose !== undefined) {
      onClose();
    }
  }

  return (
    <Sheet
      ref={ref}
      isOpen={isOpen}
      onClose={handleClose}
      initialSnap={0}
      snapPoints={[-50, 0]}
      detent="content-height"
    >
      <Sheet.Container>
        {onClose ? <Sheet.Header /> : <div className="mt-3" />}
        <Sheet.Content disableDrag={onClose === undefined}>
          <Sheet.Scroller>{children}</Sheet.Scroller>
        </Sheet.Content>
      </Sheet.Container>
      <Sheet.Backdrop onTap={handleClose} />
    </Sheet>
  );
}
