import * as Sentry from "@sentry/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { queryClient } from "app";
import {
  createCarespace,
  updateCarespaceCareNavigator,
} from "backend/functions";
import { QUERY_KEYS } from "backend/query-keys";
import type { InvitationUpdate } from "backend/resources/invitation/invitation";
import type { OrganizationRole } from "backend/resources/orgRole";
import { useActiveOrgRole, useOrgs } from "backend/resources/orgRole";
import type { Patient } from "backend/resources/patient";
import { supabase } from "clients/supabaseClient";
import { ALL_ORGANIZATIONS } from "features/routing/layouts/components/organization-switcher";
import { useAuthUser } from "features/users/auth";
import type { CarespaceRole, User } from "features/users/types";
import { useActiveCarespaceId } from "state/carespace/carespace";
import type { OrganizationSelection } from "state/organization/organization";
import { useActiveOrganizationId } from "state/organization/organization";
import type { Database } from "types/supabase";

const TABLE = "carespace";
export type Carespace = Database["public"]["Tables"]["carespace"]["Row"];
export type CarespaceInsert =
  Database["public"]["Tables"]["carespace"]["Insert"];
export type CarespaceUpdate =
  Database["public"]["Tables"]["carespace"]["Update"];
export type PrimaryCarePhysician =
  Database["public"]["Tables"]["service_resource"]["Row"];
export type PAFSubmission =
  Database["public"]["Tables"]["paf_submission"]["Row"];

// helper types
export type CarespacesWithPatientAndCaregivers = Carespace & {
  patient: Patient[];
  paf_submission: PAFSubmission[];
  organization: { name: string; software_only: boolean };
} & { user_role: { user: User; role: CarespaceRole }[] };

export function useCreateCarespaceMutation() {
  // RLS bypass for new carespaces
  const { authUser } = useAuthUser();
  const activeOrgId = useActiveOrganizationId();

  return useMutation({
    mutationFn: async ({ id, name }: { id?: string; name: string }) => {
      if (!activeOrgId || !authUser) {
        throw new Error(
          "Error in useCreateCarespaceMutation, no active organization set.",
        );
      }

      if (activeOrgId === ALL_ORGANIZATIONS) {
        throw new Error(
          "Please select an organization to create a new carespaces.",
        );
      }

      const response = await createCarespace({
        name,
        orgId: activeOrgId,
        carespace_creator_id: authUser.id,
      });

      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.carespaceIds, { userId: authUser?.id }],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.carespacesInOrganization],
      });
    },
  });
}

export const useActiveCarespaceName = () => {
  const carespaceId = useActiveCarespaceId();

  const { isLoading, error, data } = useQuery({
    queryKey: [QUERY_KEYS.carespaceName, { carespaceId }],
    queryFn: () => fetchCarespaceName(carespaceId),
    refetchOnWindowFocus: false,
  });
  return { carespaceName: data, isLoading, error };
};

export function useUpdateCarespaceNameMutation() {
  const carespaceId = useActiveCarespaceId();
  return useMutation({
    mutationFn: async ({
      providedCarespaceId,
      name,
    }: {
      providedCarespaceId?: string;
      name: string;
    }) => {
      const selectedCarespaceId = providedCarespaceId || carespaceId;
      if (!selectedCarespaceId) {
        return { data: null, error: new Error("No carespaceId provided") };
      }

      const { data, error: updateError } = await supabase
        .from(TABLE)
        .update({ name })
        .eq("id", selectedCarespaceId)
        .select();

      return { data, error: updateError };
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.carespaceName, { carespaceId }],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.carespace, { carespaceId }],
      });
    },
  });
}

export const useCarespacePCP = () => {
  const carespaceId = useActiveCarespaceId();
  return useQuery({
    queryKey: [QUERY_KEYS.carespacePCP, { carespaceId }],
    queryFn: async () => {
      if (!carespaceId) return null;
      const { data } = await supabase
        .from("carespace")
        .select("service_resource(*)")
        .eq("id", carespaceId)
        .limit(1)
        .maybeSingle();

      return data?.service_resource;
    },
    refetchOnWindowFocus: false,
  });
};

export const useAddConversationToActiveCarespace = () => {
  const carespaceId = useActiveCarespaceId();
  return useMutation({
    mutationFn: async (conversationId: string) => {
      if (!carespaceId) return;
      await supabase
        .from("carespace")
        .update({ conversation_id: conversationId })
        .eq("id", carespaceId);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.carespace],
      });
    },
  });
};

export const useCarespacesInOrganization = () => {
  const organizationId = useActiveOrganizationId();
  const { isSuperSuperUser } = useOrgs();
  const { data: activeCarespace } = useActiveCarespace();
  const { data: orgRole } = useActiveOrgRole();
  const { isLoading, error, data } = useQuery({
    queryKey: [
      QUERY_KEYS.carespacesInOrganization,
      { organizationId, orgRole, isSuperSuperUser, activeCarespace },
    ],
    queryFn: () =>
      fetchCarespacesByOrganizationIdAndActiveOrgRole(
        organizationId,
        orgRole,
        isSuperSuperUser,
      ),
  });
  const carespaces =
    data?.sort((a, b) => {
      if (a?.name && b?.name) {
        return a.name.localeCompare(b.name);
      }
      return 0;
    }) ?? (activeCarespace ? [activeCarespace] : []);

  const carespacesOptionsWithoutAll =
    carespaces?.map((carespace) => ({
      label: carespace.name || "Default",
      value: carespace.id,
    })) ?? [];
  const carespacesOptions = [
    { value: "All", label: "All" },
    ...carespacesOptionsWithoutAll,
  ];
  return {
    carespaces,
    carespacesOptions,
    carespacesOptionsWithoutAll,
    isLoading,
    error,
  };
};

export function useCarespace(carespaceId?: string) {
  const { isLoading, error, data } = useQuery({
    queryKey: [QUERY_KEYS.carespace, { carespaceId }],
    queryFn: () => fetchCarespaceById(carespaceId),
    refetchOnWindowFocus: false,
  });
  return { data, isLoading, error };
}

export function useActiveCarespace() {
  const activeCarespaceId = useActiveCarespaceId();
  return useCarespace(activeCarespaceId);
}

async function fetchCarespaceById(carespaceId?: string | null) {
  if (!carespaceId) {
    return null;
  }

  const { data, error } = await supabase
    .from(TABLE)
    .select(
      "*, organization(name, software_only), patient(*), user_role(user(*), role), paf_submission(id, status)",
    )
    .eq("id", carespaceId)
    .returns<CarespacesWithPatientAndCaregivers[]>();

  if (error) {
    throw new Error(error.message);
  }

  return data && data.length > 0 ? data[0] : null;
}

async function fetchCarespacesByOrganizationIdAndActiveOrgRole(
  organizationId: OrganizationSelection | undefined,
  orgRole: OrganizationRole | null | undefined,
  isSuperSuperUser: boolean,
) {
  if (!organizationId || (!orgRole && !isSuperSuperUser)) {
    return null;
  }

  let query = supabase
    .from(TABLE)
    .select(
      "*, paf_submission(status), organization(name), patient(*), user_role(user(*), role)",
    )
    .order("name", { ascending: true });

  if (organizationId !== ALL_ORGANIZATIONS) {
    query = query.eq("organization_id", organizationId);
  }

  const { data, error } =
    await query.returns<CarespacesWithPatientAndCaregivers[]>(); // asserts many to many relationships
  if (error) {
    throw new Error(error.message);
  }

  // If orgRole's is_superuser field is false, filter out carespaces
  if (!isSuperSuperUser && !orgRole?.is_superuser) {
    return data?.filter((carespace) =>
      carespace.user_role.some(
        (userRole) => userRole.user.id === orgRole?.user_id,
      ),
    );
  }

  return data;
}

async function fetchCarespaceName(carespaceId?: string | null) {
  if (!carespaceId) {
    return null;
  }

  const { data, error } = await supabase
    .from(TABLE)
    .select("name")
    .eq("id", carespaceId)
    .limit(1)
    .single();

  if (error) {
    throw new Error(error.message);
  }

  return data.name;
}

// invitation - user_role - user

async function updateInvitation(invitation: InvitationUpdate) {
  if (!invitation.id) return null;
  const { data, error } = await supabase
    .from("invitation")
    .update({ ...invitation })
    .eq("id", invitation.id)
    .select();

  if (error) {
    Sentry.captureException(error);
  }

  return data;
}

export function useUpdateInvitationMutation() {
  return useMutation({
    mutationFn: async ({ invitation }: { invitation: InvitationUpdate }) => {
      const data = await updateInvitation(invitation);
      return data?.[0];
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({
        queryKey: [
          QUERY_KEYS.invitation,
          { carespace_id: variables.invitation.carespace_id },
        ],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.carespaceMembers],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.carespaceMemberByInvitationId],
      });
    },
  });
}

export const useUpdateCarespaceCareNavigator = () =>
  useMutation({
    mutationFn: async ({
      carespaceIds,
      careNavigatorId,
    }: {
      carespaceIds: string[];
      careNavigatorId: string;
    }) => {
      const response = await updateCarespaceCareNavigator({
        carespace_ids: carespaceIds,
        care_navigator_id: careNavigatorId,
      });
      if (response.error) {
        throw new Error(response.error);
      }
      return response;
    },
    onSuccess: () => {
      // Carespace-related invalidations
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.carespace],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.carespacesInOrganization],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.allCarespaces],
      });

      // Plan entry invalidations
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.planEntries],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.baseAllPlanEntries],
      });

      // User role invalidations
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.userRole],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.orgAndCarespaceIdentities],
      });

      queryClient.invalidateQueries({
        queryKey: ["carespaces"],
      });
    },
  });

export const useAllCarespaces = () => {
  const query = useQuery({
    queryKey: [QUERY_KEYS.allCarespaces],
    queryFn: async () => {
      const { data, error } = await supabase
        .from(TABLE)
        .select(
          `
          *,
          paf_submission(status),
          organization(name, software_only),
          patient(*),
          user_role(user(*), role)
        `,
        )
        .order("name", { ascending: true })
        .returns<CarespacesWithPatientAndCaregivers[]>();

      if (error) {
        throw new Error(error.message);
      }

      return data ?? [];
    },
    refetchOnWindowFocus: false,
  });

  return {
    ...query,
    carespaces: query.data ?? [],
    isLoading: query.isLoading,
  };
};
