import { format } from "date-fns";
import { type ReactElement, cloneElement } from "react";

export function capitalizeFirstLetter(word: string | null) {
  return word ? word.charAt(0).toUpperCase() + word.slice(1) : "";
}

export function lowercaseFirstLetter(word: string | null) {
  return word ? word.charAt(0).toLowerCase() + word.slice(1) : "";
}

export function getLabelAndFillInfo(percentile?: number) {
  if (!percentile) return {};

  switch (true) {
    case percentile <= 32: {
      return { label: "Severe", fill: "hsl(9, 68%, 56%)" };
    }
    case percentile > 32 && percentile <= 67: {
      return { label: "Moderate", fill: "hsl(36, 94%, 59%)" };
    }
    case percentile > 67 && percentile <= 100: {
      return { label: "Normal", fill: "hsl(120, 60%, 58%)" };
    }
    default: {
      return {};
    }
  }
}

export function createDropDownOptions(
  data:
    | { id: string; completed_at: string | null; is_sample?: boolean }[]
    | null
    | undefined,
  hideTimestamp?: boolean,
) {
  if (!data) return [];

  return data.map((d, i) => {
    let label = "";

    if (d.is_sample) {
      label = "Sample";
    } else if (d.completed_at) {
      const dateFormat = hideTimestamp ? "MM/dd/yy " : "MM/dd/yy hh:mm a";
      label = format(new Date(d.completed_at), dateFormat);
    }

    if (!hideTimestamp && i === 0) {
      label += " (most recent)";
    }

    return {
      value: d.id,
      label,
    };
  });
}

export function debounce<A = unknown, R = void>(
  fn: (args: A) => R,
  ms: number,
): [(args: A) => Promise<R>, () => void] {
  let timer: NodeJS.Timeout;

  const debouncedFunc = (args: A): Promise<R> =>
    new Promise((resolve) => {
      if (timer) {
        clearTimeout(timer);
      }

      timer = setTimeout(() => {
        resolve(fn(args));
      }, ms);
    });

  const teardown = () => clearTimeout(timer);

  return [debouncedFunc, teardown];
}

export function shouldIncludeProductFruitAndHotjar() {
  switch (import.meta.env.VITE_ENV_NAME) {
    case "prod": {
      return true;
    }
    case "staging": {
      return true;
    }
    case "qa": {
      return true;
    }
    default: {
      return false;
    }
  }
}

export function isMatch(
  filter: string | undefined,
  rowValue: string | string[] | undefined | null,
  exact = false,
) {
  if (!filter || filter === "All") return true;

  if (Array.isArray(rowValue)) {
    return rowValue.includes(filter);
  }

  if (!rowValue) return false;

  if (exact) {
    return rowValue === filter;
  }

  return rowValue.toLowerCase().includes(filter.toLowerCase());
}

/*
 * The Sorter class is a utility class that allows for chaining of multiple sorting functions.
 * It is a generic class that takes a type parameter T. The sorting functions should take two arguments of type T and return a number.
 * The sortBy method is used to add a new sorting function to the chain. It returns the Sorter instance to allow for method chaining.
 * The sort method is used to sort two elements using the chain of sorting functions. It returns a non-zero value if the elements are unequal, else it returns 0.
 *
 * Example usage:
 * const sorter = new Sorter<MyType>()
 *   .sortBy((a, b) => a.property1 - b.property1)
 *   .sortBy((a, b) => a.property2.localeCompare(b.property2));
 * const sortedArray = myArray.sort((a, b) => sorter.sort(a, b));
 */
export class Sorter<T> {
  private sorters: ((a: T, b: T) => number)[] = [];

  sortBy(sorter: (a: T, b: T) => number): Sorter<T> {
    this.sorters.push(sorter);
    return this;
  }

  // Sorts two elements of type T using the sorter functions. Returns non-zero value if elements are unequal, else returns 0.
  sort(a: T, b: T): number {
    for (const sorter of this.sorters) {
      const result = sorter(a, b);
      if (result !== 0) return result;
    }
    return 0;
  }
}

/**
 * Clones the provided icon element and injects the width and height props.
 *
 * @param {ReactElement} element - The React element to clone.
 * @param {number} size - The width and height of the icon.
 * @returns {ReactElement | null} The cloned React element with injected size props, or null if no element provided.
 */
export const renderIcon = (element: ReactElement | null, size: number) => {
  if (!element) return null;
  return cloneElement(element, {
    width: size,
    height: size,
  });
};
