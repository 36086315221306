import { useMutation, useQuery } from "@tanstack/react-query";
import { queryClient } from "app";
import { QUERY_KEYS } from "backend/query-keys";
import { CarespaceRoleType } from "backend/resources/userRole/types";
import { supabase } from "clients/supabaseClient";
import { useAuthUser } from "features/users/auth";
import type { User } from "features/users/types";
import { useActiveCarespaceId } from "state/carespace/carespace";
import type { Database } from "../../../../types/supabase";

const TABLE = "user_role";
export type UserRole = Database["public"]["Tables"]["user_role"]["Row"];
export type UserRoleInsert =
  Database["public"]["Tables"]["user_role"]["Insert"];
export type UserRoleUpdate =
  Database["public"]["Tables"]["user_role"]["Update"];

export const useActiveUserRole = () => {
  const { authUser } = useAuthUser();
  const activeCarespaceId = useActiveCarespaceId();
  const {
    isLoading: isUserRoleLoading,
    error: userRoleError,
    data: userRoleData,
  } = useQuery({
    queryKey: [
      QUERY_KEYS.userRole,
      { userId: authUser?.id, activeCarespaceId },
    ],
    queryFn: () => fetchUserRole(authUser?.id, activeCarespaceId),
    refetchOnWindowFocus: false,
  });
  return { userRole: userRoleData, isUserRoleLoading, userRoleError };
};

export const useUserRoleFromId = (userRoleId?: string) => {
  const activeCarespaceId = useActiveCarespaceId();
  return useQuery({
    queryKey: [QUERY_KEYS.userRole, { userRoleId, activeCarespaceId }],
    queryFn: () => fetchUserRole(userRoleId, activeCarespaceId),
    refetchOnWindowFocus: false,
  });
};

export const useUserRolesInCarespace = (carespaceId?: string) => {
  const activeCarespaceId = useActiveCarespaceId();
  const selectedCarespaceId = carespaceId ?? activeCarespaceId;
  const result = useQuery({
    queryKey: [QUERY_KEYS.userRole, { activeCarespaceId: selectedCarespaceId }],
    queryFn: () => fetchUserRoles(selectedCarespaceId),
  });

  return {
    ...result,
    primaryCaregivers: result.data?.filter(
      (userRole) => userRole.role === CarespaceRoleType.PRIMARY_CAREGIVER,
    ),
  };
};

export const useUserFromUserRoleId = (user_role_id?: string | null) => {
  const activeCarespaceId = useActiveCarespaceId();
  return useQuery({
    queryKey: [QUERY_KEYS.userRole, { user_role_id, activeCarespaceId }],
    queryFn: () => fetchUserFromUserRoleId(user_role_id, activeCarespaceId),
    refetchOnWindowFocus: false,
  });
};

export async function updateUserRole(userRole: UserRoleUpdate) {
  if (!userRole.id) return;

  const { data, error } = await supabase
    .from(TABLE)
    .update({ ...userRole, id: undefined })
    .eq("id", userRole.id)
    .select("*")
    .limit(1)
    .order("id", { ascending: true }) // noop
    .maybeSingle();
  if (error) {
    throw new Error(error.message);
  }

  return data;
}

export const useUpdateUserRole = () =>
  useMutation({
    mutationFn: async ({
      roleId,
      newRole,
      isDeactivated,
    }: {
      roleId: string;
      newRole?: CarespaceRoleType;
      isDeactivated?: boolean;
    }) => {
      const response = await updateUserRole({
        id: roleId,
        role: newRole,
        is_deactivated: isDeactivated,
      });
      // Need to update the user role and the invitations since that's
      // How we keep track of team members in admin

      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.invitation],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.invitation],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.userRole],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.orgAndCarespaceIdentities],
      });
    },
  });

export async function insertUserRole(userRoleInsert: UserRoleInsert) {
  const { data, error } = await supabase
    .from(TABLE)
    .insert(userRoleInsert)
    .select()
    .single();

  if (error) {
    throw new Error(error.message);
  }

  return data;
}

export const useInsertUserRole = () =>
  useMutation({
    mutationFn: async ({
      userRoleInsert,
    }: {
      userRoleInsert: UserRoleInsert;
    }) => {
      const response = await insertUserRole(userRoleInsert);
      return response;
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.invitation],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.carespaceMembers],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.userRole],
      });
    },
  });

// fetch and queries
export function useCarespaceIds() {
  const { authUser } = useAuthUser();

  const { isLoading, error, data } = useQuery({
    queryKey: [QUERY_KEYS.carespaceIds, { userId: authUser?.id }],
    queryFn: () => fetchCarespaceIds(authUser?.id),
    enabled: !!authUser,
  });
  return {
    carespaceIds: data,
    carespaceIdsError: error,
    carespaceIdsIsLoading: isLoading,
  };
}

export async function fetchUserRole(
  user_id: string | undefined,
  carespace_id: string | undefined,
) {
  if (!user_id || !carespace_id) {
    return null;
  }

  const { data, error } = await supabase
    .from(TABLE)
    .select()
    .eq("user_id", user_id)
    .eq("carespace_id", carespace_id)
    .limit(1)
    .maybeSingle();

  if (error) {
    return null;
  }

  return data;
}

export async function fetchUserRoles(carespace_id: string | undefined) {
  if (!carespace_id) {
    return null;
  }

  const { data, error } = await supabase
    .from(TABLE)
    .select("*, user(*)")
    .eq("carespace_id", carespace_id)
    .order("created_at", { ascending: true })
    .returns<(UserRole & { user: User })[]>();

  if (error) {
    return null;
  }

  return data;
}

export async function fetchUserFromUserRoleId(
  user_role_id: string | undefined | null,
  carespace_id: string | undefined,
) {
  if (!user_role_id || !carespace_id) {
    return null;
  }

  const { data, error } = await supabase
    .from(TABLE)
    .select("*, user(*)")
    .eq("id", user_role_id)
    .eq("carespace_id", carespace_id)
    .returns<(UserRole & { user: User })[]>()
    .limit(1)
    .maybeSingle();

  return data;
}

export async function fetchCarespaceIds(user_id?: string) {
  if (!user_id) {
    return undefined;
  }

  const { data, error } = await supabase.from(TABLE).select("carespace_id");

  if (error) {
    throw new Error(error.message);
  }

  return data.map((role) => role.carespace_id || undefined);
}

export type UserWithRole = { user: User; role: CarespaceRoleType };
export function useUsersInCarespace(carespaceId?: string) {
  return useQuery({
    queryKey: [QUERY_KEYS.usersInCarespace, { carespaceId }],
    queryFn: async () => {
      let query = supabase.from(TABLE).select("user(*),role");

      if (carespaceId && carespaceId !== "All") {
        query = query.eq("carespace_id", carespaceId);
      }

      const { data, error } = await query;
      if (error) {
        throw new Error(error.message);
      }

      return data as UserWithRole[];
    },
    refetchOnWindowFocus: false,
  });
}
