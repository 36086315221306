import { zodResolver } from "@hookform/resolvers/zod";
import { outlinedInputClasses } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { ButtonWithIcon, IconOption } from "components/ButtonWithIcon";
import dayjs from "dayjs";
import { useForm } from "react-hook-form";
import { Form, FormField } from "shared/ui/form";
import { Input } from "shared/ui/input";
import { Title } from "shared/ui/title";
import * as z from "zod";

export const patientSearchFormSchema = z.object({
  firstName: z.string(),
  lastName: z.string(),
  birthday: z.string(),
});

type PatientSearchFormSchema = z.infer<typeof patientSearchFormSchema>;

export const usePatientSearchForm = (defaultValues?: PatientSearchFormSchema) =>
  useForm<z.infer<typeof patientSearchFormSchema>>({
    resolver: zodResolver(patientSearchFormSchema),
    defaultValues: defaultValues ?? {},
    mode: "onSubmit",
  });

export default function PatientSearchForm(props: {
  onSubmit: (data: PatientSearchFormSchema) => void;
}) {
  const form = usePatientSearchForm();
  function onSubmit(data: z.infer<typeof patientSearchFormSchema>) {
    props.onSubmit(data);
  }
  return (
    <Form form={form} id="patient-search-form">
      <Title>Patient Search</Title>
      <div className="flex gap-4 items-end">
        <FormField
          control={form.control}
          name="firstName"
          label="First Name"
          render={({ field }) => <Input {...field} />}
        />
        <FormField
          control={form.control}
          name="lastName"
          label="Last Name"
          render={({ field }) => <Input {...field} />}
        />
        <FormField
          control={form.control}
          name="birthday"
          label="Date of Birth"
          render={({ field }) => (
            <DatePicker
              value={dayjs(field.value)}
              onChange={(date) => field.onChange(date?.toDate().toDateString())}
              slotProps={{
                field: {
                  onKeyDown: (e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                    }
                  },
                },
                textField: {
                  sx: {
                    fieldset: {
                      borderRadius: 2,
                      borderColor: "#E4E4E7",
                    },
                    [`.${outlinedInputClasses.root}`]: {
                      height: 40,
                      width: 165,
                      fontSize: 14,
                      fontWeight: 400,
                      fontFamily: "Poppins",
                    },
                  },
                },
              }}
            />
          )}
        />
        <ButtonWithIcon
          className="mb-1"
          onClick={() => {
            form.handleSubmit((values) => {
              onSubmit(values);
            })();
          }}
          text="Search"
          icon={IconOption.ARROW}
        />
      </div>
    </Form>
  );
}
