import { useAlfredPageSideBar } from "backend/resources/chatGptConversation";
import { PageContainer } from "components/PageContainer";
import { PageMainHeader } from "components/PageMainHeader";
import { ProfilePage } from "components/Profile";
import SettingsPage from "components/SettingsPage/SettingsPage";
import Tabs from "components/Tabs/Tabs";
import { useState } from "react";

import MfaSettings from "features/users/pages/mfa-settings";

export enum MyAccountTab {
  Profile = "Profile",
  Settings = "Settings",
  MFA = "MFA Settings",
}

export default function MyAccountPage() {
  const [tab, setTab] = useState<MyAccountTab>(MyAccountTab.Profile);

  // Queries
  useAlfredPageSideBar("adminPage");

  return (
    <PageContainer>
      <div className="flex flex-col w-full pb-20 gap-2">
        <PageMainHeader text="My Account" />
        <Tabs
          tabs={[MyAccountTab.Profile, MyAccountTab.Settings, MyAccountTab.MFA]}
          currentTab={tab}
          setCurrentTab={setTab}
        />
        {tab === MyAccountTab.Profile && <ProfilePage />}
        {tab === MyAccountTab.Settings && <SettingsPage />}
        {tab === MyAccountTab.MFA && <MfaSettings />}
      </div>
    </PageContainer>
  );
}
