import { usePatientByNameAndDOB } from "backend/resources/patient";
import PatientSearchForm, {
  type patientSearchFormSchema,
} from "components/HomePage/SuperSuperUserHome/PatientSearchForm";
import { PageContainer } from "components/PageContainer";
import { PageMainHeader } from "components/PageMainHeader";
import PatientSearchResultsTable from "components/Tables/PatientSearchResultsTable";
import { useState } from "react";
import type * as z from "zod";

const TITLE = "Home";

export default function PatientSearch() {
  const [searchParams, setSearchParams] = useState<z.infer<
    typeof patientSearchFormSchema
  > | null>(null);

  const { data } = usePatientByNameAndDOB(
    searchParams?.firstName,
    searchParams?.lastName,
    searchParams?.birthday,
  );

  return (
    <PageContainer height="h-[90%] md:h-[100%] max-w-3xl">
      <PageMainHeader text={TITLE} />
      <div className="flex flex-col gap-4 py-5">
        <PatientSearchForm onSubmit={(data) => setSearchParams(data)} />
        <PatientSearchResultsTable data={data} />
      </div>
    </PageContainer>
  );
}
