import { useQuery } from "@tanstack/react-query";
import { supabase } from "clients/supabaseClient";
import { useEffect } from "react";

const HOURS_UNTIL_EXPIRY = 6;
const SECONDS_UNTIL_EXPIRY = HOURS_UNTIL_EXPIRY * 60 * 60;
const MILISECONDS_UNTIL_EXPIRY = SECONDS_UNTIL_EXPIRY * 1000;
export async function fetchSignedUrl(
  path: string | undefined,
  bucket: string,
  expiry: number = SECONDS_UNTIL_EXPIRY,
) {
  if (!path) {
    return null;
  }
  const { data, error } = await supabase.storage
    .from(bucket)
    .createSignedUrl(path, expiry);

  if (error) throw error;
  return data?.signedUrl;
}

export const useSignedUrl = ({
  path,
  bucket,
}: {
  path?: string;
  bucket: string;
}) => {
  const queryInfo = useQuery({
    queryKey: ["signedUrl", { path, bucket }],
    queryFn: () => fetchSignedUrl(path, bucket),
    refetchInterval: MILISECONDS_UNTIL_EXPIRY,
    refetchOnWindowFocus: true,
    refetchOnReconnect: true,
    staleTime: Number.POSITIVE_INFINITY,
  });

  useEffect(() => {
    if (queryInfo.isSuccess && queryInfo.data) {
      try {
        fetch(queryInfo.data);
      } catch (err) {
        queryInfo.refetch();
      }
    }
  }, [queryInfo.isSuccess, queryInfo.data]);

  return {
    signedUrl: queryInfo.data,
    isLoading: queryInfo.isLoading,
  };
};
