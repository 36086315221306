import logo from "assets/logo.png";
import { CircleHelp, CircleUserRound } from "lucide-react";
import { Link } from "react-router-dom";

import { useAuthUser } from "features/users/auth";

import { Button } from "shared/ui/button";
import { Dialog } from "shared/ui/dialog";
import { DropdownMenu } from "shared/ui/dropdown-menu";
import { Text } from "shared/ui/text";

import { Route } from "features/routing/constants";
import { OrganizationSwitcher } from "./organization-switcher";

export interface HeaderProps {
  authenticated?: boolean;
}

export function Header({ authenticated }: HeaderProps) {
  const { logout } = useAuthUser();

  return (
    <header className="flex flex-row items-center gap-4 h-14 shrink-0 bg-white border-b border-neutral-200">
      <div className="w-2 h-full bg-brand-orange" />

      <div className="flex flex-row gap-4">
        <img src={logo} alt="Craniometrix logo" className="w-20" />

        {authenticated && <OrganizationSwitcher />}
      </div>

      <div className="ml-auto flex flex-row gap-4 pr-4">
        <Dialog
          title="We're here to help!"
          description="User help message"
          content={
            <Text>
              Please send any issues to us at{" "}
              <a
                className="text-brand-orange"
                href="mailto:support@craniometrix.com"
              >
                support@craniometrix.com
              </a>{" "}
              and we'll get back to you as soon as possible
            </Text>
          }
        >
          <Button variant="ghost" size="auto">
            <div className="rounded-full bg-brand-orange">
              <CircleHelp className="text-white w-8 h-8" />
            </div>
          </Button>
        </Dialog>

        {authenticated && (
          <DropdownMenu
            items={[
              <Link key="my-account" to={Route.MY_ACCOUNT}>
                <Text size="sm">My Account</Text>
              </Link>,
              <Button key="logout" variant="ghost" size="auto" onClick={logout}>
                Log out
              </Button>,
            ]}
          >
            <Button variant="ghost" size="auto" aria-label="Profile menu">
              <div className="rounded-full bg-brand-orange">
                <CircleUserRound className="text-white w-8 h-8" />
              </div>
            </Button>
          </DropdownMenu>
        )}
      </div>
    </header>
  );
}
