import { useCarespace } from "backend/resources/carespace/carespace";
import { useServiceDiscussion } from "backend/resources/chatGptConversation";
import { useConversationDocumentsByConversationId } from "backend/resources/conversation_document/conversation_document";
import { useOrg, useOrgs } from "backend/resources/orgRole";
import {
  useAddConversationToServiceEngagement,
  useServiceEngagementById,
  useUpdateServiceEngagement,
} from "backend/resources/services/serviceEngagement";
import { ButtonWithIcon, IconOption } from "components/ButtonWithIcon";
import {
  CollapsedUserDiscussion,
  DiscussionType,
} from "components/ChatGptSideBar";
import { LoadingSpinner } from "components/LoadingSpinner";
import { PageContainer } from "components/PageContainer";
import { PageMainHeader } from "components/PageMainHeader";
import { HeaderNamePill } from "components/Pill";
import DocumentsUploadSection from "components/ServicePage/components/DocumentsSection";
import { ServiceDetailItem } from "components/ServicePage/components/ServiceDetailItem";
import { EditServicePageButton } from "components/ServicePage/components/ServicePageEditButton";
import Tabs from "components/Tabs/Tabs";
import { TextArea } from "components/TextArea";
import { Route } from "features/routing/constants";
import { useAppNavigate, useNavigateBack } from "lib/routing";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const TITLE_INTERNAL_DISCUSSION =
  "Internal Discussion  (care team and caregiver ONLY)";

export enum ServicePageTabs {
  INTERNAL = "Internal",
}

export const GUIDE_IDENTIFIER_FOR_RESPITE_SERVICE = "9.1";

export default function ServicePage() {
  // navigation hooks
  const navigateBack = useNavigateBack();
  const navigate = useAppNavigate();
  const { id: serviceEngagementId } = useParams<{ id: string }>();

  const [currentTab, setCurrentTab] = useState<ServicePageTabs>(
    ServicePageTabs.INTERNAL,
  );

  // queries

  const {
    data: serviceEngagementData,
    isLoading: isLoadingServiceEngagementData,
  } = useServiceEngagementById(serviceEngagementId);
  const { data: documentsInService } = useConversationDocumentsByConversationId(
    serviceEngagementData?.conversation_id,
  );

  const { data: carespaceData, isLoading: isLoadingCarespace } = useCarespace(
    serviceEngagementData?.carespace_id,
  );
  const { data: orgData, isLoading: isLoadingOrgData } = useOrg(
    carespaceData?.organization_id,
  );

  const { hasCareCentralAccess } = useOrgs();
  const {
    data: serviceDiscussionData,
    isLoading: isLoadingServiceDiscussionData,
  } = useServiceDiscussion(
    serviceEngagementId,
    currentTab === ServicePageTabs.INTERNAL,
  );

  // state
  const [note, setNote] = useState<string | undefined | null>(
    serviceEngagementData?.notes,
  );

  // mutations
  const updateServiceEngagement = useUpdateServiceEngagement().mutateAsync;
  const addConversationToServiceEngagement =
    useAddConversationToServiceEngagement().mutateAsync;

  useEffect(() => {
    if (!isLoadingServiceDiscussionData && !serviceDiscussionData) {
      addConversationToServiceEngagement({
        serviceEngagementId,
        carespaceId: serviceEngagementData?.carespace_id,
      });
    }
  }, [
    serviceDiscussionData,
    isLoadingServiceDiscussionData,
    serviceEngagementData?.carespace_id,
    serviceEngagementId,
    addConversationToServiceEngagement,
  ]);

  // Sync local note state with service engagement data on load or update service engagement data when note changes
  // Skip update if service engagement data is not available or note is not yet set (undefined)
  useEffect(() => {
    if (serviceEngagementData) {
      // undefined means it hasn't been set yet, while null means it has no note
      if (note === undefined) {
        setNote(serviceEngagementData.notes ?? undefined);
      } else {
        updateServiceEngagement({
          id: serviceEngagementData?.id,
          notes: note ?? null,
        });
      }
    }
  }, [note, serviceEngagementData]);

  // -----------------------// -----------------------// -----------------------// -----------------------//

  if (
    isLoadingServiceEngagementData ||
    isLoadingCarespace ||
    isLoadingOrgData ||
    !serviceEngagementData ||
    !serviceEngagementId
  ) {
    return <LoadingSpinner />;
  }

  return (
    <PageContainer>
      <div className="flex flex-col gap-2 max-w-3xl">
        <div className="flex flex-col gap-1">
          <ButtonWithIcon
            onClick={navigateBack}
            text="Back"
            icon={IconOption.BACK_ARROW}
            size="small"
            type="button"
          />
          <div className="flex gap-4 w-full mt-2">
            <HeaderNamePill text={orgData?.name} color="#5D63BA" />
            <HeaderNamePill text={carespaceData?.name} color="#176590" />
          </div>
          <div className="flex gap-5">
            <PageMainHeader text={serviceEngagementData?.name} />
            {hasCareCentralAccess && <EditServicePageButton />}
          </div>
          <Tabs
            tabs={Object.values(ServicePageTabs)}
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
          />
        </div>

        {currentTab === ServicePageTabs.INTERNAL && (
          <>
            <div>
              <p>Details</p>
              <TextArea
                disabled
                className="w-full"
                value={serviceEngagementData.details ?? undefined}
              />
            </div>
            <div className="flex flex-col gap-10">
              {/* Detail items */}
              <div className="grid grid-cols-2  gap-x-12  items-center gap-y-2 ">
                {/* Provider details */}
                <ServiceDetailItem
                  sectionLabel="Provider"
                  secondItem={
                    <ButtonWithIcon
                      className="line-clamp-1 w-[200px]"
                      onClick={() => {
                        navigate({
                          path: Route.SERVICE_PROVIDER_PAGE,
                          params: {
                            id: serviceEngagementData.service_resource_id,
                          },
                        });
                      }}
                      text={serviceEngagementData.service_resource?.name ?? ""}
                      icon={IconOption.FORWARD}
                      size="small"
                    />
                  }
                />

                {/* Date Active details */}
                <ServiceDetailItem
                  sectionLabel="Date Active"
                  secondItem={new Date(
                    serviceEngagementData?.created_at,
                  ).toLocaleDateString()}
                />
                {/* Date Inactive details */}
                {serviceEngagementData?.became_inactive_at && (
                  <ServiceDetailItem
                    sectionLabel="Date Inactive"
                    secondItem={new Date(
                      serviceEngagementData?.became_inactive_at,
                    ).toLocaleDateString()}
                  />
                )}
              </div>

              <CollapsedUserDiscussion
                discussionType={DiscussionType.Service}
                threadId={serviceEngagementId}
                title={TITLE_INTERNAL_DISCUSSION}
              />
            </div>
          </>
        )}
        <div className="flex flex-col gap-10 pt-10">
          {/* Documents */}
          <DocumentsUploadSection
            documents={
              documentsInService?.map((doc) => ({ ...doc, is_new: false })) ??
              []
            }
            conversationId={serviceEngagementData.conversation_id}
            carespaceId={serviceEngagementData.carespace_id}
          />

          {/* Notes */}
          {hasCareCentralAccess && (
            <div className="flex flex-col gap-2">
              <p className="text-xl">Notes</p>
              <TextArea
                className="h-[10rem]"
                onChange={(e) => setNote(e.currentTarget.value)}
                value={note ?? undefined}
                placeholder="Type your note..."
                data-hj-allow
              />
            </div>
          )}
        </div>
      </div>
    </PageContainer>
  );
}
