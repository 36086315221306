import type { CarespaceRole } from "./types";

export const TABLE_NAME = "user";

export enum OrganizationRole {
  ADMIN = "admin",
  PROVIDER = "provider",
  CARE_NAVIGATOR = "carenavigator",
}

export const CarespaceRoleMap: Record<CarespaceRole, string> = {
  primary_caregiver: "primary_caregiver",
  secondary_caregiver: "secondary_caregiver",
};

export const RoleLabel: Record<
  | CarespaceRole
  | OrganizationRole.PROVIDER
  | OrganizationRole.CARE_NAVIGATOR
  | "pcp",
  string
> = {
  primary_caregiver: "Primary Caregiver",
  secondary_caregiver: "Secondary Caregiver",
  [OrganizationRole.PROVIDER]: "Dementia Care Provider",
  [OrganizationRole.CARE_NAVIGATOR]: "Care Navigator",
  pcp: "Primary Care Provider",
};
