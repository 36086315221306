import { cva } from "class-variance-authority";
import * as React from "react";

import { cn } from "shared/utils/cn";

const titleVariants = cva("font-light leading-none", {
  variants: {
    order: {
      1: "text-3xl",
      2: "text-2xl",
      3: "text-xl",
      4: "text-base",
      5: "text-sm",
      6: "text-xs",
    },
    color: {
      default: "text-black",
      gray: "text-gray-500",
    },
  },
  defaultVariants: {
    color: "default",
  },
});

export interface TitleProps extends React.HTMLProps<HTMLHeadingElement> {
  order?: 1 | 2 | 3 | 4 | 5 | 6;
  color?: "default" | "gray";
}

const Title = React.forwardRef<HTMLHeadingElement, TitleProps>(
  ({ order = 1, color, className, ...rest }, ref) => {
    const Comp = `h${order}` as const;

    return (
      <Comp
        ref={ref}
        className={cn(titleVariants({ order, color }), className)}
        {...rest}
      />
    );
  },
);
Title.displayName = "Title";

export { Title };
